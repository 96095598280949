

// data
import React, {  } from "react";

// formating
import formatPhoneNumber from "../utilities/formatPhoneNumber";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function AdminSalesCollection(props) {

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className=" text-BNPblue">
        <div className="flex font-Montserrat p-4 bg-white">
          {/* Column 1 */}
          <div className="flex-row items-center pr-6">
            {/* Order Number */}
            <div className="flex-row justify-between items-center">
              <p className="font-semibold text-lg">Order number:</p>
              <p className="text-right text-lg">{props.id}</p>
            </div>
          </div>

          {/* Column 2 */}
          <div className="flex-row w-[200px] items-center border-2 border-red-600">
            {/* Client Info */}
            <div className="flex items-center">
              <p className="font-semibold text-lg mr-10 ">Collectors Info:</p>
            </div>
            <p>{props.bussinesName}</p>
            <p>{props.DeliveryName}</p>
            <p>{formatPhoneNumber(props.RecipientCell)}</p>
          </div>
        </div>
      </div>
    </>
  );
}
