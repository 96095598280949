import plateData from "../data/Lookups/plates.json";

// Functions
import RemoveFunnies from "../utilities/fromatRemoveFunnies";
import isNumeric from "./isNumeric";

/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
export default function FormatRegistrationNo(str, size, valid) {

  try {   
      if (!str) return str;
      let RegNo = RemoveFunnies(str);
      let ValidLen = 9;
      let RegNoFinal = RegNo;
      let NewProv = "";

      ValidLen = 9;
      // Get correct length
      if (valid !== "Fail") {
        ValidLen = valid.substring(valid.indexOf(":") + 1);
      } else {
        ValidLen = 9;
      }

      // Check if already correct
      if (valid.substring(0, 4) !== "Fail" && RegNo.length <= ValidLen) {
        RegNo = RegNo.substring(0, ValidLen);
        // console.log("Already matched, don't add more text");
        // console.log("Part-1");
        RegNoFinal = RegNo;
      } else {
        RegNo = RegNo.substring(0, ValidLen);

        // Get Prov length if it doesn't match

        if (
          // GP, NC, WP, EC, ZN, MP, FS, NW
          RegNo.substring(RegNo.length - 2) === "GP" ||
          RegNo.substring(RegNo.length - 2) === "NC" ||
          RegNo.substring(RegNo.length - 2) === "WP" ||
          RegNo.substring(RegNo.length - 2) === "EC" ||
          RegNo.substring(RegNo.length - 2) === "ZN" ||
          RegNo.substring(RegNo.length - 2) === "MP" ||
          RegNo.substring(RegNo.length - 2) === "FS" ||
          RegNo.substring(RegNo.length - 2) === "NW"
        ) {
          NewProv = RegNo.substring(RegNo.length - 2);
        } else if (
          // L
          RegNo.substring(RegNo.length - 1) === "L"
        ) {
          NewProv = RegNo.substring(RegNo.length - 1);
        } else if (valid.substring(0, 3) === "KZN") {
          NewProv = "ZN";
        } else if (valid.substring(0, 2) === "WP") {
          NewProv = "WP";
        }

        if (NewProv.length === 0) {
          ValidLen = ValidLen - 1;
        }

        // Get correct should be length of RegNo
        try {
          // Build Array - Exact match.
          const NewplateData = plateData.filter((data) => {
            return data.prov.toLowerCase() === NewProv.toLowerCase();
          });

          // console.log(NewplateData[0].prov);
          ValidLen = NewplateData[0].mxLength;
        } catch (error) {}

        RegNoFinal = RegNoFinal.substring(0, 9);

        // console.log("Part-2");
      }
      return formatRegNo(RegNoFinal, size, valid, NewProv);
  } catch (error) {
    return "Format error";
  }
}
// AB CD EF G HI
// 01 23 45 6 78
// 12 34 56 7 89

/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////

function formatRegNo(RegNo, size, valid, NewProv) {
  let RegNoFinal = RegNo;

  let a1 = false;
  let a2 = false;
  let a3 = false;
  let a4 = false;
  let a5 = false;
  let a6 = false;

  if (
    isNumeric(RegNoFinal.substring(0, 1)) === false &&
    RegNoFinal.substring(0, 1).length === 1
  ) {
    a1 = false;
  } else {
    a1 = true;
  }
  if (
    isNumeric(RegNoFinal.substring(1, 2)) === false &&
    RegNoFinal.substring(1, 2).length === 1
  ) {
    a2 = false;
  } else {
    a2 = true;
  }
  if (
    isNumeric(RegNoFinal.substring(2, 3)) === false &&
    RegNoFinal.substring(2, 3).length === 1
  ) {
    a3 = false;
  } else {
    a3 = true;
  }
  if (
    isNumeric(RegNoFinal.substring(3, 4)) === false &&
    RegNoFinal.substring(3, 4).length === 1
  ) {
    a4 = false;
  } else {
    a4 = true;
  }
  if (
    isNumeric(RegNoFinal.substring(4, 5)) === false &&
    RegNoFinal.substring(4, 5).length === 1
  ) {
    a5 = false;
  } else {
    a5 = true;
  }
  if (
    isNumeric(RegNoFinal.substring(5, 6)) === false &&
    RegNoFinal.substring(5, 6).length === 1
  ) {
    a6 = false;
  } else {
    a6 = true;
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // GP plates // AB 12 CD
  // console.log("1")
  if (RegNo.length === 2 && a1 === false && a2 === false) {
    RegNoFinal = RegNo.substring(0, 2).trim();
  } else {
    if (RegNo.length === 3 && a1 === false && a2 === false && a3 === true) {
      RegNoFinal = (RegNo.substring(0, 2) + " " + RegNo.substring(2, 4)).trim();
    } else {
      if (
        RegNo.length === 4 &&
        a1 === false &&
        a2 === false &&
        a3 === true &&
        a4 === true
      ) {
        RegNoFinal = (
          RegNo.substring(0, 2) +
          " " +
          RegNo.substring(2, 4)
        ).trim();
      } else {
        if (
          RegNo.length === 5 &&
          a1 === false &&
          a2 === false &&
          a3 === true &&
          a4 === true &&
          a5 === false
        ) {
          RegNoFinal = (
            RegNo.substring(0, 2) +
            " " +
            RegNo.substring(2, 4) +
            " " +
            RegNo.substring(4, 6)
          ).trim();
        } else {
          if (
            RegNo.length >= 6 &&
            a1 === false &&
            a2 === false &&
            a3 === true &&
            a4 === true &&
            a5 === false &&
            a6 === false
          ) {
            RegNoFinal = (
              RegNo.substring(0, 2) +
              " " +
              RegNo.substring(2, 4) +
              " " +
              RegNo.substring(4, 6) +
              " " +
              RegNo.substring(6, 10)
            ).trim();
          }
        }
      }
    }
  }

  // console.log("520 & 440 & 250 - ABC 123");  ///////////////////////////////////////////////////////////////////
  // console.log("2")
  //  use spaces - OT-SN // NoN GP
  if (RegNo.length <= 3) {
    RegNoFinal = RegNo.substring(0, 4).trim();
  } else {
    if (
      RegNo.length === 4 &&
      a1 === false &&
      a2 === false &&
      a3 === false &&
      a4 === true
    ) {
      RegNoFinal = (RegNo.substring(0, 3) + " " + RegNo.substring(3, 6)).trim();
    } else {
      if (
        RegNo.length === 5 &&
        a1 === false &&
        a2 === false &&
        a3 === false &&
        a4 === true &&
        a5 === true
      ) {
        RegNoFinal = (
          RegNo.substring(0, 3) +
          " " +
          RegNo.substring(3, 6)
        ).trim();
      } else {
        if (
          RegNo.length >= 6 &&
          a1 === false &&
          a2 === false &&
          a3 === false &&
          a4 === true &&
          a5 === true &&
          a6 === true
        ) {
          RegNoFinal = (
            RegNo.substring(0, 3) +
            " " +
            RegNo.substring(3, 6) +
            " " +
            RegNo.substring(6, 10)
          ).trim();
        }
      }
    }
  }

  // 250 plates
  if (size === "250") {
    // console.log("3")
    // console.log("250-L");  ///////////////////////////////////////////////////////////////////
    // console.log("1");
    if (
      // KZN and WP 2 letter STD and Small sizes
      valid.substring(0, 5) === "KZN-2" ||
      valid.substring(0, 4) === "WP-2"
    ) {
      RegNoFinal = (
        RegNo.substring(0, 2) +
        " " +
        RegNo.substring(2, 12)
      ).trim();
      // console.log("440-KZN2/WP2");
    }

    if (
      // KZN and WP 3 letter STD and Small sizes
      valid.substring(0, 5) === "KZN-3" ||
      valid.substring(0, 4) === "WP-3"
    ) {
      RegNoFinal = (
        RegNo.substring(0, 3) +
        " " +
        RegNo.substring(3, 10)
      ).trim();
      // console.log("520-KZN3/WP3");
    }
  } else if (
    // KZN and WP 3 letter STD and Small sizes  ///////////////////////////////////////////////////////////////////
    valid.substring(0, 5) === "KZN-3" ||
    valid.substring(0, 4) === "WP-3"
  ) {
    // console.log("4")
    RegNoFinal = (RegNo.substring(0, 3) + " " + RegNo.substring(3, 10)).trim();
    // console.log("520-KZN3/WP3");
  } else if (
    // KZN and WP 2 letter STD and Small sizes  ///////////////////////////////////////////////////////////////////
    valid.substring(0, 5) === "KZN-2" ||
    valid.substring(0, 4) === "WP-2"
  ) {
    // console.log("5")

    if (RegNo.length <= 5) {
      RegNoFinal = (RegNo.substring(0, 2) + " " + RegNo.substring(2, 5)).trim();
    } else {
      RegNoFinal = (
        RegNo.substring(0, 2) +
        " " +
        RegNo.substring(2, 5) +
        "-" +
        RegNo.substring(5, 10)
      ).trim();
    }
    // console.log("520-KZN2/WP2");
  } // both 520 & 440  - ABC 123

  // console.log(valid.substring(0, 4))
  if (valid.substring(0, 4) === "OT-P") {
    // console.log("6")
    RegNoFinal = (
      RegNo.substring(0, RegNo.length - 2) +
      " " +
      RegNo.substring(RegNo.length - 2, 10)
    ).trim();
  } else if (valid.substring(0, 3) === "L-P") {
    // console.log("7")
    RegNoFinal = (
      RegNo.substring(0, RegNo.length - 1) +
      " " +
      RegNo.substring(RegNo.length - 1, 10)
    ).trim();
  }

  return RegNoFinal;
}
