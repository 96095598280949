import React, { useContext, useEffect, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import axios from "axios";
import md5 from "md5";

// formating
import formatFunny from "../utilities/fromatRemoveFunnies";

// Pages
import TrackingItems from "./TrackingItems";

// Context
import UserContext from "../context/UserContext";
import ModalContext from "../context/ModalContext";
import UrlContext from "../context/URLContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function TrackingPage() {
  // console.log("Render TrackingPage")

  // Context
  const { trackingData } = useContext(UrlContext);

  const {
    IDno,
    userID,
    setIDno,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    tokenTime,
  } = useContext(UserContext);
  const {
    trackingVisible,
    setTrackingVisible,
    msgClean,
    setMsgOpen,
    setMsgTxt,
    setMsgType,
    setTrackingRefresh,
  } = useContext(ModalContext);

  // State
  const idNoRef = useRef();
  const btnTrack = useRef();
  const [lookupIDno, setLookupIDno] = useState("");
  const [idNoMove, setIDNoMove] = useState(false);
  const [trackingInfo, setTrackingInfo] = useState([]);
  const [errorMsgVisible, setErrorMsgVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [ClearText, setClearText] = useState(false);
  const [btnWait, setBtnWait] = useState(false);

  // vaiables
  let loadNewAccessToken = accessToken; // JWS Token

  useEffect(() => {
    if (lookupIDno.length !== 0) {
      setIDNoMove(true);
      if (userID === 0) {
        setClearText(true);
      } else {
        setClearText(false);
      }
    } else {
      setIDNoMove(false);
      setClearText(false);
      setTrackingVisible(false);
    }

    if (userID > 0) {
      setLookupIDno(IDno);
      document.getElementById("TrackClick")?.click();
    }
  }, [lookupIDno, IDno, setTrackingVisible, userID]);

  // ID No
  const handleIDNo = (e) => {
    setLookupIDno(formatFunny(e.target.value.toUpperCase()));
    setErrorMsg(formatFunny(e.target.value.toUpperCase()));
    setClearText(true);
    setErrorMsgVisible(false);
    setTrackingVisible(false);
  };

  const idNoFocusOn = () => {
    setIDNoMove(true);
    idNoRef.current.focus();
  };

  const idNoFocusOff = () => {
    if (lookupIDno?.length !== 0) {
      setIDNoMove(true);
    } else {
      setIDNoMove(false);
    }
  };

  // Tracking info

  const handleTrackInfo = () => {
    if (lookupIDno.length === 0) {
      idNoRef.current.focus();
      setTrackingInfo([]);
      setErrorMsgVisible(false);
      setBtnWait(false);
    } else {
      // Tracking data array

      setBtnWait(true);
      if (userID > 0) {
        loadTrackingInfo("ucn", userID);
      } else {
        loadTrackingInfo("IDno", lookupIDno);
      }
    }
  };

  // Reset Input Field handler
  const clearDiscText = () => {
    setLookupIDno("");
    setClearText(false);
    setIDNoMove(false);
    setTrackingVisible(false);
    setErrorMsgVisible(false);
  };

  // Load address info
  const loadTrackingInfo = async (t, i) => {
    // console.log(t, i);
    //  Hash build
    let pw = md5(i + process.env.REACT_APP_UCNPASSPHRASE);
    let rf = 0;

    // Check if new refreshToken is needed
    if (refreshToken === "" || new Date().getTime() > tokenTime) {
      rf = 1;
    }

    // request data from mySQL db
    let url = trackingData;
    const data = await axios
      .get(url, {
        params: {
          lookup: t,
          ucn_ID: i,
          z: pw,
          rf: rf,
        },
      })
      .then((res) => res.data)
      .catch((err) => {
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });
    // console.log(data);

    if (data.auth === true && data.sqlData[0].msg === "Data") {
      try {
        if (data[0]?.length === 0) {
          setErrorMsgVisible(true);
          idNoRef.current.focus();
          setTrackingRefresh(false);
        } else {
          setErrorMsgVisible(false);
          setTrackingVisible(true);
          setTrackingRefresh(true);

          // Only update tokens if not already set
          if (rf === 1) {
            setAccessToken(data.accessToken);
            setRefreshToken(data.refreshToken);
            loadNewAccessToken = data.accessToken;
          }
        }
      } catch (error) {
        console.log(error);
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      }

      // load sqlData
      try {
        setTrackingInfo(data.sqlData);
        setIDno(data.sqlData[0].ID_No);
      } catch (error) {
        console.log(error);
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      }
    } else {
      setErrorMsgVisible(true);
      idNoRef.current.focus();
      setTrackingInfo([]);
    }

    setBtnWait(false);
  };

  // const handleEnter = (e) => {
  //   if (e.key === "Enter") {
  //     // console.log('run click');
  //     handleTrackInfo();
  //     console.log("Enter key pressed");
  //   }
  // };

  // console.log(trackingInfo)


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="flex-row w-100 font-Montserrat text-BNPblue pb-4">
        {/* Search Section */}
        <div className="flex-row justify-center items-center text-center">
          <h1 className="pt-3 md:pt-6 text-3xl font-bold">
            Please enter your order ID number below.
          </h1>

          {/* ID No & Passport */}
          <div className="flex w-100 justify-center mt-3 md:mt-6">
            <div className="flex-row">
              <div
                onClick={() => idNoFocusOn()}
                className="group w-[300px] h-[43px] BNP-LableDIV cursor-text"
              >
                <input
                  value={lookupIDno}
                  onChange={handleIDNo}
                  onKeyUp={handleIDNo}
                  onBlur={() => idNoFocusOff()}
                  // onKeyDown={handleEnter}
                  type="text"
                  ref={idNoRef}
                  autoComplete="off"
                  className="w-100 h-11 pl-4 BNP-Input-thin text-lg cursor-text"
                />
                <h4
                  className={
                    idNoMove
                      ? "w-[105px] BNP-LableMove -translate-y-[54px]"
                      : "w-[158px] BNP-LableStart pt-[2px]  hover:border-BNPblue"
                  }
                >
                  <div className="flex focus:border-BNPblue">
                    ID No/Passport:
                    <div
                      className={
                        idNoMove ? "hidden" : "font-semibold pl-1 text-red-700"
                      }
                    >
                      *
                    </div>
                  </div>
                </h4>
              </div>
              <div className="flex justify-end w-[300px] h-7">
                <Transition
                  show={ClearText}
                  enter="transition ease-in-out duration-700 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-700 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div onClick={clearDiscText} className="w-auto BNP-Links p-1">
                    clear
                  </div>
                </Transition>
              </div>
            </div>
          </div>

          <button
            id="TrackClick"
            ref={btnTrack}
            onClick={() => handleTrackInfo()}
            className={
              btnWait
                ? "BNP-SubmitButtonsBlueWait w-[138px] mb-3"
                : "BNP-SubmitButtonsBlue w-[138px] mb-3"
            }
          >
            Track
          </button>
        </div>

        {/* Border between Search and Order Details */}
        <div className="flex justify-center">
          <div className="flex justify-center border-t-2 border-BNPblue border-opacity-40 w-[95%] lg:w-[90%] transition-all duration-300">
            {/* Error message - No data found */}
            <Transition
              show={errorMsgVisible}
              enter="transition ease-in-out duration-700 transform"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in-out duration-700 transform"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="mt-10 space-y-3 text-center text-red-600 text-2xl">
                <p>
                  We were unable to retrieve tracking information for ID number:
                  '{errorMsg}'.
                </p>
                <p>Please check the ID/Passport entered and try again.</p>
              </div>
            </Transition>
          </div>
        </div>

        {/* Orders Details */}
        <div className="overflow-y-scroll no-scrollbar">
          <Transition
            show={trackingVisible}
            enter="transition ease-in-out duration-700 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="flex-row space-y-3 ">
              {trackingInfo.map((data) => (
                <div key={data.Order_No}>
                  {/* Sales Item */}
                  <TrackingItems
                    ucn={userID}
                    Order_No={data.Order_No}
                    Inv_No={data.Inv_No}
                    SalesDate={data.SalesDate}
                    paymentType={data.paymentType}
                    paid={data.paid}
                    orderType={data.orderType}
                    deliveryType={data.deliveryType}
                    delivering={data.delivering}
                    orderReady={data.orderReady}
                    clientReceived={data.clientReceived}
                    loadNewAccessToken={loadNewAccessToken}
                  />
                </div>
              ))}
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
