import React, { useContext, useEffect, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";

// Context
import ModalContext from "../context/ModalContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function HomePage() {
  // console.log("Render HomePage");

  // Check window size for any changes
  const size = useWindowSize();

  // Context
  const { setCookieModal, cookieModalDelay } = useContext(ModalContext);

  // State
  const [wordingMove, setWordingMove] = useState(false);

  // Check if CookieModal delayed
  const cookieModalCheck = () => {
    if (cookieModalDelay === true) setCookieModal(true);
  };

  useEffect(() => {
    if (size.width < 1300) {
      setWordingMove(true);
    } else {
      setWordingMove(false);
    }
  }, [size]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div onClick={cookieModalCheck()} className="flex font-Montserrat">
        <div className="relative flex w-100">
          {/* Image */}
          <div className="flex justify-start lg:justify-center w-100 my-3 z-0">
            <div className="relative">
              <img
                src="../images/FlyerIMG.png"
                alt="BNP Welcome"
                className="rounded-md opacity-80 BNP-Trans300"
              />
              {/* Wording Location */}
              {/* Top */}
              <div
                className={
                  wordingMove
                    ? "absolute flex justify-end top-0 right-4 my-14 transition-height duration-1000 ease-in-out opacity-0"
                    : "absolute flex justify-end top-0 right-4 my-14 transition-height duration-1000 ease-in-out"
                }
              >
                <div className="flex-row text-center">
                  <div className="text-4xl font-semibold ">Welcome to</div>
                  <div className="text-5xl font-semibold">
                    Bespoke Number Plates
                  </div>
                </div>
              </div>

              {/* Bottom */}
              <div
                className={
                  wordingMove
                    ? "absolute flex justify-start my-8 left-4 bottom-0 transition-height duration-1000 ease-in-out"
                    : "absolute flex justify-start my-8 left-4 bottom-0 transition-height duration-1000 ease-in-out opacity-0"
                }
              >
                <div className="flex-row text-center">
                  <div className="text-2xl font-semibold">Welcome to</div>
                  <div className="text-3xl font-semibold">
                    Bespoke Number Plates
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
