import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";

// formating
import formatCurrency from "../utilities/formatCurrency";
import ViewRegImg from "../utilities/regPlateimgView";
import validateRegNo from "../utilities/validateRegistrationNo";
import formatRegistrationNo from "../utilities/formatRegistrationNo";

// Context
import modalContext from "../context/ModalContext";
import URLContext from "../context/URLContext";
import UserContext from "../context/UserContext";

// Data lookups
import storePlateInfo from "../data/Lookups/plates.json";
import bPlateInfo from "../data/Lookups/bplates.json";
import plateCaseInfo from "../data/Lookups/plateCase.json";
import otherSalesInfo from "../data/Lookups/otherForSaleData.json";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function TrackingItemsProducts(props) {
  // console.log("Render TrackingItemsProducts");

  // Context
  const { googleImgGet } = useContext(URLContext);
  const { accessToken } = useContext(UserContext);
  const {
    setTrackingModalImgPreview,
    setPrevType,
    setPrevDoc,
    msgClean,
    setMsgOpen,
    setMsgTxt,
    setMsgType,
    setNavLink,
    setNavLinkTxt,
  } = useContext(modalContext);

  // State
  const [img, setImg] = useState("");
  const [imgCase, setImgCase] = useState("");
  const [imgDesc, setImgDesc] = useState("");
  const [provinceDesc, setProvinceDesc] = useState("");
  const [regDoc, setRegDoc] = useState(false);
  const [newReg, setNewReg] = useState(props.reg);
  const [trackingModalImg, setTrackingModalImg] = useState(false);

  const effectRunOnce = useRef(false);

  let imgPrevType = "Test";

  useEffect(() => {
    if (effectRunOnce.current === false) {
      let provID;
      let plateSizeID;

      // Get Array index
      if (props.province === "NC") {
        provID = 0;
      } else if (props.province === "WP") {
        provID = 1;
      } else if (props.province === "NW") {
        provID = 2;
      } else if (props.province === "FS") {
        provID = 3;
      } else if (props.province === "GP") {
        provID = 4;
      } else if (props.province === "MP") {
        provID = 5;
      } else if (props.province === "L") {
        provID = 6;
      } else if (props.province === "ZN") {
        provID = 7;
      } else if (props.province === "EC") {
        provID = 8;
      } else if (props.province === "BP") {
        provID = 9;
      }

      // Size Index
      if (props.size === "520") {
        plateSizeID = 0;
      } else if (props.size === "440") {
        plateSizeID = 1;
      } else if (props.size === "250") {
        plateSizeID = 2;
      } else {
        plateSizeID = 0;
      }

      // Plate Case
      const pLateCase = [plateCaseInfo[plateSizeID]];
      const newPlateCase = pLateCase.find((size) => (size = 1));
      setImgCase(newPlateCase.tracking);
      setImgDesc(newPlateCase.sizeDesc);
      // console.log(newPlateCase);

      // plate info
      if (props.prodType === "plate") {
        // Format Reg no
        let valid = validateRegNo(props.reg, props.province);
        setNewReg(formatRegistrationNo(props.reg, props.size, valid));

        const numbers = [storePlateInfo[provID]];
        const newPlateInfo = numbers.find((prov) => (prov = 1));
        if (props.size === 520) {
          setImg(newPlateInfo.size520);
        } else if (props.size === 440) {
          setImg(newPlateInfo.size440);
        } else if (props.size === 250) {
          setImg(newPlateInfo.size250);
        }

        // Set Preview Cient docs
        if (props.province?.length === 0) {
          setProvinceDesc("");
          setRegDoc(false);
        } else {
          setProvinceDesc(newPlateInfo?.name);
          setRegDoc(true);
        }
        // console.log(newPlateInfo)
      }

      // backing plate info
      if (props.prodType === "bplate") {
        const bpInfo = [bPlateInfo[plateSizeID]];
        const newBpInfo = bpInfo.find((size) => (size = 1));
        if (props.size === 520) {
          setImg(newBpInfo.bpsize520);
        } else if (props.size === 440) {
          setImg(newBpInfo.bpsize440);
        } else if (props.size === 250) {
          setImg(newBpInfo.bpsize250);
        }
        // console.log(newBpInfo)
      }

      // Rivet Into
      if (props.prodType === "rivet") {
        const rivets = [otherSalesInfo[0]];
        const rivetNewInfo = rivets.find((id) => (id = 1));
        // console.log(rivetNewInfo);

        setImg(rivetNewInfo.img);
        setImgCase(newPlateCase.rivetTracking);
        setImgDesc(rivetNewInfo.desc);
        // console.log(newPlateCase)
      }
      effectRunOnce.current = true;
    }
  }, [props]);

  const PreviewRegDocs = async (i) => {
    setTrackingModalImg(false);
    if (i === "ID") {
      try {
        imgPrevType = "ID Document";
        // Get secure URL
        setPrevDoc(await getSecureImgURL(props.IDimg));
      } catch (error) {}
      if (trackingModalImg) {
        setPrevType("ID");
        setTrackingModalImgPreview(true);
      }
    }
    if (i === "Reg") {
      try {
        imgPrevType = "Vehicle Registration ";
        // Get secure URL
        setPrevDoc(await getSecureImgURL(props.Regimg));
      } catch (error) {}
      if (trackingModalImg) {
        setPrevType("Reg");
        setTrackingModalImgPreview(true);
      }
    }
    if (i === "Selfie") {
      try {
        imgPrevType = "Selfie ";
        // Get secure URL
        setPrevDoc(await getSecureImgURL(props.Selfimg));
      } catch (error) {}
      if (trackingModalImg) {
        setPrevType("Selfie");
        setTrackingModalImgPreview(true);
      }
    }
  };

  // Get secure URL
  const getSecureImgURL = async (img) => {
    // check token is still valid
    document.getElementById("validateClick")?.click(); // Refresh token
    // console.log(accessToken)

    let url = googleImgGet + img;
    const res = await axios
      .get(
        url,
        { headers: { Authorization: accessToken } } // JWS Token
      )
      .then((res) => res.data)
      .catch((err) => {
        setTrackingModalImg(false);
        msgClean();

        setMsgTxt(
          imgPrevType +
            " image is not available. Please contact support for assistance."
        );
        setNavLink("/Contacts");
        setNavLinkTxt("Contact Us");
        setMsgType("Info");
        setMsgOpen(true);
      });
    // console.log(res);

    if (res?.length > 0) setTrackingModalImg(true);
    return res;
  };

  // console.log(props);
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="flex-row p-3 border-2 border-BNPblue border-opacity-40 rounded-md ">
        <div className="flex-row lg:flex justify-between">
          {/* Image */}
          <div className="flex items-center mb-3 lg:mb-0">
            <div className={imgCase}>
              <ViewRegImg // Has to start with Uppercase letter
                Cformat={"2"}
                CType={props.prodType}
                Cimgs={img}
                CpID={props.province + "^" + props.size + "^" + props.reg}
                CregNo={newReg}
                Ccase={imgCase}
              />
            </div>
          </div>
          {/* Product info */}
          <dir className="flex lg:ml-3">
            <div className="flex-row w-auto mt-1 mb-2 mr-5">
              <p className="font-semibold text-BNPblue">Item Description:</p>
              <p className="text-BNPblue">{provinceDesc} </p>
              <p className="text-BNPblue">{imgDesc}</p>
              <p className="text-BNPblue">{props.reg}</p>
            </div>
            <div className="flex">
              <div className="flex-row h-[85px] w-32 mt-1 mr-1 pr-2">
                <p className="text-right pr-1 text-BNPblue">
                  R {formatCurrency(props.price)}
                </p>
                <div className="flex justify-between items-start pb-1 border-b-2 border-BNPblue border-opacity-50">
                  <p className="text-BNPblue pl-2">Qty:</p>
                  <p className="pr-1 text-BNPblue">{props.quant}</p>
                </div>
                <p className="text-BNPblue text-right pt-1 font-semibold border-b-2 pb-1 border-BNPblue border-opacity-50">
                  R {formatCurrency(props.price * props.quant)}
                </p>
              </div>
            </div>
          </dir>
        </div>
        {/* view Legal docs */}
        <div className={regDoc ? "flex-row" : "hidden"}>
          <div className="flex-row lg:flex justify-center text-center space-x-10">
            <p className="font-semibold">View Documents:</p>
          </div>
          <div className="flex-row lg:grid px-[5%] lg:grid-cols-3">
            <div
              onClick={() => PreviewRegDocs("ID")}
              className="flex justify-center BNP-Links w-full p-0 m-0 text-center lg:text-left"
            >
              ID/Passport
            </div>
            <div
              onClick={() => PreviewRegDocs("Reg")}
              className="flex justify-center BNP-Links w-full p-0 m-0 text-center"
            >
              Vehile Registration
            </div>
            <div
              onClick={() => PreviewRegDocs("Selfie")}
              className="flex justify-center BNP-Links w-full p-0 m-0 text-center lg:text-right"
            >
              Selfie
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
