import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// React Icons
import * as aiIcons from "react-icons/ai";

// Context
import modalContext from "../context/ModalContext";
import SalesCartContext from "../context/SalesCartContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function SalePayFastPage() {
  // console.log("Render SalePayFastPage")

  // Navigate
  const nav = useNavigate();

  // Context
  const { setSaleYocoCashOpen, openPaymentTypes, setOpenPaymentTypes } =
    useContext(modalContext);

  const { invNo, cashPay } = useContext(SalesCartContext);

  // State
  const [msgTop1, setMsgTop1] = useState("Top");
  const [msgTop2, setMsgTop2] = useState("");
  const [msgTop3, setMsgTop3] = useState("");
  const [msgBottom, setMsgBottom] = useState("Bottom");

  // Close
  const closeClick = () => {
    setSaleYocoCashOpen(false);
    if (openPaymentTypes === true) {
      setOpenPaymentTypes(false);
      nav("/Tracking");
    } else {
      nav("/PaySuccess");
    }
  };

  useEffect(() => {
    if (cashPay === true) {
      setMsgTop1("You have selected to");
      setMsgTop2("TAP your Credit/Debit card or");
      setMsgTop3("to Pay Cash on Collection.");
      setMsgBottom(
        "Note that the order will only be Finalised and items picked once the CARD/CASH payment has been made!"
      );
    } else {
      setMsgTop1("You have selected to");
      setMsgTop2("TAP your Credit or Debit card");
      setMsgTop3("on Collection to make payment.");
      setMsgBottom(
        "Note that the order will only be Finalised and items picked once the CARD payment has been made!"
      );
    }
  }, [invNo, cashPay]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="flex justify-center max-h-full font-Montserrat">
        <div className="flex-row pt-[5%] pb-100 px-100">
          {/* Header */}
          <div className="relative flex justify-center items-center w-[500px] h-[70px] text-BNPgoldLight bg-BNPblue shadow-md shadow-BNPblue">
            <div className="text-3xl font-semibold">
              <h1>Order Confirmation.</h1>
            </div>
            <div className="absolute top-5 left-5">
              <div className="pt-1 pl-1">
                <aiIcons.AiOutlineClose
                  className="rounded-full focus:outline-none transition-all duration-500 scale-[2.2] hover:font-semibold hover:scale-[2.9] hover:rotate-90
                  hover:border-2 hover:rounded-full hover:border-BNPgoldLight cursor-pointer hover:transition-all hover:duration-700"
                  onClick={closeClick}
                />
              </div>
            </div>
          </div>

          {/* Body */}

          <div className="flex-row justify-between px-2 py-4 bg-white rounded-b-md text-BNPblue shadow-md shadow-BNPblue">
            <div className="flex-row items-center justify-between  mx-14">
              <div className="flex-row items-center font-bold text-center text-xl pb-4">
                <h1>{msgTop1}</h1>
                <h1>{msgTop2}</h1>
                <h1>{msgTop3}</h1>
              </div>
              <div className="flex justify-center items-center text-lg">
                <p>Payment reference: </p>
                <p className=" font-semibold text-center text-lg ml-3">
                  {invNo}
                </p>
              </div>

              <div className="flex justify-center text-center text-lg mt-3 pt-3 border-t-2 border-BNPblue border-opacity-40 text-red-700">
                <p>{msgBottom}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
