import React, { useContext, useEffect, useState } from "react";

// React Icons
import * as aiIcons from "react-icons/ai";

// Context
import modalContext from "../context/ModalContext";
import salesContext from "../context/SalesCartContext";
import UserContext from "../context/UserContext";

export default function HomePage() {
  // console.log("Render Delivery methods");

  // Context
  const { deliveryMethodOpen, setdeliveryMethodOpen, setDeliveryAddressOpen } =
    useContext(modalContext);
  const { deliveryCheck, setDeliveryCheck, UpdateCartTotal } =
    useContext(salesContext);
  const { Address, Province } = useContext(UserContext);

  // State
  const [deliverFlag, setdeliverFlag] = useState("DeliverRadio");

  useEffect(() => {
    if (deliveryCheck === true) {
      setdeliverFlag("DeliverRadio");
    } else {
      setdeliverFlag("CollectRadio");
    }
  }, [deliveryCheck]);

  const closeOutSide = (e) => {
    // console.log(e.target.id);
    if (e.target.id === "DelMethod_Close") closeClick();
  };

  const closeClick = () => {
    setdeliveryMethodOpen(!deliveryMethodOpen);
    if (deliveryCheck === true) {if (Address === "" && Province === "") setDeliveryAddressOpen(true);}
    // console.log("closed")
  };

  // Radio Button Changes
  const deliverClick = () => {
    if (deliverFlag === "DeliverRadio") {
      setDeliveryCheck(false);
    } else {
      setDeliveryCheck(true);
    }
    UpdateCartTotal();
  };

  return (
    <>
      <div
        onClick={closeOutSide}
        className="flex justify-center max-h-full font-Montserrat "
      >
        <div id="DelMethod_Close" className="flex-row pt-[10%] pb-100 px-100">
          {/* Header */}
          <div className="flex justify-center items-center w-[400px] h-[70px] text-BNPgoldLight bg-BNPblue shadow-md shadow-BNPblue">
            <div className="text-3xl font-semibold">
              <h1>Delivery Mothods</h1>
            </div>
            <div className="absolute -mt-5 -ml-[351px]">
              <div className="pt-1 pl-1">
                <aiIcons.AiOutlineClose
                  className="rounded-full focus:outline-none transition-all duration-500 scale-[2.2] hover:font-semibold hover:scale-[2.9] hover:rotate-90
                  hover:border-2 hover:rounded-full hover:border-BNPgoldLight cursor-pointer hover:transition-all hover:duration-700"
                  onClick={closeClick}
                />
              </div>
            </div>
          </div>

          {/* Body */}
          <div className="flex justify-between px-2 bg-BNPgoldLightLight text-BNPblue font-semibold shadow-md shadow-BNPblue">
            <div
              onClick={() => {
                setdeliverFlag("DeliverRadio");
                setDeliveryCheck(true);
                if (Address === "" && Province === "") setDeliveryAddressOpen(true);
                setdeliveryMethodOpen(false);
              }}
              className="flex-row p-5 cursor-pointer border-r-4"
            >
              <div> Delivery my order</div>
              <div className="flex w-100 justify-center ">
                <input
                  type="radio"
                  name="typebtn"
                  value={deliveryCheck}
                  checked={deliverFlag === "DeliverRadio"}
                  onChange={() => deliverClick}
                  className="mt-3 text-center scale-150 hover:cursor-pointer "
                />
              </div>
            </div>
            <div
              onClick={() => {
                setdeliverFlag("CollectRadio");
                setDeliveryCheck(false);
                setdeliveryMethodOpen(false);
              }}
              className="flex-row p-5 cursor-pointer  border-l-4"
            >
              <div> Collect my order</div>
              <div className="flex w-100 justify-center">
                <input
                  type="radio"
                  name="typebtn"
                  value={deliveryCheck}
                  checked={deliverFlag === "CollectRadio"}
                  onChange={() => deliverClick}
                  className="mt-3 scale-150 hover:cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
