import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

// Context
// import userContext from "../context/UserContext";
import SalesCartContext from "../context/SalesCartContext";
import ModalContext from "../context/ModalContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function SaleSuccessfulPage() {
  // console.log("Render SaleSuccessfulPage")

  // Context
  const { cartItems, emptyCart } = useContext(SalesCartContext);
  const { testing } = useContext(ModalContext);

  useEffect(() => {
    if (cartItems.length > 0 && testing === 0) {
      // Clear cart
     emptyCart();   //  Insert this to clear cart after sale
    }
  }, [cartItems, emptyCart, testing]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="flex-row w-100 font-Montserrat text-center text-BNPblue my-7 space-y-5">
      <div className="flex justify-center items-center font-semibold text-4xl animate-pulse duration-300">
        <h1 className="mr-4">Sale completed successfully</h1>
      </div>
      <div className="flex justify-center items-center font-semibold text-3xl">
        <h1>Thank you for doing business with BNP.</h1>
      </div>

      <div className="flex justify-center items-center font-semibold text-2xl pt-5">
        <h1>Time to keep an eye on our tracking page.</h1>
      </div>

      <div className="flex justify-center items-center font-semibold text-2xl pt-3">
        <div className="flex-row justify-center items-center">
          <div className="flex justify-center">
            <h1 className="border-b-2 border-BNPblue border-opacity-40 pb-1">
              If you chose to Collect.
            </h1>
          </div>
          <h1 className=" font-semibold text-lg">
            We will inform you shortly when your item/s are ready for collection.
          </h1>
        </div>
      </div>

      <div className="flex justify-center items-center font-semibold text-2xl">
        <h1>or</h1>
      </div>

      <div className="flex justify-center items-center font-semibold text-2xl">
        <div className="flex-row justify-center items-center">
          <div className="flex justify-center">
            <h1 className="border-b-2 border-BNPblue border-opacity-40 pb-1">
              If you select Delivery and are within the Pretoria area.
            </h1>
          </div>
          <h1 className="mt-2 font-semibold text-lg">
            We will contact you shortly to make arrangements for delivery and
            fitment.
          </h1>
        </div>
      </div>

      <div className="flex justify-center items-center font-semibold text-2xl pt-3">
        <div className="flex-row justify-center items-center">
          <div className="flex justify-center">
            <h1 className="border-b-2 border-BNPblue border-opacity-40 pb-1">
              If you selected Delivery and are outside the Pretoria area.
            </h1>
          </div>
          <h1 className="mt-2 font-semibold text-lg">
            Your items will be packaged and given to the courier company for
            delivery.
          </h1>
        </div>
      </div>

      <div className="flex justify-center items-center mt-3 font-semibold text-xl">
        <h1>Go to </h1>

        <div className="BNP-Links">
          <Link to="/Tracking">Tracking </Link>
        </div>

        <h1 className="mr-3">page</h1>
      </div>
    </div>
  );
}
