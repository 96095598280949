
const userTokenGetTime = () => {

  let validTime = 5 * 60 * 1000 // add 5 minutes to the timestamp
  // let validTime = 20000 // add 20 seconds to the timestamp
  // let validTime = 10000 // add 10 seconds to the timestamp
 
  // console.log(validTime)
  return {
    timestamp: new Date().getTime() + validTime
  };
}

export default userTokenGetTime;