import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";

// formating
import RemoveFunnies from "./fromatRemoveFunnies";
import isNumeric from "./isNumeric";

///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
export default function CarouselImg(props) {
  const [triggerClean, setTriggerClean] = useState(false);

  const [dirEnterFrom, setDirEnterFrom] = useState("n");
  const [dirEnterTo, setDirEnterTo] = useState("n");

  const [dirLeaveFrom, setDirLeaveFrom] = useState("n");
  const [dirLeaveTo, setDirLeaveTo] = useState("n");

  const [pWordingCase, setPWordingCase] = useState("text-blue-950");

  const [sz520, setSZ520] = useState(false);
  const [sz440, setSZ440] = useState(false);
  const [sz250, setSZ250] = useState(false);

  const [provHide, setProvHide] = useState(false);
  const [newCpWording, setNewCpWording] = useState(props.CpWording);


  const [Lfix250, setLfix250] = useState(true);

  useEffect(() => {
  
    if (props.CCProvince === "L") {
      setLfix250(true);
    } else {
      setLfix250(false);
    }

    if (props.Ctrigger !== "true") {
      setTriggerClean(props.Ctrigger);
    } else {
      setTriggerClean(true);
    }

    if (props.Cdirection === "top") {
      setDirEnterFrom("-translate-y-full opacity-0");
      setDirEnterTo("translate-y-0 opacity-100");
      setDirLeaveFrom("translate-y-0 opacity-100");
      setDirLeaveTo("translate-y-full opacity-0");
    } else if (props.Cdirection === "left") {
      setDirEnterFrom("translate-x-full opacity-0");
      setDirEnterTo("translate-x-0 opacity-100");
      setDirLeaveFrom("translate-x-0 opacity-100");
      setDirLeaveTo("-translate-x-full opacity-0");
    }

    // Remove Province for KZN and CA old numbers
    if (props.CRnValidate.substring(0, 2) === "WP") {
      setProvHide(true);
    } else if (props.CRnValidate.substring(0, 3) === "KZN") {
      setProvHide(true);
    } else {
      setProvHide(false);
    }

    // Get color Array
    if (props.CCProvince === "NC") {
      setPWordingCase("text-green-950 BNP-Trans300");
    } else if (props.CCProvince === "WP") {
      setPWordingCase("text-black BNP-Trans300");
    } else if (props.CRnValidate.substring(0, 2) === "WP") {
      setPWordingCase("text-black BNP-Trans300");
    } else if (props.CCProvince === "NW") {
      setPWordingCase("text-black BNP-Trans300");
    } else if (props.CCProvince === "FS") {
      if (props.CRnValidate === "OT-S") {
        setPWordingCase("text-black BNP-Trans300");
      } else {
        setPWordingCase("text-green-800 BNP-Trans300");
      }
    } else if (props.CCProvince === "GP") {
      setPWordingCase("text-blue-950 BNP-Trans300");
    } else if (props.CCProvince === "MP") {
      setPWordingCase("text-black BNP-Trans300");
    } else if (props.CCProvince === "L") {
      setPWordingCase("text-black BNP-Trans300");
    } else if (props.CCProvince === "ZN") {
      setPWordingCase("text-green-800 BNP-Trans300");
    } else if (
      props.CRnValidate.substring(0, 5) === "KZN-3" ||
      props.CRnValidate.substring(0, 5) === "KZN-2"
    ) {
      setPWordingCase("text-blue-900 BNP-Trans300");
    } else if (props.CCProvince === "EC") {
      setPWordingCase("text-black BNP-Trans300");
    } else {
      setPWordingCase("text-blue-950 BNP-Trans300");
    }

    // KZN New plate - AB 12 CD ZN
    let RegClean = RemoveFunnies(props.CpWording);
    if (
      props.CCProvince === "ZN" &&
      isNumeric(RegClean.substring(0, 1)) === false &&
      isNumeric(RegClean.substring(1, 2)) === false &&
      isNumeric(RegClean.substring(2, 3)) === true &&
      isNumeric(RegClean.substring(3, 4)) === true &&
      isNumeric(RegClean.substring(4, 5)) === false &&
      isNumeric(RegClean.substring(5, 6)) === false
    ) {
      setPWordingCase("text-blue-950 BNP-Trans300");
    }

    if (props.CpSize === "520") {
      setSZ520(true);
      setSZ440(false);
      setSZ250(false);
    } else if (props.CpSize === "440") {
      setSZ520(false);
      setSZ440(true);
      setSZ250(false);
    } else if (props.CpSize === "250") {
      setSZ520(false);
      setSZ440(false);
      setSZ250(true);
    }

    // Remove Province when RegNo has a province
    if (RemoveFunnies(RegClean).length >= 0 && props.CpProvince.length === 0) {
      setProvHide(false);
      // console.log("hidden");
    } else {
      setProvHide(true);
      // console.log("not hidden");
    }

    if (
      (props.CCProvince === "GP" && props.CpSize === "440") ||
      (props.CCProvince === "ZN" && props.CpSize === "440")
    ) {
      setNewCpWording(RemoveFunnies(props.CpWording));
      // console.log("440");
    } else {
      setNewCpWording(props.CpWording);
      // console.log("520-250");
    }
  }, [props, provHide, newCpWording]);

  // console.log(props.CpProvince);
  // console.log(props.Cdirection)
  // console.log(props.CpWording);
  // console.log(dirEnterTo)
  // console.log(dirLeaveFrom)
  // console.log(dirLeaveTo)

  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Transition
        show={triggerClean}
        enter="transition ease-in-out duration-700 transform"
        enterFrom={dirEnterFrom}
        enterTo={dirEnterTo}
        leave="transition ease-in-out duration-700 transform"
        leaveFrom={dirLeaveFrom}
        leaveTo={dirLeaveTo}
      >
        <div className="relative flex-shrink justify-center items-center   border-2 border-green-600">
          {/* Plate IMG */}
          <img src={props.Cimgs} alt="" className={props.CimgClass} />

          {/* NoPlate letters */}
          <div className="">
            {/* 520 */}
            <Transition
              show={sz520}
              enter="transition ease-in-out duration-500 transform"
              enterFrom="-translate-x-full opacity-0"
              enterTo="translate-x-0 opacity-100"
              leave="transition ease-in-out duration-500 transform"
              leaveFrom="translate-x-0 opacity-100"
              leaveTo="translate-x-full opacity-0"
              className="absolute flex justify-between items-center top-0 left-0 w-100 font-sans font-bold text-[70px] scale-x-100 scale-y-130 h-[80px] mt-[18px]    border-2 border-red-600"
            >
              <div className={pWordingCase}>
                <div className="flex justify-between items-center">
                  <p
                    className={
                      provHide
                        ? "flex justify-center items-center w-[347px] ml-[8px] transition-all duration-500 whitespace-nowrap "
                        : "flex justify-center items-center w-[500px] ml-[8px] transition-all duration-500 whitespace-nowrap"
                    }
                  >
                    {newCpWording}
                  </p>
                  <Transition // Move Province in and out
                    show={provHide}
                    enter="transition ease-in-out duration-500 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-500 transform"
                    leaveFrom="translate-x-0 opacity-100"
                    leaveTo="translate-x-full opacity-0"
                  >
                    <p className="flex justify-end w-[108px] ml-[42px]">
                      {props.CpProvince}
                    </p>
                  </Transition>
                </div>
              </div>
            </Transition>

            {/* 440 */}
            <Transition
              show={sz440}
              enter="transition ease-in-out duration-500 transform"
              enterFrom="-translate-x-full opacity-0"
              enterTo="translate-x-0 opacity-100"
              leave="transition ease-in-out duration-500 transform"
              leaveFrom="translate-x-0 opacity-100"
              leaveTo="translate-x-full opacity-0"
              className="absolute flex justify-between items-center top-0 left-0 w-100 font-sans font-bold text-[70px] scale-x-100 scale-y-130 h-[96px] mt-[24px]    border-2 border-purple-600"
            >
              <div className={pWordingCase}>
                <div className="flex justify-between items-center">
                  <p
                    className={
                      provHide
                        ? "flex justify-center w-[335px] ml-[10px] transition-all duration-500 whitespace-nowrap"
                        : "flex justify-center w-[500px] ml-[10px] transition-all duration-500 whitespace-nowrap"
                    }
                  >
                    {newCpWording}
                  </p>
                  <Transition // Move Province in and out
                    show={provHide}
                    enter="transition ease-in-out duration-500 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-500 transform"
                    leaveFrom="translate-x-0 opacity-100"
                    leaveTo="translate-x-full opacity-0"
                  >
                    <p className="flex justify-end w-[117px] ml-[40px]">
                      {props.CpProvince}
                    </p>
                  </Transition>
                </div>
              </div>
            </Transition>

            {/* 250 */}
            <Transition
              show={sz250}
              enter="transition ease-in-out duration-500 transform"
              enterFrom="-translate-x-full opacity-0"
              enterTo="translate-x-0 opacity-100"
              leave="transition ease-in-out duration-500 transform"
              leaveFrom="translate-x-0 opacity-100"
              leaveTo="translate-x-full opacity-0"
              className="absolute flex justify-between items-center top-0 left-0 w-100 font-sans font-bold text-[70px] scale-x-100 scale-y-130 h-[147px] mt-[30px]    border-2 border-orange-600"                 
            >
              <div className={pWordingCase}>
                <div className="flex-row">
                  <p className="flex justify-center w-[170px]">
                    {RemoveFunnies(newCpWording).substring(0, 3)}
                  </p>
                  <div className="flex w-[250px] -mt-[33px]">
                    <p
                      className={
                        Lfix250
                          ? "flex w-100 justify-start items-center ml-[10px] pl-[20px]"
                          : "flex w-100 justify-center items-center ml-[10px]"
                      }
                    >
                      {RemoveFunnies(newCpWording).substring(3, 10)}
                    </p>
                    <Transition // Move Province in and out
                      show={provHide}
                      enter="transition ease-in-out duration-500 transform"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition ease-in-out duration-500 transform"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <p
                        className={
                          Lfix250
                            ? "flex justify-end items-center mr-[20px]"
                            : "flex justify-end items-center mr-[11px]"
                        }
                      >
                        {props.CpProvince}
                      </p>
                    </Transition>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </Transition>
    </>
  );
}
