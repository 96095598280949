import React, { useContext } from "react";

// Context
import SalesCartContext from "../context/SalesCartContext";
import ViewRegImg from "../utilities/regPlateimgView";

export default function SalesCartItems() {
  // Context

  const { cartItems } = useContext(SalesCartContext);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="flex-row overflow-x-scroll overflow-y-hidden">
        {cartItems.map((cartItem) => (
          <div key={cartItem.id}
          className="mr-1 p-2 border-2 border-BNPblue border-opacity-70 bg-BNPgoldLightLight">
            {/* <img src={cartItem.plate} alt="" className={cartItem.pCase}  /> */}

            <ViewRegImg // Has to start with Uppercase letter
            Cformat={"0"}
                    CType={cartItem.prodType}
                    Cimgs={cartItem.plate}
                    CpID={cartItem.id}
                    CregNo={cartItem.reg}
                    Ccase={cartItem.pCase}
                  />

            
          </div>
        ))}
      </div>
    </>
  );
}
