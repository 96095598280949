
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function AdminSalesDelivery(props) {

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div 
      className=" text-BNPblue">
        <div className="flex justify-between font-Montserrat p-4 bg-white">
          {/* Column 1 */}
          <div className="flex-row items-center pr-6">
            {/* Order Number */}
            <div className="flex-row justify-between items-center">
              <p className="font-semibold text-lg">Order number:</p>
              <p className="text-right text-lg">{props.id}</p>
            </div>
          

          </div>

          {/* Column 2 */}

          <div className="flex-row w-[200px] items-center">
            {/* Address Info */}
            <div className="flex items-center">
              <p className="font-semibold text-lg mr-10 ">Address Info:</p>
            </div>

            <div className="flex-row"> {props.City} </div>
            <div className="flex-row"> {props.Suburb} </div>
          </div>

          {/* Column 3 */}
          <div className="flex-row w-[200px] items-center">
            {/* Client Info */}
            <div className="flex items-center">
              <p className="font-semibold text-lg mr-10 ">Client Info:</p>
            </div>
            <p>{props.bussinesName}</p>
            <p>{props.DeliveryName}</p>
          </div>


        </div>
      </div>
    </>
  );
}
