import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";

// Pages
import CartItems from "./SalesCartItems";

// formating
import formatCurrency from "../utilities/formatCurrency";

// React Icons
import * as aiIcons from "react-icons/ai";
import * as bsIcons from "react-icons/bs";
import * as tbIcons from "react-icons/tb";
import * as hiIcons from "react-icons/hi2";
import * as imIcons from "react-icons/im";

// Context
import SalesCartContext from "../context/SalesCartContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function CartModal() {
  // console.log("Render CartModal");

  // Context
  const {
    cartClick,
    setCartClick,
    cartItems,
    emptyCart,
    cartVol,
    updateCartCost,
  } = useContext(SalesCartContext);

  // State
  const [checkOut, setcheckOut] = useState(true);

  // ToolTips
  const [BTNclearCartTT, setBTNclearCartTT] = useState(false);

  const closeOutSide = (e) => {
    // console.log(e.target.id);
    if (e.target.id === "Cart_Close") closeClick();
  };

  const closeClick = () => {
    setCartClick(!cartClick);
    // if (cartVol === 0) { localStorage.removeItem("BNPcart"); }
  };

  // Check window size for any changes
  const size = useWindowSize();

  // Screen Size effect
  useEffect(() => {
    if (cartVol === 0) {
      setcheckOut(false);
    }
  }, [cartVol]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div
        id="Cart_Close"
        onClick={closeOutSide}
        className="flex justify-end max-h-full font-Montserrat overflow-y-scroll no-scrollbar bg-BNPgoldLightlight bg-opacity-10"
      >
        <div className="hidden">
          {/* flex-row */}
          <div>W:{size.width}</div>
          <div>H:{size.height}</div>
        </div>
        <div className="BNP-CartModal w-[350px] lg:w-[670px]">
          {/* Header */}
          <div className="flex-row text-BNPgoldLight bg-BNPblue ">
            <div className="pt-5 pl-5">
              <div className="pt-1 pl-1">
                <aiIcons.AiOutlineClose
                  className="rounded-full focus:outline-none transition-all duration-500 scale-[2.2] hover:font-semibold hover:scale-[2.9] hover:rotate-90
                    hover:border-2 hover:rounded-full hover:border-BNPgoldLight hover:transition-all hover:duration-700"
                  onClick={closeClick}
                />
              </div>
            </div>
            <h1 className="flex justify-center items-center text-3xl font-semibold h-[70px] -mt-5">
              My Cart
            </h1>
            <div className="flex justify-end pb-1">
              <imIcons.ImBin
                onClick={() => {
                  emptyCart();
                  closeClick();                 
                }}
                onMouseEnter={() => setBTNclearCartTT(true)}
                onMouseLeave={() => setBTNclearCartTT(false)}
                className="mx-5 mb-4 -mt-4 scale-150"
              />
              <p
                className={
                  BTNclearCartTT
                    ? "absolute mr-[50px] -mt-6 w-[117PX] BNP-Tooltip border-2 border-BNPgoldLight"
                    : "hidden"
                }
              >
                Empty Cart
              </p>
            </div>
          </div>
          {/* Content */}
          <div className="mt-2 mx-1">
            <CartItems />
          </div>
          <div className="flex-row lg:justify-between lg:flex">
            {/* Extra info */}
            <div className="flex justify-end">
              <div className="flex-row items-center  m-1 mx-2 px-3 py-5 space-y-2 w-[340px] border-2 text-BNPblue border-BNPblue bg-BNPgoldLight">
                <div className="flex items-center">
                  <aiIcons.AiFillLock className="mr-3" />
                  <p>Secure checkout</p>
                </div>
                <div className="flex items-center">
                  <bsIcons.BsCreditCard2Back className="mr-3" />
                  <p>Multiple ways to pay</p>
                </div>
                <div className="flex items-center">
                  <tbIcons.TbTruckDelivery className="mr-3" />
                  <p>Fast reliable delivery & </p>
                  <hiIcons.HiWrenchScrewdriver className="mx-2" />
                  <p> Fitment</p>
                </div>
              </div>
            </div>

            {/* SubTotal */}
            <div className="flex justify-center lg:justify-end">
              <div className="flex-row m-1 mx-2 p-3 w-[250px] border-2 text-BNPblue border-BNPblue bg-BNPgoldLight bg-opacity-90">
                <p className="flex justify-center font-semibold pl-5 pb-1 border-b-2 border-BNPblue border-opacity-40">
                  Cart Summary
                </p>

                <div className="flex w-auto justify-between pt-3 mr-[28px]">
                  <p className="flex justify-start w-auto ml-5">Items:</p>
                  <div className="flex justify-end w-1/2">
                    {cartItems.reduce((total, cartItem) => {
                      const item = cartItems.find(
                        (item) => item.id === cartItem.id
                      );
                      return total + (item?.quant || 0);
                    }, 0)}
                    .
                  </div>
                </div>

                <div className="flex justify-between w-auto mt-1 py-1 font-semibold text-lg tracking-wider border-b-2 border-BNPblue border-opacity-40">
                  <p className="flex justify-start w-auto ml-5 pt-[6px]">
                    Total:
                  </p>
                  <div className="flex justify-start w-auto pl-4 pt-1 border-t-2 border-BNPblue border-opacity-40">
                    R{" "}
                    {formatCurrency(
                      cartItems.reduce((total, cartItem) => {
                        const item = cartItems.find(
                          (item) => item.id === cartItem.id
                        );
                        return total + (item?.price || 0) * cartItem.quant;
                      }, 0)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={checkOut ? "flex py-4 mr-2" : "hidden"}>
            <Link
              to={"/payreview"}
              onClick={() => {
                updateCartCost();
                closeClick();
              }}
              className="ms-auto w-auto px-8"
            >
              <button className=" text-lg rounded-md p-3 BNP-SubmitButtonsBlue">
                <p>Proceed to Checkout</p>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
