import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import axios from "axios";

// Context
import UserContext from "../context/UserContext";
import UrlContext from "../context/URLContext";
import modalContext from "../context/ModalContext";

// Icons
import * as io5Icons from "react-icons/io5";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function PasswordChangePage() {
  // console.log("Render PasswordChangePage")

  // Hashing
  const bcrypt = require("bcryptjs-react");

  // Navigate
  const nav = useNavigate();

  // Context
  const { userPasswordChange } = useContext(UrlContext);
  const {
    userID,
    userLogin,
    userAdmin,
    userPassword,
    setUserPassword,

    accessToken,
  } = useContext(UserContext);
  const { setPwRestLock, msgClean, setMsgOpen, setMsgTxt, setMsgType } =
    useContext(modalContext);

  // State
  const [btnDisable, setBtnDisable] = useState(false);
  const [pswTotal, setPswTotal] = useState(false);

  const pswOldRef = useRef();
  const [userPswOld, setUserPswOld] = useState("");
  const [pswOldMove, setPswOldMove] = useState(false);
  const [pswOldError, setPswOldError] = useState(true);
  const [pswOldErrorMsg, setPswOldErrorMsg] = useState("");

  const pswRef = useRef();
  const [userPsw, setUserPsw] = useState("");
  const [pswMove, setPswMove] = useState(false);
  const [pswError, setPswError] = useState(true);
  const [pswErrorMsg, setPswErrorMsg] = useState("");

  const [pwShow, setPwShow] = useState(false);

  const psw2Ref = useRef();
  const [userPsw2, setUserPsw2] = useState("");
  const [psw2Move, setPsw2Move] = useState(false);
  const [psw2Error, setPsw2Error] = useState(true);
  const [psw2ErrorMsg, setPsw2ErrorMsg] = useState("");

  const [progBar1, setProgBar1] = useState(false);
  const [progBar2, setProgBar2] = useState(false);
  const [progBar3, setProgBar3] = useState(false);
  const [progBar4, setProgBar4] = useState(false);
  const [progBar5, setProgBar5] = useState(false);
  const [progBar6, setProgBar6] = useState(false);
  const [progBar7, setProgBar7] = useState(false);
  const [progBar8, setProgBar8] = useState(false);
  const [progBar9, setProgBar9] = useState(false);
  const [progBar10, setProgBar10] = useState(false);

  const [btnWait, setBtnWait] = useState(false);

  // variables
  let loadNewToken = accessToken;

  useEffect(() => {
    if (userLogin === false) {
      nav("/PageNotFound");
    }

    if (
      pswTotal >= 4 &&
      pswOldError === false &&
      psw2Error === false &&
      userPswOld.length > 0 &&
      userPsw.length > 0 &&
      userPsw2.length > 0
    ) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }

    pswChecks(userPsw);

    // Password1 and password2 match confirm
    setPsw2Error(false);
    if (userPsw2 !== userPsw && userPsw2.length !== 0) {
      setPsw2Error(true);
      setPsw2ErrorMsg("Passwords don't match!");
    }

    if (userPswOld.length !== 0) {
      setPswOldMove(true);
    }
    if (userPsw.length !== 0) {
      setPswMove(true);
    }
    if (userPsw2.length !== 0) {
      setPsw2Move(true);
    }
  }, [
    nav,
    userLogin,
    userAdmin,
    pswTotal,
    pswOldError,
    psw2Error,
    userPswOld,
    userPsw,
    userPsw2,
  ]);

  // Password Old
  const pswOldFocusOn = () => {
    setPswOldMove(true);
    pswOldRef.current.focus();
  };

  const pswOldFocusOff = () => {
    if (userPswOld.length !== 0) {
      setPswOldMove(true);
    } else {
      setPswOldMove(false);
    }
  };

  // Old Password vs hash Password on file matches
  const pswOldChecks = (i) => {
    setPswOldError(true);

    if (bcrypt.compareSync(i, userPassword) === false && i.length !== 0) {
      setPswOldErrorMsg("Invalid current password!");
    } else {
      setPswOldError(false);
    }
  };

  // Password 1
  const pswFocusOn = () => {
    setPswMove(true);
    pswRef.current.focus();
  };

  const pswFocusOff = () => {
    if (userPsw.length !== 0) {
      setPswMove(true);
    } else {
      setPswMove(false);
    }
  };

  // Password strenth confirm checks
  function pswChecks(i) {
    setPswError(true);
    let a = 10;
    // regular expressions to validate password strength!
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    var special = /(?=.*[!@#$%&? "])/g;

    if (i.length < 8 && i.length !== 0) {
      setPswErrorMsg("Password should be more than 8 characters.");
      a = a - 2;
    }
    if (!i.match(lowerCase) && i.length !== 0) {
      setPswErrorMsg("Password should contains lowercase letters!");
      a = a - 2;
    }
    if (!i.match(upperCase) && i.length !== 0) {
      setPswErrorMsg("Password should contain uppercase letters!");
      a = a - 2;
    }
    if (!i.match(numbers) && i.length !== 0) {
      setPswErrorMsg("Password should contains numbers also!");
      a = a - 2;
    }
    if (!i.match(special) && i.length !== 0) {
      setPswErrorMsg("Password should contains special characters!");
      a = a - 2;
    }

    setProgBar1(false);
    setProgBar2(false);
    setProgBar3(false);
    setProgBar4(false);
    setProgBar5(false);
    setProgBar6(false);
    setProgBar7(false);
    setProgBar8(false);
    setProgBar9(false);
    setProgBar10(false);

    if (a >= 2) {
      setProgBar1(true);
      setProgBar2(true);
    }
    if (a >= 4) {
      setProgBar1(true);
      setProgBar2(true);
      setProgBar3(true);
      setProgBar4(true);
    }
    if (a >= 6) {
      setProgBar1(true);
      setProgBar2(true);
      setProgBar3(true);
      setProgBar4(true);
      setProgBar5(true);
      setProgBar6(true);
    }
    if (a >= 8) {
      setProgBar1(true);
      setProgBar2(true);
      setProgBar3(true);
      setProgBar4(true);
      setProgBar5(true);
      setProgBar6(true);
      setProgBar7(true);
      setProgBar8(true);
    }
    if (a === 10) {
      setProgBar1(true);
      setProgBar2(true);
      setProgBar3(true);
      setProgBar4(true);
      setProgBar5(true);
      setProgBar6(true);
      setProgBar7(true);
      setProgBar8(true);
      setProgBar9(true);
      setProgBar10(true);
      setPswError(false);
    }

    if (i.length === 0) {
      setProgBar1(false);
      setProgBar2(false);
      setProgBar3(false);
      setProgBar4(false);
      setProgBar5(false);
      setProgBar6(false);
      setProgBar7(false);
      setProgBar8(false);
      setProgBar9(false);
      setProgBar10(false);
    }

    setPswTotal(a);
    // console.log(a);
  }

  // Password 2 info
  const psw2FocusOn = () => {
    setPsw2Move(true);
    psw2Ref.current.focus();
  };

  const psw2FocusOff = () => {
    if (userPsw2.length !== 0) {
      setPsw2Move(true);
    } else {
      setPsw2Move(false);
    }
  };

  const ChangePasswordClick = async () => {
    setBtnWait(true);
    //  Hash the new password before saving to db.
    let hash = bcrypt.hashSync(userPsw, 10);
    setUserPassword(hash);

    // Validate JWT accessToken
    document.getElementById("validateClick")?.click();

    // push new Hash password to db.
    let url = userPasswordChange;
    const data = await axios
      .post(
        url,
        {
          type: "c",
          password: hash,
          ucn_email: userID,
        },
        { headers: { Authorization: loadNewToken } } // JWS Token
      )
      .then((res) => res)
      .catch((err) => {
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });

    try {
      if (data.status === 200) {
        setUserPassword(hash);
        setPwRestLock(false);
        msgClean();
        setMsgTxt(
          `Your "P@s5w0rd" ${String.fromCodePoint("0x1f609")} has been changed!`
        );
        setMsgType("Success");
        setMsgOpen(true);
        nav("/");

        setBtnDisable(true);
        setUserPswOld("");
        setUserPsw("");
        setUserPsw2("");
      } else {
        msgClean();
        setMsgTxt('Error during "P@s5w0rd" change.  please try again.');
        setMsgType("Error");
        setMsgOpen(true);
        // alert("Error during Password change.  please try again.");
      }
    } catch (error) {}
    setBtnWait(false);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="flex h-full ml-10 font-Montserrat bg-opacity-10">
      <div className="flex-row items-center justify-between mt-8">
        <h1 className="flex justify-center text-5xl font-bold">
          Change Password
        </h1>

        <div className="mt-6 ">
          {/* Old Password */}
          <div className="flex justify-center">
            <div className="flex-row justify-center mt-[9px] h-[65px] text-md">
              <div
                onClick={() => pswOldFocusOn()}
                className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
              >
                <input
                  value={userPswOld}
                  onChange={(e) => {
                    setUserPswOld(e.target.value);
                    pswOldChecks(e.target.value);
                  }}
                  onBlur={() => pswOldFocusOff()}
                  type="password"
                  ref={pswOldRef}
                  autoComplete="off"
                  className="w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                />
                <h4
                  className={
                    pswOldMove
                      ? "w-[120px] BNP-LableMove -translate-y-[50px]"
                      : "w-[300px] BNP-LableStart mt-[3px]"
                  }
                >
                  <div className="flex">
                    Current Password
                    <div
                      className={
                        pswOldMove
                          ? "hidden"
                          : "font-semibold pl-1 text-red-700"
                      }
                    >
                      *
                    </div>
                  </div>
                </h4>
              </div>
              {/*  Error Message */}
              <div
                className={
                  pswOldError
                    ? "ml-2 mt-[2px] block text-sm font-semibold text-red-600"
                    : "hidden"
                }
              >
                <Transition
                  show={pswOldError}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {pswOldErrorMsg}
                </Transition>
              </div>
            </div>
          </div>

          {/* New Password */}
          <div className="flex justify-center border-t-2 border-BNPblue border-opacity-30">
            <div className="flex-row justify-center mt-[18px] h-[75px] text-md">
              <div
                onClick={() => pswFocusOn()}
                className="relative group w-[350px] h-[40px] BNP-LableDIV cursor-text"
              >
                <input
                  id="idNewPassword"
                  value={userPsw}
                  onChange={(e) => setUserPsw(e.target.value)}
                  onBlur={() => pswFocusOff()}
                  type={pwShow ? "text" : "password"}
                  ref={pswRef}
                  autoComplete="off"
                  className="w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                />
                <h4
                  className={
                    pswMove
                      ? "w-[100px] BNP-LableMove -translate-y-[50px] "
                      : "flex w-[300px] BNP-LableStart mt-[3px]"
                  }
                >
                  <div className="flex">
                    New Password
                    <div
                      className={
                        pswMove ? "hidden" : "font-semibold pl-1 text-red-700"
                      }
                    >
                      *
                    </div>
                  </div>
                </h4>
                <div
                  className="absolute flex justify-center items-center top-[6px] right-2 h-8 w-8 scale-150 cursor-pointer"
                  onClick={() => {
                    setPwShow(!pwShow);
                  }}
                  onMouseLeave={() => {
                    setPwShow(false);
                  }}
                >
                  <div className="relative flex justify-center items-center">
                    <io5Icons.IoEyeSharp
                      className={
                        pwShow
                          ? "absolute transition-height duration-300 ease-in-out" // Show
                          : "absolute transition-height duration-300 ease-in-out opacity-0" // Hidden
                      }
                    />
                    <io5Icons.IoEyeOffSharp
                      className={
                        pwShow
                          ? "absolute transition-height duration-300 ease-in-out opacity-0" // Hidden
                          : "absolute transition-height duration-300 ease-in-out" // Show
                      }
                    />
                  </div>
                </div>
              </div>
              {/* Password status bar */}
              <div className="flex justify-center w-100">
                <div className="flex justify-between w-[98.5%] h-[10px] border-2 rounded-md border-BNPblue border-opacity-30">
                  <div
                    className={
                      progBar1
                        ? "flex justify-center w-100 bg-gradient-to-r from-red-800 to-orange-800"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar2
                        ? "flex justify-center w-100 bg-gradient-to-r from-orange-800 to-orange-600"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar3
                        ? "flex justify-center w-100 bg-gradient-to-r from-orange-600 to-orange-500"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar4
                        ? "flex justify-center w-100 bg-gradient-to-r from-orange-500 to-orange-400"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar5
                        ? "flex justify-center w-100 bg-gradient-to-r from-orange-400 to-orange-300"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar6
                        ? "flex justify-center w-100 bg-gradient-to-r from-orange-300 to-orange-200"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar7
                        ? "flex justify-center w-100 bg-gradient-to-r from-orange-200 to-green-300"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar8
                        ? "flex justify-center w-100 bg-gradient-to-r from-green-300 to-green-500"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar9
                        ? "flex justify-center w-100 bg-gradient-to-r from-green-500 to-green-800"
                        : "flex justify-center w-100"
                    }
                  ></div>
                  <div
                    className={
                      progBar10
                        ? "flex justify-center w-100 bg-gradient-to-r from-green-800 to-green-900"
                        : "flex justify-center w-100"
                    }
                  ></div>
                </div>
              </div>

              {/*  Error Message */}
              <div
                className={
                  pswError
                    ? "ml-2 mt-[2px] block text-sm font-semibold text-red-600"
                    : "hidden"
                }
              >
                <Transition
                  show={pswError}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {pswErrorMsg}
                </Transition>
              </div>
            </div>
          </div>

          {/* Confirm Password */}
          <div className="flex justify-center">
            <div className="flex-row justify-center mt-[9px] h-[65px] text-md">
              <div
                onClick={() => psw2FocusOn()}
                className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
              >
                <input
                  value={userPsw2}
                  onChange={(e) => setUserPsw2(e.target.value)}
                  onBlur={() => psw2FocusOff()}
                  type="password"
                  ref={psw2Ref}
                  autoComplete="off"
                  className="w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                />
                <h4
                  className={
                    psw2Move
                      ? "w-[120px] BNP-LableMove -translate-y-[50px]"
                      : "w-[300px] BNP-LableStart mt-[3px]"
                  }
                >
                  <div className="flex">
                    Confrim Password
                    <div
                      className={
                        psw2Move ? "hidden" : "font-semibold pl-1 text-red-700"
                      }
                    >
                      *
                    </div>
                  </div>
                </h4>
              </div>
              {/*  Error Message */}
              <div
                className={
                  psw2Error
                    ? "ml-2 mt-[2px] block text-sm font-semibold text-red-600"
                    : "hidden"
                }
              >
                <Transition
                  show={psw2Error}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {psw2ErrorMsg}
                </Transition>
              </div>
            </div>
          </div>

          {/* Update Button */}
          <div className="flex justify-center my-4 mr-1">
            {btnDisable === true ? (
              <div>
                <button
                  className={
                    "w-[250px] px-8 text-lg rounded-md p-3 BNP-SubmitButtonsBlue cursor-not-allowed hover:cursor-not-allowed"
                  }
                  disabled
                >
                  Change
                </button>
              </div>
            ) : (
              <div>
                <button
                  onClick={() => ChangePasswordClick()}
                  className={
                    btnWait
                      ? "w-[250px] px-8 text-lg rounded-md p-3 BNP-SubmitButtonsBlueWait"
                      : "w-[250px] px-8 text-lg rounded-md p-3 BNP-SubmitButtonsBlue"
                  }
                >
                  Change
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
