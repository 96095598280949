import React, { useContext, useEffect, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import md5 from "md5";
import axios from "axios";

// lookup data
import BNPInfoData from "../data/Lookups/BNPinfo.json";

// Icons
import * as bsIcons from "react-icons/bs";
import * as aiIcons from "react-icons/ai";

// formating
import formatPhoneNumber from "../utilities/formatPhoneNumber";
import formatProperCase from "../utilities/formatProperCase";
import RemoveFunnies from "../utilities/fromatRemoveFunnies";

// Context
import UrlContext from "../context/URLContext";
import modalContext from "../context/ModalContext";

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function ContactsPage() {
  // console.log("Render ContactsPage");

  // Context
  const { callbackInsert } = useContext(UrlContext);

  const { setMsgOpen, msgClean, setMsgHeader, setMsgTxt, setMsgType } =
    useContext(modalContext);

  // State
  const [NameError, setNameError] = useState(false);
  const [NameErrorMsg, setNameErrorMsg] = useState("");
  const [CellError, setCellError] = useState(false);
  const [CellErrorMsg, setCellErrorMsg] = useState("");
  const [EmailError, setEmailError] = useState(false);
  const [EmailErrorMsg, setEmailErrorMsg] = useState("");

  const [Name, setName] = useState("");
  const [NameMove, setNameMove] = useState(false);
  const NameRef = useRef();
  const [CellNo, setCellNo] = useState("");
  const [CellMove, setCellMove] = useState(false);
  const CellRef = useRef();
  const [eMail, seteMail] = useState("");
  const [eMailMove, seteMailMove] = useState(false);
  const eMailRef = useRef();
  const [Msg, setMsg] = useState("");
  const [MsgMove, setMsgMove] = useState(false);
  const MsgRef = useRef();
  const [MsgLen, setMsgLen] = useState("500");
  const [MsgMin, setMsgMin] = useState(false);
  const [Submit, setSubmit] = useState(true);
  const [btnWait, setBtnWait] = useState(false);
  const btnRef = useRef();
  const [msgborderRed, setMsgborderRed] = useState(false);

  useEffect(() => {
    if (Name.length > 0) {
      setNameMove(true);
    } else {
      setNameMove(false);
    }
    if (CellNo.length > 0) {
      setCellMove(true);
    } else {
      setCellMove(false);
    }
    if (eMail.length > 0) {
      seteMailMove(true);
    } else {
      seteMailMove(false);
    }
    if (Msg.length > 0) {
      setMsgMove(true);
    } else {
      setMsgMove(false);
    }

    // validate info supplied
    let i = 0;
    if (NameError === true) {
      i = 1;
    } else if (CellError === true) {
      i = 1;
    } else if (EmailError === true) {
      i = 1;
    } else if (Msg.length < 15) {
      i = 1;
    }

    if (i > 0) {
      setSubmit(true);
    } else {
      setSubmit(false);
      setBtnWait(false);
    }

    if (Msg.length > 0 && Msg.length < 15) {
      setMsgborderRed(true);
    } else {
      setMsgborderRed(false);
    }
  }, [CellNo, Msg, Name, eMail, CellError, NameError, EmailError]);

  // UserName
  const handleName = (e) => {
    let i = formatProperCase(e.target.value);
    setName(i);
    if (i.length < 3 && i.length > 0) {
      setNameErrorMsg("Name is too short.");
      setNameError(true);
    } else {
      setNameErrorMsg("Name length OK!");
      setNameError(false);
    }
  };

  // Cellphone validate
  const handleCell = (e) => {
    setCellNo(formatPhoneNumber(e.target.value));
    let i = RemoveFunnies(e.target.value);
    if (i.length < 10 && i.length > 0) {
      setCellErrorMsg("Cellphone number length is too short.");
      setCellError(true);
    } else {
      setCellError(false);
    }
  };

  // Email validate
  const handleUserEmail = (e) => {
    seteMail(e.target.value);
    setEmailError(true);

    let vEmail = e.target.value;
    var emailcase =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (vEmail.length > 0) {
      if (vEmail.length < 4) {
        setEmailErrorMsg("Email length is too short.");
      } else if (vEmail.substring(vEmail.length - 1) === ".") {
        setEmailErrorMsg("An email address can not end with a PERIOD!");
      } else if (!vEmail.match("@")) {
        setEmailErrorMsg("An email address must have a @ in it!");
      } else if (!vEmail.match(emailcase)) {
        setEmailErrorMsg("Invalid email address!");
      } else {
        setEmailError(false);
      }
    } else {
      setEmailError(false);
    }
  };

  const NameFocusOn = () => {
    setNameMove(true);
    NameRef.current.focus();
  };

  const NameFocusOff = () => {
    if (Name.length !== 0) {
      setNameMove(true);
    } else {
      setNameMove(false);
    }
  };

  const CellFocusOn = () => {
    setCellMove(true);
    CellRef.current.focus();
  };

  const CellFocusOff = () => {
    if (CellNo.length !== 0) {
      setCellMove(true);
    } else {
      setCellMove(false);
    }
  };

  const eMailFocusOn = () => {
    seteMailMove(true);
    eMailRef.current.focus();
  };

  const eMailFocusOff = () => {
    if (eMail.length !== 0) {
      seteMailMove(true);
    } else {
      seteMailMove(false);
    }
  };

  const MsgFocusOn = () => {
    setMsgMove(true);
    MsgRef.current.focus();
  };

  const MsgFocusOff = () => {
    if (Msg.length !== 0) {
      setMsgMove(true);
    } else {
      setMsgMove(false);
    }
  };

  const MsgText = (e) => {
    setMsg(e.target.value);
    setMsgLen(500 - Msg.length);

    // Get Min length
    if (e.target.value.length > 14) {
      setMsgMin(true);
    } else {
      setMsgMin(false);
    }
  };

  const btnSubmit = async () => {
    // Push call back data to mySQL db.
    setBtnWait(true);
    let NewCell = RemoveFunnies(CellNo);

    //  Hash build
    let pw = md5(NewCell + process.env.REACT_APP_UCNPASSPHRASE);

    let error = 0;
    let url = callbackInsert;
    const result = await axios
      .post(url, {
        Name,
        NewCell,
        eMail,
        Msg,
        pw,
      })
      .then((res) => res)
      .catch((err) => {
        error = 1;
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });
    // console.log(result);

    if (error === 0) {
      if (result.status === 200) {
        if (result.data.length > 0) {
          const data = result.data[0];
          if (data[0].Msg === "Successful - Callback created") {
            let n = data[0].vCB_ID;
            var zerofilled = ("00000" + n).slice(-5);

            msgClean();
            setMsgHeader("Call back reference No: BNP-" + zerofilled);
            setMsgTxt(
              " Your callback request has been logged.  One of our friendly staff will call you shortly!"
            );
            setMsgType("Info");
            setMsgOpen(true);

            // Clean Old msg out
            cleanMsg();
          }
        }
      }
    }
    // console.log("working");
  };

  const cleanMsg = () => {
    setName("");
    setNameMove(false);
    setCellNo("");
    setCellMove(false);
    seteMail("");
    seteMailMove(false);
    setMsg("");
    setMsgMove(false);
  };

  // console.log(Submit);


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <main>
        {/* Container */}
        <div className="justify-center font-Montserrat">
          {/* Contact Section */}
          <div className="flex justify-center items-center">
            <h1 className="pt-4 md:pt-10 mb-2 text-5xl font-bold BNP-Trans300">
              Contact Us
            </h1>
          </div>
          {/* Business Hours */}
          <div className="flex justify-center mt-2 mb-4">
            <div className="flex-row justify-center transition-all duration-500">
              <p className="flex justify-center text-xl">
                <strong>Business Hours</strong>
              </p>
              <div className="flex justify-center space-x-5 text-lg">
                <p className="flex justify-center">Mon - Fri</p>
                <p>8:30 - 17:00</p>
              </div>
              <p className="flex justify-center text-lg">
                Sat, Sun and Public Holidays - Closed
              </p>
            </div>
          </div>

          {/* split content */}
          <div className="flex-row lg:flex justify-center transition-all duration-500">
            {/* Address */}
            <div className="flex justify-center lg:justify-end lg:mr-5 lg:pr-5 lg:pb-3 m-0 p-0 lg:border-r-2 border-BNPblue border-opacity-40 lg:w-[33%]">
              <div className="flex justify-center items-center ">
                <div className="flex-row w-[200px]">
                  <div className="flex justify-center items-center">
                    <strong className="pt-1 md:pt-3 text-2xl ">
                      Address Info:
                    </strong>
                  </div>
                  <dir className="flex justify-center items-center mt-2">
                    <dir className="flex-row justify-center text-center text-lg">
                      <p>{BNPInfoData[0].street_address}</p>
                      <p>{BNPInfoData[0].suburb}</p>
                      <p>{BNPInfoData[0].City}</p>
                      <p>{BNPInfoData[0].province}</p>
                      <p>{BNPInfoData[0].post_code}</p>
                    </dir>
                  </dir>
                </div>
              </div>
            </div>

            <div className="flex-row justify-center pt-4 lg:mr-5 lg:pr-5 lg:pb-3 m-0 pb-0">
              <strong className="flex justify-center text-2xl">
                Contact number
              </strong>
              <div className="flex justify-center mt-2">
                <bsIcons.BsFillTelephoneFill className="flex items-center h-9 w-9 opacity-90 text-green-600" />
                <div className="flex-row items-center text-xl px-3 w-[190px]">
                  <p className="flex justify-center BNP-Links p-0 ">
                    {formatPhoneNumber(BNPInfoData[0].admin_contact)}
                  </p>
                  <p className="flex justify-center BNP-Links p-0">
                    {formatPhoneNumber(BNPInfoData[0].sales_contact)}
                  </p>
                </div>
                <bsIcons.BsWhatsapp className="h-10 w-10 opacity-90 text-green-600" />
              </div>
            </div>

            <div className="flex justify-center lg:justify-start pt-5 lg:border-l-2 border-BNPblue border-opacity-40 lg:w-[33%]">
              <div className="flex-row w-[300px]">
                <div className="flex justify-center">
                  <aiIcons.AiOutlineMail className="h-9 w-9 scale-x-125 opacity-90" />
                  <strong className="px-4 pt-1 text-2xl">Email</strong>
                </div>
                <div className="flex justify-center text-xl hover:BNP-Underline hover:text-blue-700 hover:font-semibold">
                  <a className="BNP-Links" href="mailto:sales@bespoknp.co.za">
                    sales@bespoknp.co.za
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Contact Us Section */}
          <div className="flex justify-center">
            <div className="m-6">
              <dir className="flex justify-center ">
                <strong className="text-2xl ">Or</strong>
              </dir>
              <dir className="flex justify-center ">
                <strong className="pt-4 text-2xl ">
                  Message us and we will call you back!
                </strong>
              </dir>

              {/* Name */}
              <div className="h-[80px]">
                <div
                  onClick={() => NameFocusOn()}
                  className="group mt-10 h-[50px] mb-[40px] BNP-LableDIV cursor-text"
                >
                  <input
                    value={Name}
                    onChange={handleName}
                    onBlur={() => NameFocusOff()}
                    type="text"
                    ref={NameRef}
                    autoComplete="off"
                    className={
                      NameError
                        ? "w-[500px] h-12 BNP-Input BNP-ErrorBoarder"
                        : "w-[500px] h-12 BNP-Input cursor-text"
                    }
                  />
                  <h4
                    className={
                      NameMove
                        ? "w-[50px] BNP-LableMove -translate-y-[59px]"
                        : "w-28 BNP-LableStart"
                    }
                  >
                    <div className="flex">
                      Name:
                      <div
                        className={
                          NameMove
                            ? "hidden"
                            : "font-semibold pl-1 text-red-700"
                        }
                      >
                        *
                      </div>
                    </div>
                  </h4>
                </div>

                {/*  Error Message */}
                <div
                  className={
                    NameError
                      ? "-translate-y-[40px] ml-2 block text-sm font-semibold text-red-600"
                      : "hidden"
                  }
                >
                  <Transition
                    show={NameError}
                    enter="transition ease-in-out duration-500 transform"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in-out duration-500 transform"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    {NameErrorMsg}
                  </Transition>
                </div>
              </div>

              {/* Cellphone */}
              <div className="h-[80px]">
                <div
                  onClick={() => CellFocusOn()}
                  className="group h-[50px] mb-[40px]  mt-2 BNP-LableDIV cursor-text"
                >
                  <input
                    value={CellNo}
                    onChange={handleCell}
                    onBlur={() => CellFocusOff()}
                    type="text"
                    ref={CellRef}
                    autoComplete="on"
                    className={
                      CellError
                        ? "w-[500px] h-12 BNP-Input BNP-ErrorBoarder"
                        : "w-[500px] h-12 BNP-Input cursor-text"
                    }
                  />
                  <h4
                    className={
                      CellMove
                        ? "w-[90px] BNP-LableMove -translate-y-[59px]"
                        : "w-[144px] BNP-LableStart"
                    }
                  >
                    <div className="flex">
                      Cell number:
                      <div
                        className={
                          CellMove
                            ? "hidden"
                            : "font-semibold pl-1 text-red-700"
                        }
                      >
                        *
                      </div>
                    </div>
                  </h4>
                </div>
                {/*  Error Message */}
                <div
                  className={
                    CellError
                      ? "-translate-y-[40px] ml-2 block text-sm font-semibold text-red-600"
                      : "hidden"
                  }
                >
                  <Transition
                    show={CellError}
                    enter="transition ease-in-out duration-500 transform"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in-out duration-500 transform"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    {CellErrorMsg}
                  </Transition>
                </div>
              </div>

              {/* Email */}
              <div className="h-[80px]">
                <div
                  onClick={() => eMailFocusOn()}
                  className="group mt-2 h-[50px] mb-[40px] BNP-LableDIV cursor-text"
                >
                  <input
                    value={eMail}
                    onChange={handleUserEmail}
                    onBlur={() => eMailFocusOff()}
                    type="email"
                    ref={eMailRef}
                    autoComplete="off"
                    className={
                      EmailError
                        ? "w-[500px] h-12 BNP-Input BNP-ErrorBoarder"
                        : "w-[500px] h-12 BNP-Input cursor-text"
                    }
                  />
                  <h4
                    className={
                      eMailMove
                        ? "w-[48px] BNP-LableMove -translate-y-[59px]"
                        : "w-28 BNP-LableStart"
                    }
                  >
                    Email:
                  </h4>
                </div>
                {/*  Error Message */}
                <div
                  className={
                    EmailError
                      ? "-translate-y-[40px] ml-2 block text-sm font-semibold text-red-600"
                      : "hidden"
                  }
                >
                  <Transition
                    show={EmailError}
                    enter="transition ease-in-out duration-500 transform"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in-out duration-500 transform"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    {EmailErrorMsg}
                  </Transition>
                </div>
              </div>

              {/* Message */}
              <div>
                <div
                  onClick={() => MsgFocusOn()}
                  className="group mt-2 h-[200px] mb-4 BNP-LableDIV cursor-text"
                >
                  <textarea
                    value={Msg}
                    onChange={MsgText}
                    onBlur={() => MsgFocusOff()}
                    type="text"
                    ref={MsgRef}
                    autoComplete="off"
                    maxLength={500}
                    className={
                      msgborderRed
                        ? "w-[500px] resize-none h-48 BNP-Input focus:ring-red-600 focus:border-red-600"
                        : "w-[500px] resize-none h-48 BNP-Input flex-wrap"
                    }
                  />
                  <div className="flex justify-end mr-2 -translate-y-[221px] text-sm">
                    <p
                      className={
                        MsgMin
                          ? "hidden"
                          : "text-red-600 transition-opacity duration-500"
                      }
                    >
                      {15 - Msg.length} min
                    </p>
                    <p className={MsgMin ? "hidden" : "mx-3"}>-</p>
                    <p>{MsgLen} max</p>
                  </div>
                  <h4
                    className={
                      MsgMove
                        ? "-translate-y-[229px] w-[69px] BNP-LableMove"
                        : "-translate-y-52 w-28 BNP-LableStart"
                    }
                  >
                    <div className="flex">
                      Message:
                      <div
                        className={
                          MsgMove ? "hidden" : "font-semibold pl-1 text-red-700"
                        }
                      >
                        *
                      </div>
                    </div>
                  </h4>
                </div>

                <div className="flex justify-end">
                  <button
                    ref={btnRef}
                    onClick={btnSubmit}
                    disabled={Submit}
                    className={
                      btnWait
                        ? "w-[150px] BNP-SubmitButtonsWait transition-opacity duration-500"
                        : Submit
                        ? "w-[150px] BNP-SubmitButtons hover:cursor-not-allowed opacity-60 transition-opacity duration-500"
                        : "w-[150px] BNP-SubmitButtons transition-opacity duration-500"
                    }
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
