import { createContext, useEffect, useState } from "react";

// Context
const SalesCartContext = createContext();

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function CartProvider({ children }) {
  // State
  const [cartClick, setCartClick] = useState(false);
  const [cartVol, setCartVol] = useState(0);
  const [cartItems, setCartItems] = useState([
    // {id: "Place", quant: 0,province: "",size: "",reg: "",plate: "",price: "0",name: "", surname: "",idNo: "1234567890123",IDType: "",IDfile: [],Regfile: [],Selfie: []}
  ]);
  const [plateVol, setPlateVol] = useState(0);
  const [bPlateVol, setBplateVol] = useState(0);
  const [rivetVol, setRivetVol] = useState(0);

  const [payMethod, setPayMethod] = useState("payfast");
  const [deliveryCheck, setDeliveryCheck] = useState(false);
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [cartCost, setCartCost] = useState(0);
  const [cartDiscount, setCartDiscount] = useState(0);
  const [discountAmt, setDiscountAmt] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);
  const [purchaseSuccess, setPurchaseSuccess] = useState(0);
  const [province, setProvince] = useState("GP");

  const [IDfile, setIDfile] = useState([]);
  const [Regfile, setRegfile] = useState([]);
  const [Selfiefile, setSelfiefile] = useState([]);

  const [invNo, setInvNo] = useState("Inv000001");
  const [cashPay, setCashPay] = useState(true); //  False removed the Cash payment option

  const [saleRefreshPay, setSaleRefreshPay] = useState(0);

  const [dealerPayMethodDisable, setDealerPayMethodDisable] = useState(false);

  useEffect(() => {
    setCartVol(
      cartItems.reduce((total, cartItem) => {
        const item = cartItems.find((item) => item.id === cartItem.id);
        return total + (item?.quant || 0);
      }, 0)
    );
  }, [cartItems]);

  // Read from local storage
  function readLocalSales() {
    var items = JSON.parse(localStorage.getItem("BNPcart"));
    if (items) {
      setCartItems(items);
      setCartVol(items.reduce((total, v) => (total = total + v.quant), 0));
    }
  }

  // Write to local storage
  function saveLocalSales() {
    localStorage.setItem("BNPcart", JSON.stringify(cartItems));
  }

  
  // Write Payfast to local storage
  function savePF2LocalSales(i) {
    localStorage.setItem("BNPpayfast", JSON.stringify(i));
  }

  const updateCartCost = () => {
    setCartCost(
      cartItems.reduce((total, cartItem) => {
        const item = cartItems.find((item) => item.id === cartItem.id);
        return total + (item?.price || 0) * cartItem.quant;
      }, 0)
    );
  };

  const UpdateDiscount = () => {
    setDiscountAmt((cartCost / 100) * cartDiscount * -1);
  };

  const UpdateCartTotal = () => {
    setTotalAmt(cartCost + discountAmt + deliveryCost);
  };

  const emptyCart = () => {
    setCartVol(0);
    setPlateVol(0);
    setBplateVol(0);
    setRivetVol(0);
    setCartItems([]);
    setDeliveryCost(0);
    setCartDiscount(0);
    setDiscountAmt(0);
    setDeliveryCheck(false);
    setCartCost(0);
    setTotalAmt(0);
    setPayMethod("payfast");
    setIDfile([]);
    setRegfile([]);
    setSelfiefile([]);
    localStorage.removeItem("BNPcart");
    // localStorage.clear();
  };

  // function getCartQuantity (id) {
  //   return cartItems.find((item) => item.id === id)?.quant || 0
  // }

  const CartVoladd = (value) => {
    if (value === "plate") {
      setPlateVol(plateVol + 1);
      setCartVol(cartVol + 1);
    } else if (value === "bplate") {
      setBplateVol(bPlateVol + 1);
      setCartVol(cartVol + 1);
    } else if (value === "rivet") {
      setRivetVol(rivetVol + 1);
      setCartVol(cartVol + 1);
    }
    UpdateCartTotal();
  };

  const deleteByID = (value) => {
    let PlateQuant = 0;
    let prodType = "";

    const NewCart = cartItems.filter((data) => data.id === value);
    PlateQuant = NewCart[0].quant;
    prodType = NewCart[0].prodType;

    setCartItems((cartItems) => {
      // Remove Item
      if (cartItems.find((item) => item.id === value)?.quant === 1) {
        return cartItems.filter((item) => item.id !== value);
      } else {
        return cartItems.map((item) => {
          if (item.id === value) {
            // If Plate, flag rivets to be removed
            if (item.prodType === "plate") {
              PlateQuant = item.quant;
              prodType = item.prodType;
            }
            // Remove orig item
            return { ...item, quant: item.quant - 1 };
          } else {
            return item;
          }
        });
      }
    });

    // Auto rivet remove if a Plate is remove
    if (prodType === "plate" && PlateQuant >= 1) {
      // console.log("start-");
      setCartItems((cartItems) => {
        // Remove Rivet from updated cart
        if (cartItems.find((item2) => item2.id === "Rivet")?.quant === 1) {
          // console.log("1-");
          return cartItems.filter((item2) => item2.id !== "Rivet");
        } else {
          // console.log("2-");
          return cartItems.map((item2) => {
            if (item2.id === "Rivet") {
              // console.log("3-");
              // Remove orig item
              CartVolDel("rivet");
              return { ...item2, quant: item2.quant - 1 };
            } else {
              return item2;
            }
          });
        }
      });
    }
    // console.log("end");
  };

  const CartVolDel = (value) => {
    // Update Cart Vol
    if (cartVol > 0) {
      setCartVol(cartVol - 1);
    }

    if (cartVol - 1 === 0) {
      localStorage.removeItem("BNPcart");
    } else {
      localStorage.setItem("BNPcart", JSON.stringify(cartItems));
    }

    if (value === "plate" && plateVol > 0) {
      setPlateVol(plateVol - 1);
    } else if (value === "bplate" && bPlateVol > 0) {
      setBplateVol(bPlateVol - 1);
    } else if (value === "rivet" && rivetVol > 0) {
      setRivetVol(rivetVol - 1);
    }

    UpdateCartTotal();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <SalesCartContext.Provider
      value={{
        readLocalSales,
        saveLocalSales,
        savePF2LocalSales,

        cartVol,
        cartClick,
        setCartClick,
        cartItems,
        setCartItems,
        deleteByID,
        CartVoladd,
        plateVol,
        bPlateVol,
        rivetVol,
        CartVolDel,
        emptyCart,

        payMethod,
        setPayMethod,
        deliveryCheck,
        setDeliveryCheck,
        deliveryCost,
        setDeliveryCost,
        updateCartCost,
        cartCost,
        cartDiscount,
        setCartDiscount,
        discountAmt,
        UpdateDiscount,
        totalAmt,
        UpdateCartTotal,
        purchaseSuccess,
        setPurchaseSuccess,
        province,
        setProvince,

        invNo,
        setInvNo,
        cashPay,
        setCashPay,

        IDfile,
        setIDfile,
        Regfile,
        setRegfile,
        Selfiefile,
        setSelfiefile,

        saleRefreshPay,
        setSaleRefreshPay,

        dealerPayMethodDisable,
        setDealerPayMethodDisable,
      }}
    >
      {children}
    </SalesCartContext.Provider>
  );
}

export default SalesCartContext;
