import React from "react";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import * as GoIcons from "react-icons/go";
import * as BsIcons from "react-icons/bs";
import * as FaI6cons from 'react-icons/fa6';
import * as tbIcons from "react-icons/tb";


export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <BiIcons.BiHome />,
  },
  {
    title: "Shop",
    path: "/sales",
    icon: <FaI6cons.FaShop />,
  },
  {
    title: "Tracking",
    path: "/tracking",
    icon: <tbIcons.TbTruckDelivery />,
  },
  {
    title: "Legislation",
    path: "/Legislation",
    icon: <GoIcons.GoLaw />,
    subnav: false,    // Change to true to have sub menues
    iconClosed: <BsIcons.BsChevronDown/>,
    subnavItems: [
      {
        title: "Current",
        path: "/CurrentLegislation",
          },
      {
        title: "Future",
        path: "/FutureLegislation",
          },

    ]
  },
  {
    title: "About",
    path: "/about",
    icon: <BsIcons.BsChatSquareText />,
  },
  {
    title: "Contacts",
    path: "/contacts",
    icon: <FaIcons.FaPhoneAlt />,
  },
  {
    title: "FAQ",
    path: "/faq",
    icon: <FaIcons.FaQuestion />,
  },
];
