import React, { useContext } from "react";
import CircularProgress from '@mui/material/CircularProgress';

// Context
import ModalContext from "../context/ModalContext";

// React icons
import * as aiIcons from "react-icons/ai";

export default function AboutPage() {
  // console.log("Render Sales Page Modal Preview Img");

  // Context
  const { setTrackingModalImgPreview, prevType, prevDoc, setPrevDoc, setPrevType } = useContext(ModalContext);

  const closeOutSide = (e) => {
    if (e.target.id === "ImgPrevModal") closeClick();
  };

  const closeClick = () => {
    setTrackingModalImgPreview(false);
    setPrevDoc("");
    setPrevType("Nothing");
  };

  // console.log(prevDoc)
  return (
    <>
      <div onClick={closeOutSide}>
        <div className="font-Montserrat">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-scroll max-h-[90%]">

            <div id="ImgPrevModal" className="flex-row items-center justify-center w-[600px] md:w-[500px] bg-BNPgoldLightLight rounded-md BNP-Modal-shape">

              {/* Header */}
              <div className="flex-row  w-100 h-[100px] text-BNPgoldLight bg-BNPblue shadow-md shadow-BNPblue">
                <div className="flex justify-start items-start pt-5 pl-5">
                  <aiIcons.AiOutlineClose
                    className="rounded-full focus:outline-none transition-all duration-500 scale-[2.2] hover:font-semibold hover:scale-[2.9] hover:rotate-90
                  hover:border-2 hover:rounded-full hover:border-BNPgoldLight cursor-pointer hover:transition-all hover:duration-700"
                    onClick={closeClick}
                  />
                </div>

                <div className="flex justify-center text-center mt-3 mb-5 text-xl font-semibold">
                  <h1>Verify that it is the correct {prevType} document.</h1>
                </div>
              </div>
              <div className="flex items-center justify-center m-5">
              <CircularProgress />
                <img src={prevDoc} alt="" className="cursor-zoom-in" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
