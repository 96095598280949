import md5 from "md5";
import axios from "axios";

import userTokenGetTime from "./userTokenSetTime";

export default async function UserToken(url, t, k) {
  let validTime = userTokenGetTime().timestamp;
  //   Get New Token
  let pw = md5(k + process.env.REACT_APP_UCNPASSPHRASE);
  const data = await axios
    .post(url, {
      rtoken: t,
      k: k,
      z: pw,
    })
    .then((res) => res.data)
    .catch((err) => err);

  return {
    timestamp: validTime,
    data: data,
  };
}
