import React from "react";

// Pages
import AppLanding from "./pages/AppLanding";

// CSS
import "./styles.css";

// Context
import { AdminProvider } from "./context/AdminContext";
import { UserProvider } from "./context/UserContext";
import { ModalProvider } from "./context/ModalContext";
import { CartProvider } from "./context/SalesCartContext";
import { UrlProvider } from "./context/URLContext";


////////////////////////////////////////////////////////////////////
function App() {
  return (
    <>
      <div>
      <AdminProvider>
        <UserProvider>
          <ModalProvider>
            <CartProvider>
            <UrlProvider>
              {/* Landing page */}
              <AppLanding />
              {/* Landing page end */}
              </UrlProvider>
            </CartProvider>
          </ModalProvider>
        </UserProvider>
        </AdminProvider>
      </div>
    </>
  );
}

export default App;
