import React, { useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

// React Icons
import * as aiIcons from "react-icons/ai";
import * as faIcons from "react-icons/fa";
import * as biIcons from "react-icons/bi";
import * as bsIcons from "react-icons/bs";

import * as mdIcons from "react-icons/md";

// Context
import modalContext from "../context/ModalContext";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function ErrorMessages() {
  // console.log("Render ErrorMessages");

  // Navigate
  const nav = useNavigate();

  // Context
  const {
    msgOpen,
    setMsgOpen,
    msgHeader,
    msgTxt,
    msgType,
    linkMsg,
    navLink,
    navLinkTxt,
    setloginModal,
    setSalePaymentCheckLoginOpen,
    setDeliveryAddressOpen,
    setTrackingModalOpen,
  } = useContext(modalContext);

  // State

  const [typeIconSuccess, setTypeIconSuccess] = useState(false);
  const [typeIconInfo, setTypeIconInfo] = useState(false);
  const [typeIconError, setTypeIconError] = useState(false);
  const [typeIconWarning, setTypeIconWarning] = useState(false);
  const [typeIconHelp, setTypeIconHelp] = useState(false);
  const [linkMessage, setLinkMessage] = useState(false);
  const [headerHide, setHeaderHide] = useState(false);

  useEffect(() => {
    if (msgType === "Success") {
      setTypeIconSuccess(true);
      setTypeIconInfo(false);
      setTypeIconWarning(false);
      setTypeIconError(false);
      setTypeIconHelp(false);
    } else if (msgType === "Info") {
      setTypeIconInfo(true);
      setTypeIconSuccess(false);
      setTypeIconWarning(false);
      setTypeIconError(false);
      setTypeIconHelp(false);
    } else if (msgType === "Warning") {
      setTypeIconWarning(true);
      setTypeIconSuccess(false);
      setTypeIconInfo(false);
      setTypeIconError(false);
      setTypeIconHelp(false);
    } else if (msgType === "Error") {
      setTypeIconError(true);
      setTypeIconSuccess(false);
      setTypeIconInfo(false);
      setTypeIconWarning(false);
      setTypeIconHelp(false);
    } else if (msgType === "Help") {
      setTypeIconHelp(true);
      setTypeIconSuccess(false);
      setTypeIconInfo(false);
      setTypeIconWarning(false);
      setTypeIconError(false);
    }

    if (linkMsg !== "" || navLinkTxt !== "") {
      setLinkMessage(true);
    }

    // Check if Heard is populated else hide
    if (msgHeader.length > 0) {
      setHeaderHide(false);
    } else {
      setHeaderHide(true);
    }
  }, [linkMsg, navLinkTxt, msgType, msgHeader]);

  const handleLinkClick = () => {
    // console.log(navLink);
    if (navLink === "/Contacts") {
      setTrackingModalOpen(false);
      nav(navLink);
      closeModal();
    } else if (navLink.substring(0, 1) === "/") {
      // console.log("into");
      nav(navLink);
    } else if (navLink === "loginModal") {
      // console.log("loginModal")
      closeModal();
      setloginModal(true);
      setSalePaymentCheckLoginOpen(false);
    } else if (navLink === "AddressModal") {
      setSalePaymentCheckLoginOpen(false);
      setDeliveryAddressOpen(true);
    } else if (navLink === "/PasswordRecover") {
      closeModal();
    } else closeModal();
  };

  const closeModal = () => {
    setMsgOpen(false);
  };


  useEffect(() => {
    const close = (e) => {
      // console.log(e.keyCode)
      if(e.keyCode === 27){
        setMsgOpen(false);
      }
    }
    window.addEventListener('keydown', close)
  return () => window.removeEventListener('keydown', close)
},[setMsgOpen])

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Transition
        show={msgOpen}
        enter="transition ease-in-out duration-700 transform"
        enterFrom="-translate-y-[50%] opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition ease-in-out duration-700 transform"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="-translate-y-[50%] opacity-0"
      >
        <div
            className="flex justify-center mt-[5%] font-Montserrat "
        >
          <div className="flex-row p-4 max-w-[50%] bg-BNPgoldLightLight border-2 border-BNPblue rounded-md shadow-md shadow-BNPblue transition-all duration-1000">
            <div>
              <aiIcons.AiOutlineClose
                onClick={() => closeModal()}
                className="m-2 mb-3 rounded-full focus:outline-none transition-all duration-500 scale-[2] hover:font-semibold hover:scale-[2.5] hover:rotate-90 hover:border-2 hover:rounded-full hover:border-BNPblue cursor-pointer hover:transition-all hover:duration-700"
              />
            </div>
            {/* Message Body */}
            <div className="flex-row justify-center items-center">
              <div className="flex justify-center -mt-3 mb-3">
                <faIcons.FaRegCheckCircle
                  className={
                    typeIconSuccess
                      ? "scale-150 text-3xl text-green-700"
                      : "hidden"
                  }
                />
                <bsIcons.BsInfoCircle
                  className={
                    typeIconInfo
                      ? "scale-150 text-3xl text-yellow-500 font-bold"
                      : "hidden"
                  }
                />
                <biIcons.BiErrorAlt
                  className={
                    typeIconError ? "scale-150 text-5xl text-red-700" : "hidden"
                  }
                />
                <bsIcons.BsFillExclamationTriangleFill
                  className={
                    typeIconWarning
                      ? "scale-150 text-3xl text-orange-600"
                      : "hidden"
                  }
                />
                <mdIcons.MdLiveHelp
                  className={
                    typeIconHelp
                      ? "scale-150 text-5xl text-yellow-400"
                      : "hidden"
                  }
                />
              </div>
              <p
                className={
                  headerHide
                    ? "hidden"
                    : "pt-5 text-center text-3xl text-BNPblue font-semibold underline"
                }
              >
                {msgHeader}
              </p>
              <p className="flex flex-wrap justify-center items-center p-5 text-center text-3xl text-BNPblue font-semibold text-wrap">
                {msgTxt}
              </p>

              <div className={linkMessage ? "flex justify-center" : "hidden"}>
                <p className="flex justify-center items-center text-xl">
                  {linkMsg}
                </p>
                <p className="text-xl BNP-Links" onClick={handleLinkClick}>
                  {navLinkTxt}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}
