import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

// Pages
import TrackingItemsProducts from "./TrackingItemsProducts";

// Context
import ModalContext from "../context/ModalContext";
import AdminContext from "../context/AdminContext";
import UrlContext from "../context/URLContext";
import UserContext from "../context/UserContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function TrackingItems(props) {
  // console.log("Render TrackingItems");

  // Context
  const { trackingDataProducts, trackingModal, trackingModalProducts } =
    useContext(UrlContext);

  const {
    trackingRefresh,
    setTrackingRefresh,
    setTrackingModalOpen,
    msgClean,
    setMsgOpen,
    setMsgTxt,
    setMsgType,
    setSalePaymentChange,
  } = useContext(ModalContext);
  const {
    salesOrderInfo,
    setSalesOrderInfo,
    setSalesProductData,
  } = useContext(AdminContext);

  const { accessToken } = useContext(UserContext);

  // State
  const [vtrackingOrderData, setvtrackingOrderData] = useState([]);
  const [vPaid, setvPaid] = useState(false);
  const [vReady, setvReady] = useState(false);
  const [vDeliverying, setvDeliverying] = useState(false);
  const [vReceived, setvReceived] = useState(false);

  const [vOrderTypeTxt, setvOrderTypeTxt] = useState("Embossed");
  const [vDeliveryType, setvDeliveryType] = useState(false);

  const [paidCase, setPaidCase] = useState("font-semibold");

  useEffect(() => {
    setvReady(props.orderReady);
    setvDeliverying(props.delivering);
    setvReceived(props.clientReceived);

    // Paid
    if (props.paid === "Yes") {
      setvPaid(true);
    } else {
      setvPaid(false);
      setPaidCase(
        "text-red-500 font-bold animate-pulse duration-100 border-4 rounded-full p-1 border-red-500 "
      );
    }

    // Order type - Plates or other Items
    if (props.orderType === "plate") {
      setvOrderTypeTxt("Plates Embossed");
    } else {
      setvOrderTypeTxt("Items Picked");
    }

    // Delivery type i.e. Delivery/Collection or Courier Collected
    if (props.deliveryType === "Delivery") {
      setvDeliveryType("Out for Delivery");
    } else if (props.deliveryType === "Courier") {
      setvDeliveryType("Courier Collected");
    } else if (props.deliveryType === "Collect") {
      setvDeliveryType("Ready for Collection");
    }
  }, [props, salesOrderInfo]);

  // Outstanding Orders vol
  useEffect(() => {
    if (trackingRefresh === true && props.loadNewAccessToken !== "") {
      try {
        // check token is still valid
        document.getElementById("validateClick")?.click();

        async function fetchData() {
          // request data from mySQL db
          let url = trackingDataProducts + props.Order_No;

          const data = await axios
            .get(
              url,
              { headers: { Authorization: props.loadNewAccessToken } } // JWS Token
            )
            .then((res) => res.data)
            .catch((err) => {
              msgClean();
              setMsgTxt(
                "Network error!  Please check your Internet connection and try again."
              );
              setMsgType("Error");
              setMsgOpen(true);
            });
          if (data[0]?.length > 0) {
            setvtrackingOrderData(data[0]);
          }
        }
        fetchData();
      } catch (error) {}
    }
    setTrackingRefresh(false);
  }, [
    props,
    setMsgOpen,
    setMsgTxt,
    setMsgType,
    trackingDataProducts,
    setTrackingRefresh,
    trackingRefresh,
    msgClean,
  ]);

  // const trackingOrderData = ProductsData.filter(
  //   (data) => data.Order_No === props.Order_No
  // );

  const handleClick = () => {
    console.log("click start");
    setSalePaymentChange(false);
    setSalesOrderInfo([]);
    setSalesProductData([]);

    // Validate JWT accessToken
    document.getElementById("validateClick")?.click();

    try {
      fetchClientData();
      fetchClientProductData();
      setTrackingModalOpen(true);
    } catch (error) {}

    console.log("click end");
  };

  // Get Client info
  const fetchClientData = async () => {
    let url = trackingModal + props.Order_No;
    const data = await axios
      .get(
        url,
        { headers: { Authorization: accessToken } } // JWS Token
      )
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });
    // console.log(data);
    setSalesOrderInfo(data[0]);
  };

  // Get Product info
  const fetchClientProductData = async () => {
    // request data from mySQL db
    let url = trackingModalProducts + props.Order_No;
    const data = await axios
      .get(
        url,
        { headers: { Authorization: accessToken } }, // JWS Token
        {
          params: {
            orderNo: props.Order_No,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => {
        console.log(err);
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });

    // console.log(data[0]);
    setSalesProductData(data[0]);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {/* page */}
      <div className="flex w-100 justify-center font-Montserrat mt-4 mb-5 xl:mb-0">
        {/* Container */}
        <div
          onClick={handleClick}
          className="flex-row w-[98%] lg:w-[85%] md:w-[90%] justify-center bg-white rounded-md border-2 border-BNPblue border-opacity-40 transition-all duration-300"
        >
          <div className="flex justify-center w-100 px-4">
            {/* Status Bar */}
            <div className="relative flex justify-between mt-3 max-w-[500px] w-100 z-0">
              {/* paid */}
              <div className="flex-row w-[50px]">
                <p
                  className={
                    vPaid
                      ? "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPgoldLight bg-BNPblue text-BNPgoldLight"
                      : "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPblue bg-BNPgoldLight"
                  }
                >
                  1
                </p>
                <p className="flex justify-center mt-3 w-100 text-center">
                  Paid
                </p>
                <div
                  className={
                    vPaid
                      ? "absolute border-[3px] rounded-lg w-[35%] -ml-3 top-[23px] -z-[1] border-BNPblue"
                      : "absolute border-[3px] rounded-lg w-[35%] -ml-3 top-[23px] -z-[1] border-BNPgoldLight"
                  }
                ></div>
              </div>

              {/* Order Type Desc */}
              <div className="flex-row w-[50px]">
                <p
                  className={
                    vReady
                      ? "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPgoldLight bg-BNPblue text-BNPgoldLight"
                      : "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPblue bg-BNPgoldLight"
                  }
                >
                  2
                </p>
                <p className="flex justify-center mt-3 w-100 text-center">
                  {vOrderTypeTxt}
                </p>
                <div
                  className={
                    vReady
                      ? "absolute border-[3px] rounded-lg w-[35%] -ml-3 top-[23px] -z-[1] border-BNPblue"
                      : "absolute border-[3px] rounded-lg w-[35%] -ml-3 top-[23px] -z-[1] border-BNPgoldLight"
                  }
                ></div>
              </div>

              {/* Order out for Delivery */}
              <div className="flex-row w-[50px]">
                <p
                  className={
                    vDeliverying
                      ? "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPgoldLight bg-BNPblue text-BNPgoldLight"
                      : "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPblue bg-BNPgoldLight"
                  }
                >
                  3
                </p>
                <p className="flex justify-center mt-3 w-100 text-center">
                  {vDeliveryType}
                </p>
                <div
                  className={
                    vDeliverying
                      ? "absolute border-[3px] rounded-lg w-[35%] -ml-3 top-[23px] -z-[1] border-BNPblue"
                      : "absolute border-[3px] rounded-lg w-[35%] -ml-3 top-[23px] -z-[1] border-BNPgoldLight"
                  }
                ></div>
              </div>

              {/* Client Received */}
              <div className="flex-row w-[50px]">
                <p
                  className={
                    vReceived
                      ? "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPgoldLight bg-BNPblue text-BNPgoldLight"
                      : "flex justify-center items-center h-[50px] border-2 rounded-full border-BNPblue bg-BNPgoldLight"
                  }
                >
                  4
                </p>
                <p className="flex justify-center mt-3 w-100 text-center">
                  Client Received
                </p>
                <div
                  className={
                    vReceived
                      ? "absolute border-[3px] rounded-lg w-[75px] -ml-3 top-[23px] -z-[1] border-BNPblue"
                      : "absolute border-[3px] rounded-lg w-[75px] -ml-3 top-[23px] -z-[1] border-BNPgoldLight"
                  }
                ></div>
              </div>
            </div>
          </div>
          {/* Body */}
          <div className="flex justify-center w-100 mt-2 mb-4">
            <div className="flex-row lg:flex justify-center w-[85%] p-4 border-2 border-BNPblue border-opacity-60 rounded-md hover:shadow-md hover:shadow-BNPblue cursor-pointer">
              <div className="flex-row s1300:flex transition-all duration-300">
                {/* Column 1 */}
                <div className="flex justify-center w-auto min-w-fit">
                  <div className="flex-row pr-6">
                    {/* Order Number */}
                    <div className="flex justify-between items-center">
                      <p className="font-semibold text-lg">Order number:</p>
                      {props.Order_No}
                    </div>
                    {/* Sales Date */}
                    <div className="flex justify-between items-center">
                      <p className="font-semibold text-lg mr-10 ">
                        Sales date:
                      </p>
                      <p> {props.SalesDate} </p>
                    </div>
                    {/* Payment Method */}
                    <div className="flex justify-between items-center">
                      <p className="font-semibold text-lg mr-10 ">
                        Payment Type:
                      </p>
                      <p> {props.paymentType} </p>
                    </div>
                    {/* Paid */}
                    <div className="flex justify-between items-center">
                      <p className="font-semibold text-lg mr-10 ">Paid:</p>
                      <p className={paidCase}>{props.paid}</p>
                    </div>
                  </div>
                </div>

                {/* Column 2 */}
                <div className="flex w-100 justify-center lg:justify-start items-center">
                  <div className="flex-row md:flex mt-2 lg:mt-0 md:ml-2 ml-0 space-x-0 md:space-x-2 space-y-2 md:space-y-0">
                    {/* Items Images */}
                    {vtrackingOrderData.map((data) => (
                      <div key={data.id}>
                        {/* Sales Item */}
                        <TrackingItemsProducts
                          prodType={data.prodType}
                          size={data.size}
                          reg={data.reg}
                          province={data.province}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
