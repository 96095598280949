import React, { useRef, useState, useContext, useEffect } from "react";
import { Transition } from "@headlessui/react";

import formatPhoneNumber from "../utilities/formatPhoneNumber";
import formatTextNumber from "../utilities/formatTextNumber";
import formatProperCase from "../utilities/formatProperCase";

// React Icons
import * as aiIcons from "react-icons/ai";

// pages
import ProvincePage from "./LookupProvince";

// Context
import modalContext from "../context/ModalContext";
import DeliveryContext from "../context/UserContext";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function DeliveryAddress() {
  // console.log("Render Delivery methods");

  // Context
  const { setDeliveryAddressOpen, setProvDdOpen, provInValid, setProvInValid } =
    useContext(modalContext);

  const {
    BusCheck,
    setBusCheck,

    DeliveryName,
    setDeliveryName,
    RecipientsCell,
    setRecipientsCell,
    bussinesName,
    setBussinesName,
    ComplexName,
    setComplexName,
    Address,
    setAddress,
    Suburb,
    setSuburb,
    City,
    setCity,
    Province,
    setProvince,
    PostalCode,
    setPostalCode,
    setDeliverAddressUpdate,
  } = useContext(DeliveryContext);

  //  State
  const [BusinessFlag, setBusinessFlag] = useState("IndRadio");

  const DeliveryNameRef = useRef();
  const [DeliveryNameMove, setDeliveryNameMove] = useState(false);

  const RecipientsCellRef = useRef();
  const [RecipientsCellMove, setRecipientsCellMove] = useState(false);

  const bussinesNameRef = useRef();
  const [bussinesNameMove, setbussinesNameMove] = useState(false);

  const ComplexNameRef = useRef();
  const [ComplexNameMove, setComplexNameMove] = useState(false);

  const AddressRef = useRef();
  const [AddressMove, setAddressMove] = useState(false);

  const SuburbRef = useRef();
  const [SuburbMove, setSuburbMove] = useState(false);

  const CityRef = useRef();
  const [CityMove, setCityMove] = useState(false);

  const PostalCodeRef = useRef();
  const [PostalCodeMove, setPostalCodeMove] = useState(false);

  const [deliverFlag, setDeliverFlag] = useState(false);
  const [cellFlag, setCellFlag] = useState(false);
  const [busNameFlag, setBusNameFlag] = useState(false);
  const [addressFlag, setAddressFlag] = useState(false);
  const [suburbFlag, setSuburbFlag] = useState(false);
  const [cityFlag, setCityFlag] = useState(false);
  const [postCodeFlag, setPostCodeFlag] = useState(false);

  const [clearText, setClearText] = useState(false);
  const [closeCase, setCloseCase] = useState(false);
  const [closeCaseCursor, setCloseCaseCursor] = useState("cursor-pointer");

  const [checkCntTotal, setCheckCntTotal] = useState(0);

  useEffect(() => {
    if (bussinesName?.length > 0) {
      setBusinessFlag("BusRadio");
      setBusCheck(true);
    }

    let CheckCnt = 0;

    if (DeliveryName !== "") {
      CheckCnt = CheckCnt + 1;
      setDeliveryNameMove(true);
      setDeliverFlag(false);
    }
    if (RecipientsCell !== "") {
      CheckCnt = CheckCnt + 1;
      setRecipientsCellMove(true);
      setCellFlag(false);
    }
    if (bussinesName !== "" && BusCheck === true) {
      CheckCnt = CheckCnt + 1;
      setbussinesNameMove(true);
      setBusNameFlag(false);
    }
    if (ComplexName !== "") {
      setComplexNameMove(true);
    }
    if (Address !== "") {
      CheckCnt = CheckCnt + 1;
      setAddressMove(true);
      setAddressFlag(false);
    }
    if (Suburb !== "") {
      CheckCnt = CheckCnt + 1;
      setSuburbMove(true);
      setSuburbFlag(false);
    }
    if (City !== "") {
      CheckCnt = CheckCnt + 1;
      setCityMove(true);
      setCityFlag(false);
    }
    if (Province !== "") {
      CheckCnt = CheckCnt + 1;
    }
    if (PostalCode !== "") {
      CheckCnt = CheckCnt + 1;
      setPostalCodeMove(true);
      setPostCodeFlag(false);
    }
    if (CheckCnt === 8 && BusCheck === true) {
      setCloseCase(true);
    } else {
      setCloseCase(false);
    }
    if (CheckCnt === 7 && BusCheck === false) {
      setCloseCase(true);
    } else {
      setCloseCase(false);
    }

    if (CheckCnt === 0) {
      setClearText(false);
      setCloseCaseCursor("cursor-pointer");
    } else if (CheckCnt < 8 && BusCheck === true) {
      setClearText(true);
      setCloseCaseCursor("cursor-not-allowed");
    } else if (CheckCnt < 7 && BusCheck === false) {
      setClearText(true);
      setCloseCaseCursor("cursor-not-allowed");
    } else {
      setClearText(false);
      setCloseCaseCursor("cursor-pointer");
    }

    setCheckCntTotal(CheckCnt);
  }, [
    BusCheck,
    DeliveryName,
    RecipientsCell,
    bussinesName,
    ComplexName,
    Address,
    Suburb,
    City,
    PostalCode,
    Province,
    setBusCheck,
  ]);

  // Close
  const closeOutSide = (e) => {
    if (e.target.id === "DelMethod_Close") {
      // closeClick();
    } else if (
      e.target.id !== "prov_lookup_Close" &&
      e.target.id !== "prov_lookup_Close_Arrow"
    ) {
      setProvDdOpen(false);
    }
  };

  const closeClick = () => {
    if (DeliveryName?.length > 0) {
      setDeliverFlag(false);
    }
    if (RecipientsCell?.length > 0) {
      setCellFlag(false);
    }
    if (bussinesName?.length > 0) {
      setBusNameFlag(false);
    }
    if (ComplexName?.length > 0) {
    }
    if (Address?.length > 0) {
      setAddressFlag(false);
    }
    if (Suburb?.length > 0) {
      setSuburbFlag(false);
    }
    if (City?.length > 0) {
      setCityFlag(false);
    }
    if (provInValid === false && Province?.length > 0) {
      setProvInValid(false);
    }
    if (PostalCode?.length > 0) {
      setPostCodeFlag(false);
    }

    if (checkCntTotal === 0) {
      // if nothing is populated
      setDeliveryAddressOpen(false);
    } else {
      // if anything is populated
      setCloseCase(false);
      if (checkCntTotal === 8 && BusCheck === true) {
        setDeliveryAddressOpen(false);
        setCloseCase(true);
      } else if (checkCntTotal === 7 && BusCheck === false) {
        setDeliveryAddressOpen(false);
        setCloseCase(true);
      } else {
        if (DeliveryName?.length === 0) setDeliverFlag(true);
        if (RecipientsCell?.length === 0) setCellFlag(true);
        if (bussinesName?.length === 0) setBusNameFlag(true);
        if (Address?.length === 0) setAddressFlag(true);
        if (Suburb?.length === 0) setSuburbFlag(true);
        if (City?.length === 0) setCityFlag(true);
        if (Province?.length === 0) setProvInValid(true);
        if (PostalCode?.length === 0) setPostCodeFlag(true);
      }
      setClearText(true);
    }

    // console.log(CheckCnt);
    // console.log("end");
    // console.log(DeliveryName.length);
    // console.log(RecipientsCell.length);
    // console.log(bussinesName.length);
    // console.log(Address.length);
    // console.log(Suburb.length);
    // console.log(Province.length);
    // console.log(PostalCode.length);
    // console.log(deliverFlag);
    // console.log(cellFlag);
    // console.log(busNameFlag);
    // console.log(addressFlag);
    // console.log(suburbFlag);
    // console.log(cityFlag);
    // console.log(postCodeFlag);
  };

  // Radio Button Changes
  const busClick = () => {
    if (BusinessFlag === "IndRadio") {
      setBusCheck(false);
    } else {
      setBusCheck(true);
    }
  };

  // DeliveryName
  const DeliveryNameFocusOn = () => {
    setDeliveryNameMove(true);
    DeliveryNameRef.current.focus();
  };

  const DeliveryNameFocusOff = () => {
    if (DeliveryName !== "") {
      setDeliveryNameMove(true);
    } else {
      setDeliveryNameMove(false);
    }
  };

  // RecipientsCell
  const RecipientsCellFocusOn = () => {
    setRecipientsCellMove(true);
    RecipientsCellRef.current.focus();
  };

  const RecipientsCellFocusOff = () => {
    if (RecipientsCell !== "") {
      setRecipientsCellMove(true);
    } else {
      setRecipientsCellMove(false);
    }
  };

  // bussinesName
  const bussinesNameFocusOn = () => {
    setbussinesNameMove(true);
    bussinesNameRef.current.focus();
  };

  const bussinesNameFocusOff = () => {
    if (bussinesName !== "") {
      setbussinesNameMove(true);
    } else {
      setbussinesNameMove(false);
    }
  };

  // ComplexName
  const ComplexNameFocusOn = () => {
    setComplexNameMove(true);
    ComplexNameRef.current.focus();
  };

  const ComplexNameFocusOff = () => {
    if (ComplexName !== "") {
      setComplexNameMove(true);
    } else {
      setComplexNameMove(false);
    }
  };

  // Address
  const AddressFocusOn = () => {
    setAddressMove(true);
    AddressRef.current.focus();
  };

  const AddressFocusOff = () => {
    if (Address !== "") {
      setAddressMove(true);
    } else {
      setAddressMove(false);
    }
  };

  // Suburb
  const SuburbFocusOn = () => {
    setSuburbMove(true);
    SuburbRef.current.focus();
  };

  const SuburbFocusOff = () => {
    if (Suburb !== "") {
      setSuburbMove(true);
    } else {
      setSuburbMove(false);
    }
  };

  // City
  const CityFocusOn = () => {
    setCityMove(true);
    CityRef.current.focus();
  };

  const CityFocusOff = () => {
    if (City !== "") {
      setCityMove(true);
    } else {
      setCityMove(false);
    }
  };

  // PostalCode
  const PostalCodeFocusOn = () => {
    setPostalCodeMove(true);
    PostalCodeRef.current.focus();
  };

  const PostalCodeFocusOff = () => {
    if (PostalCode !== "") {
      setPostalCodeMove(true);
    } else {
      setPostalCodeMove(false);
    }
  };

  const clearAll = () => {
    setClearText(false);
    setDeliveryName("");
    setDeliveryNameMove(false);
    setDeliverFlag(false);
    setRecipientsCell("");
    setRecipientsCellMove(false);
    setCellFlag(false);
    setBussinesName("");
    setbussinesNameMove(false);
    setBusNameFlag(false);
    setComplexName("");
    setComplexNameMove(false);
    setAddress("");
    setAddressMove(false);
    setAddressFlag(false);
    setSuburb("");
    setSuburbMove(false);
    setSuburbFlag(false);
    setCity("");
    setCityMove(false);
    setCityFlag(false);
    setProvince("");
    setProvInValid(false);
    setProvDdOpen(false);
    setPostalCode("");
    setPostalCodeMove(false);
    setPostCodeFlag(false);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div
        onClick={closeOutSide}
        className="flex justify-center max-h-full font-Montserrat overflow-y-scroll"
      >
        <div id="DelMethod_Close" className="flex-row pt-[5%] pb-100 px-100">
          {/* Header */}
          <div className="relative flex justify-center items-center w-[400px] h-[70px] text-BNPgoldLight bg-BNPblue shadow-md shadow-BNPblue">
            <div className="text-3xl font-semibold">
              <h1>Delivery Address</h1>
            </div>

            <div className="absolute -mt-5 left-3">
              <div className="pt-1 pl-1">
                <aiIcons.AiOutlineClose
                  className={
                    closeCase
                      ? "rounded-full focus:outline-none transition-all duration-500 scale-[2.2] hover:font-semibold hover:scale-[2.9] hover:rotate-90 hover:border-2 hover:rounded-full hover:border-BNPgoldLight cursor-pointer hover:transition-all hover:duration-700 animate-pulse hover:animate-none"
                      : `rounded-full focus:outline-none transition-all duration-500 scale-[2.2] hover:font-semibold hover:scale-[2.9] hover:rotate-90 hover:border-2 hover:rounded-full hover:border-BNPgoldLight ${closeCaseCursor} hover:transition-all hover:duration-700`
                  }
                  onClick={closeClick}
                />
              </div>
            </div>
            <Transition
              show={clearText}
              enter="transition ease-in-out duration-500 transform"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in-out duration-500 transform"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute bottom-0 right-0"
            >
              <p
                onClick={clearAll}
                className="w-auto BNP-Links text-BNPgoldLight m-0 p-0 text-end px-2"
              >
                clear all
              </p>
            </Transition>
          </div>

          {/* Body */}

          <div className="flex-row justify-between px-2 bg-white text-BNPblue shadow-md shadow-BNPblue">
            {/* Radio button Business flag */}
            <div className="flex items-center justify-between pt-4 mx-14">
              <div
                onClick={() => {
                  setBusinessFlag("IndRadio");
                  setBusCheck(false);
                  setBussinesName("");
                  setbussinesNameMove(false);
                  DeliveryNameRef.current.focus();
                }}
                className="flex hover:cursor-pointer hover:font-bold hover:text-blue-800"
              >
                <input
                  type="radio"
                  name="typebtn"
                  value={BusinessFlag}
                  onChange={() => busClick}
                  checked={BusinessFlag === "IndRadio"}
                  className="scale-150 hover:cursor-pointer "
                />
                <h2 className="p-1 text-lg pl-4 hover:font-bold hover:text-blue-800">
                  Indavidual
                </h2>
              </div>

              <div
                onClick={() => {
                  setBusinessFlag("BusRadio");
                  setBusCheck(true);
                  DeliveryNameRef.current.focus();
                }}
                className="flex hover:cursor-pointer"
              >
                <input
                  type="radio"
                  name="typebtn"
                  value={BusinessFlag}
                  onChange={() => busClick}
                  checked={BusinessFlag === "BusRadio"}
                  className="scale-150 hover:cursor-pointer"
                />
                <h2 className="p-1 text-lg pl-4 hover:font-bold hover:text-blue-800">
                  Business
                </h2>
              </div>
            </div>

            <div className="w-full items-center ">
              {/* Recipients Name */}
              <div className="p-1 mt-[9px] text-md">
                <div
                  onClick={() => DeliveryNameFocusOn()}
                  className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
                >
                  <input
                    value={DeliveryName}
                    onChange={(e) => {
                      setDeliveryName(formatProperCase(e.target.value));
                      setDeliverAddressUpdate(true);
                    }}
                    onBlur={() => DeliveryNameFocusOff()}
                    type="text"
                    ref={DeliveryNameRef}
                    autoComplete="off"
                    className={
                      deliverFlag
                        ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text border-4 border-red-600"
                        : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                    }
                  />
                  <h4
                    className={
                      DeliveryNameMove
                        ? "w-[108px] BNP-LableMove -translate-y-[50px] "
                        : "w-[170px] BNP-LableStart pt-[3px]"
                    }
                  >
                    <div className="flex">
                      Recipient Name
                      <div
                        className={
                          DeliveryNameMove
                            ? "hidden"
                            : "font-semibold pl-1 text-red-700"
                        }
                      >
                        *
                      </div>
                    </div>
                  </h4>
                </div>
              </div>

              {/* Recipients Cell */}
              <div className="p-1 mt-[9px] text-md">
                <div
                  onClick={() => RecipientsCellFocusOn()}
                  className="group w-[200px] h-[40px] BNP-LableDIV cursor-text"
                >
                  <input
                    value={RecipientsCell}
                    onChange={(e) => {
                      setRecipientsCell(formatPhoneNumber(e.target.value));
                      setDeliverAddressUpdate(true);
                    }}
                    onBlur={() => RecipientsCellFocusOff()}
                    type="text"
                    ref={RecipientsCellRef}
                    autoComplete="off"
                    className={
                      cellFlag
                        ? "w-[200px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text border-4 border-red-600"
                        : "w-[200px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                    }
                  />
                  <h4
                    className={
                      RecipientsCellMove
                        ? "w-[93px] BNP-LableMove -translate-y-[50px]"
                        : "w-[150px] BNP-LableStart pt-[3px]"
                    }
                  >
                    <div className="flex">
                      Recipient Cell
                      <div
                        className={
                          RecipientsCellMove
                            ? "hidden"
                            : "font-semibold pl-1 text-red-700"
                        }
                      >
                        *
                      </div>
                    </div>
                  </h4>
                </div>
              </div>
            </div>

            <div
              className={
                BusCheck
                  ? "transition-height duration-500 ease-in-out h-[48px]" // Show
                  : "transition-height duration-500 ease-in-out h-0 opacity-0 -mt-[9px]" // Hidden
              }
            >
              <Transition
                show={BusCheck}
                enter="transition ease-in-out duration-500 transform"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in-out duration-700 transform"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {/* Business Name */}
                <div className="p-1 mt-[9px] text-md">
                  <div
                    onClick={() => bussinesNameFocusOn()}
                    className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
                  >
                    {BusCheck === true ? (
                      <input
                        value={bussinesName}
                        onChange={(e) => {
                          setBussinesName(formatProperCase(e.target.value));
                          setDeliverAddressUpdate(true);
                        }}
                        onBlur={() => bussinesNameFocusOff()}
                        type="text"
                        ref={bussinesNameRef}
                        autoComplete="off"
                        className={
                          busNameFlag
                            ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text border-4 border-red-600"
                            : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                        }
                      />
                    ) : (
                      <input
                        value={bussinesName}
                        onChange={() => setBussinesName("")}
                        type="text"
                        ref={bussinesNameRef}
                        className="w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                      />
                    )}

                    <h4
                      className={
                        bussinesNameMove
                          ? "w-[104px] BNP-LableMove -translate-y-[50px]"
                          : "w-[160px] BNP-LableStart pt-[3px]"
                      }
                    >
                      <div className="flex">
                        Business Name
                        <div
                          className={
                            bussinesNameMove
                              ? "hidden"
                              : "font-semibold pl-1 text-red-700"
                          }
                        >
                          *
                        </div>
                      </div>
                    </h4>
                  </div>
                </div>
              </Transition>
            </div>

            {/* Building/Complex Name */}
            <div className="p-1 mt-[9px] text-md">
              <div
                onClick={() => ComplexNameFocusOn()}
                className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
              >
                <input
                  value={ComplexName}
                  onChange={(e) => {
                    setComplexName(formatProperCase(e.target.value));
                    setDeliverAddressUpdate(true);
                  }}
                  onBlur={() => ComplexNameFocusOff()}
                  type="text"
                  ref={ComplexNameRef}
                  autoComplete="off"
                  className="w-[350px] h-[40px] pl-4 BNP-Input-thin  text-lg text-BNPblue cursor-text"
                />
                <h4
                  className={
                    ComplexNameMove
                      ? "w-[160px] BNP-LableMove -translate-y-[50px]"
                      : "w-[225px] BNP-LableStart pt-[3px]"
                  }
                >
                  <div className="flex">
                    Building/Complex Name
                    {/* <div
                        className={
                          ComplexNameMove
                            ? "hidden"
                            : "font-semibold pl-1 text-red-700"
                        }
                      >
                        *
                      </div> */}
                  </div>
                </h4>
              </div>
            </div>

            {/* Address*/}
            <div className="p-1 mt-[9px] text-md">
              <div
                onClick={() => AddressFocusOn()}
                className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
              >
                <input
                  value={Address}
                  onChange={(e) => {
                    setAddress(formatProperCase(e.target.value));
                    setDeliverAddressUpdate(true);
                  }}
                  onBlur={() => AddressFocusOff()}
                  type="text"
                  ref={AddressRef}
                  autoComplete="off"
                  className={
                    addressFlag
                      ? "w-[350px] h-[40px] pl-4 BNP-Input-thin  text-lg text-BNPblue cursor-text border-4 border-red-600"
                      : "w-[350px] h-[40px] pl-4 BNP-Input-thin  text-lg text-BNPblue cursor-text"
                  }
                />
                <h4
                  className={
                    AddressMove
                      ? "w-[99px] BNP-LableMove -translate-y-[50px]"
                      : "w-[160px] BNP-LableStart pt-[3px]"
                  }
                >
                  <div className="flex">
                    Street Address
                    <div
                      className={
                        AddressMove
                          ? "hidden"
                          : "font-semibold pl-1 text-red-700"
                      }
                    >
                      *
                    </div>
                  </div>
                </h4>
              </div>
            </div>

            {/* Suburb Name */}
            <div className="p-1 mt-[9px] text-md">
              <div
                onClick={() => SuburbFocusOn()}
                className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
              >
                <input
                  value={Suburb}
                  onChange={(e) => {
                    setSuburb(formatProperCase(e.target.value));
                    setDeliverAddressUpdate(true);
                  }}
                  onBlur={() => SuburbFocusOff()}
                  type="text"
                  ref={SuburbRef}
                  autoComplete="off"
                  className={
                    suburbFlag
                      ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text border-4 border-red-600"
                      : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                  }
                />
                <h4
                  className={
                    SuburbMove
                      ? "w-[54px] BNP-LableMove -translate-y-[50px]"
                      : "w-[100px] BNP-LableStart pt-[3px]"
                  }
                >
                  <div className="flex">
                    Suburb
                    <div
                      className={
                        SuburbMove
                          ? "hidden"
                          : "font-semibold pl-1 text-red-700"
                      }
                    >
                      *
                    </div>
                  </div>
                </h4>
              </div>
            </div>

            {/* City/Town Name */}
            <div className="p-1 mt-[9px] text-md">
              <div
                onClick={() => CityFocusOn()}
                className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
              >
                <input
                  value={City}
                  onChange={(e) => {
                    setCity(formatProperCase(e.target.value));
                    setDeliverAddressUpdate(true);
                  }}
                  onBlur={() => CityFocusOff()}
                  type="text"
                  ref={CityRef}
                  autoComplete="off"
                  className={
                    cityFlag
                      ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text border-4 border-red-600"
                      : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                  }
                />
                <h4
                  className={
                    CityMove
                      ? "w-[69px] BNP-LableMove -translate-y-[50px]"
                      : "w-[115px] BNP-LableStart pt-[3px]"
                  }
                >
                  <div className="flex">
                    City/Town
                    <div
                      className={
                        CityMove ? "hidden" : "font-semibold pl-1 text-red-700"
                      }
                    >
                      *
                    </div>
                  </div>
                </h4>
              </div>
            </div>

            {/* Province Name */}
            <div className="relative mt-[9px]">
              <ProvincePage />
            </div>
            {/* Postal Code */}
            <div className="p-1 -mt-[15px] text-md">
              <div
                onClick={() => PostalCodeFocusOn()}
                className="group w-[150px] mt-1 mb-3 h-[40px] BNP-LableDIV cursor-text"
              >
                <input
                  value={PostalCode}
                  onChange={(e) => {
                    setPostalCode(formatTextNumber(e.target.value));
                    setDeliverAddressUpdate(true);
                  }}
                  onBlur={() => PostalCodeFocusOff()}
                  type="text"
                  ref={PostalCodeRef}
                  autoComplete="off"
                  className={
                    postCodeFlag
                      ? "w-[150px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text border-4 border-red-600"
                      : "w-[150px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                  }
                />
                <h4
                  className={
                    PostalCodeMove
                      ? "w-[78px] BNP-LableMove -translate-y-[50px]"
                      : "w-[125px] BNP-LableStart pt-[3px]"
                  }
                >
                  <div className="flex">
                    Postal Code
                    <div
                      className={
                        PostalCodeMove
                          ? "hidden"
                          : "font-semibold pl-1 text-red-700"
                      }
                    >
                      *
                    </div>
                  </div>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
