import { useContext, useEffect, useRef, useState } from "react";

// Context
import modalContext from "../context/ModalContext";
import AdminContext from "../context/AdminContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function TrackingModalOrderInfo(props) {
  // console.log("Render OrderInfo");

  // Context
  const { setSalePaymentChange, setSaleOrderNo, setSalePaymentType } =
    useContext(modalContext);
 const { setPayFastInvTracking, setPayFastTotalTracking } = useContext(AdminContext);

  // State
  const effectRunOnce = useRef(false);

  // Remember to UPDATE DB data here!!!!

  //   State
  const [paid, setPaid] = useState("Yes");
  const [paidCase, setPaidCase] = useState("pr-1");

  useEffect(() => {
    if (props.paid.toLowerCase() === "no") {
      setPaid(props.paid);
      setPayFastInvTracking("INV"+props.Inv_No);
      setPayFastTotalTracking(props.Total_Amt);
      setPaidCase(
        "text-red-500 font-bold animate-pulse duration-100 border-4 rounded-full p-1 border-red-500"
      );
      setSalePaymentChange(true);
        setSaleOrderNo(props.Order_No);
      
      if (effectRunOnce.current === false) {
        setSalePaymentType(props.paymentType.toLowerCase());
        effectRunOnce.current = true;
      }
    } else {
      setPaidCase("pr-1");
    }
  }, [props, setSalePaymentChange, setSaleOrderNo, setSalePaymentType, setPayFastInvTracking, setPayFastTotalTracking]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="flex-row w-[320px] items-center p-3 text-BNPblue bg-white rounded-md">
        {/* Order Number */}
        <div className="flex justify-between items-center font-semibold text-xl">
          <p className="font-semibold">Order number:</p>
          {props.Order_No}
        </div>
        {/* Inv Number */}
        <div className="flex justify-between items-center font-semibold">
          <p className="font-semibold text-lg">Invoice number:</p>
          {props.Inv_No}
        </div>
        {/* Sales Date */}
        <div className="flex justify-between items-center">
          <p className="font-semibold text-lg mr-10 ">Sales date:</p>
          <p> {props.SalesDate} </p>
        </div>
        {/* Payment Method */}
        <div className="flex justify-between items-center font-semibold">
          <p className="font-semibold text-lg mr-10 ">Payment Type:</p>
          <p> {props.paymentType} </p>
        </div>
        {/* Paid */}
        <div className="flex justify-between items-center font-semibold h-11">
          <p className="font-semibold text-lg mr-10 ">Paid:</p>
          <p className={paidCase}>{paid}</p>
        </div>
      </div>
    </>
  );
}
