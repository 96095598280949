import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

// Context
import ModalContext from "../context/ModalContext";
import UserContext from "../context/UserContext";

export default function CookeiPage() {
  // console.log("Render CookiePage")

  const { setCookieModal, setCookieModalDelay } = useContext(ModalContext);
  const { setCookie } = useContext(UserContext);

  const nav = useNavigate();

  useEffect(() => {
    setCookieModalDelay(false);
  }, [setCookieModalDelay]);

  return (
    <div className="absolute flex-row lg:flex justify-between lg:justify-center w-full font-Montserrat top-0 bg-BNPblue text-BNPgoldLightLight BNP-Trans500">
      <div className="flex w-full lg:w-1/3 justify-center lg:justify-end m-2 BNP-Trans500">
        <img
          src="./images/cookie.PNG"
          alt=""
          className="bg-BNPgoldLight h-[90px] m-2 rounded-full"
        />
      </div>
      <div className=" flex justify-center items-center text-center px-5 w-full lg:w-1/2 BNP-Trans500">
        <div className="flex-row space-y-2 ">
          <text>This website uses ( </text>
          <text className="text-BNPgoldLight">yummy</text>
          <text> ) cookies to deliver a better user experience.</text>

          <h1>
            We assume that you are ok with this, but you can opt-out if you
            wish.
          </h1>
        </div>
      </div>
      <div className="relative flex justify-center items-center pb-3 w-full lg:w-1/3 BNP-Trans500">
        <div className="flex h-[100px] justify-between w-[200px]">
          <div className="flex justify-center items-center w-1/2">
            <button
              onClick={() => {
                Cookies.set("BNPCookies", "true", { expires: 100 });
                setCookieModal(false);
                setCookie(true);
              }}
              className="p-2 border-2 border-BNPgoldLight rounded-md hover:border-[3px] hover:bg-BNPgoldLight hover:text-BNPblue hover:font-semibold hover:border-BNPblue hover:shadow-md hover:shadow-BNPgoldLight transition-all duration-200"
            >
              Accept
            </button>
          </div>
          <div className="flex justify-center items-center w-1/2">
            <button
              onClick={() => {
                Cookies.set("BNPCookies", "false", { expires: 100 });
                setCookieModal(false);
                setCookie(false);
              }}
              className="p-2 border-2 border-BNPgoldLight rounded-md hover:border-[3px] hover:bg-BNPgoldLight hover:text-BNPblue hover:font-semibold hover:border-BNPblue hover:shadow-md hover:shadow-BNPgoldLight transition-all duration-200"
            >
              Reject
            </button>
          </div>
        </div>
        <div
          className="absolute flex bottom-0 left-3 BNP-Links text-BNPgoldLight "
          onClick={() => {
            setCookieModal(false);
            setCookieModalDelay(true);
            nav("/Privacy-Policy");
          }}
        >
          Privacy Policy
        </div>
      </div>
    </div>
  );
}
