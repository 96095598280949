import { createContext, useState } from "react";

// Context
const AdminContext = createContext();

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
export function AdminProvider({ children }) {
  // State
  const [salesVol, setSalesVol] = useState(0);
  const [selectOrderNo, setSelectOrderNo] = useState(0);
  const [orderPaid, setOrderPaid] = useState("No");
  const [orderStatus, setOrderStatus] = useState(false);
  const [ordersModalValidation, setOrdersModalValidation] = useState(false);
  const [salesSummaryData, setSalesSummaryData] = useState([]);
  const [salesOrderInfo, setSalesOrderInfo] = useState([]);
  const [salesClientInfo, setSalesClientInfoData] = useState([]);
  const [salesProductData, setSalesProductData] = useState([]);
  const [regChecksCount, setRegChecksCount] = useState([]);

  const [payFastInvTracking, setPayFastInvTracking] = useState("");
  const [payFastTotalTracking, setPayFastTotalTracking] = useState("");

  const [adminPgRefresh, setAdminPgRefresh] = useState(0);

  const [callBackVol, setCallBackVol] = useState(0);
  const [callBackRefresh, setCallBackRefresh] = useState(0);

  // console.log(regChecksCount);
  // console.log(adminPgRefresh);

  return (
    <AdminContext.Provider
      value={{
        salesVol,
        setSalesVol,

        selectOrderNo,
        setSelectOrderNo,

        regChecksCount,
        setRegChecksCount,

        payFastInvTracking,
        setPayFastInvTracking,
        payFastTotalTracking,
        setPayFastTotalTracking,

        salesSummaryData,
        setSalesSummaryData,
        salesOrderInfo,
        setSalesOrderInfo,
        salesClientInfo,
        setSalesClientInfoData,
        salesProductData,
        setSalesProductData,

        adminPgRefresh,
        setAdminPgRefresh,

        orderPaid,
        setOrderPaid,
        orderStatus,
        setOrderStatus,
        ordersModalValidation,
        setOrdersModalValidation,

        callBackVol,
        setCallBackVol,
        callBackRefresh,
        setCallBackRefresh,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
}

export default AdminContext;
