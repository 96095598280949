import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Pages
import AdminSalesItem from "./AdminSalesItem";
import Delivery from "./AdminSalesDelivery";
import Collect from "./AdminSalesCollection";

// Context
import UserContext from "../context/UserContext";
import UrlContext from "../context/URLContext";
import ModalContext from "../context/ModalContext";
import AdminContext from "../context/AdminContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function SalesAdminPage() {
  // console.log("Render Sales Admin Page");

  // Context
  const { adminSalesSummary, adminSalesProduct } = useContext(UrlContext);
  const { userLogin, userAdmin } = useContext(UserContext);
  const { msgClean, setMsgOpen, setMsgTxt, setMsgType } =
    useContext(ModalContext);
  const {
    adminPgRefresh,
    setAdminPgRefresh,
    salesSummaryData,
    setSalesSummaryData,
    setSalesProductData,
    setSalesVol,
  } = useContext(AdminContext);

  // Navigate
  const nav = useNavigate();

  // const effectRunOnce = useRef(false);
  // if (effectRunOnce.current === false) {
  //   effectRunOnce.current = true;
  // }

  useEffect(() => {
    if (userLogin === false || userAdmin === false) {
      nav("/PageNotFound");
    }

    let errorCnt = 0;
    // Get Admin Summary info from Server
    async function fetchData() {
      // request data from mySQL db
      let url = adminSalesSummary;
      const data = await axios
        .get(url)
        .then((res) => res.data)
        .catch((err) => {
          msgClean();
          setMsgTxt(
            "Network error!  Please check your Internet connection and try again."
          );
          setMsgType("Error");
          setMsgOpen(true);
          errorCnt = 1;
        });
      // console.log(data)
      setSalesSummaryData(data);
    }

    // Get Product data from Server
    async function fetchProductData() {
      // request data from mySQL db
      let url = adminSalesProduct;
      const data = await axios
        .get(url)
        .then((res) => res.data)
        .catch((err) => {
          if (errorCnt === 0) {
            msgClean();
            setMsgTxt(
              "Network error!  Please check your Internet connection and try again."
            );
            setMsgType("Error");
            setMsgOpen(true);
          }
        });
      // console.log(data)
      setSalesProductData(data);
    }

    if (adminPgRefresh === 1) {
      fetchData();
      fetchProductData();
      setAdminPgRefresh(0);
      // console.log("Refresh");
    }
  }, [
    nav,
    userLogin,
    userAdmin,
    adminSalesSummary,
    setMsgOpen,
    msgClean,
    setMsgTxt,
    setMsgType,
    setSalesSummaryData,
    adminPgRefresh,
    setAdminPgRefresh,
    setSalesProductData,
    adminSalesProduct,
  ]);

  // console.log(salesSummaryDataOld)
  // Outstanding Orders vol

  const saleOrdersData = salesSummaryData?.filter(
    (data) => data.orderReady === 0 ||
    data.Verified === 0
  );

  // Pta Delivery vol
  const PtaDeliveryOrdersData = salesSummaryData?.filter(
    (data) =>
      data.orderReady === 1 &&
      data.City === "Pretoria" &&
      data.deliveryType === "Delivery" &&
      data.Verified === 1
      
  );

  // Courier Delivery vol
  const deliveryOrdersData = salesSummaryData?.filter(
    (data) =>
      data.orderReady === 1 &&
      data.deliveryType === "Delivery" &&
      data.City !== "Pretoria" &&
      data.Verified === 1
  );

  // Collection vol
  const CollectOrdersData = salesSummaryData?.filter(
    (data) => data.orderReady === 1 && data.deliveryType === "Collect" &&
    data.Verified === 1
  );

  useEffect(() => {
    setSalesVol(saleOrdersData.length);
  }, [saleOrdersData, setSalesVol]);

  // console.log(CollectOrdersData);
  // console.log(salesSummaryData)

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="flex h-100 font-Montserrat overflow-y-scroll text-BNPblue">
        {/* 1st Half */}
        <div className="flex-row w-1/2 border-r-2 border-BNPblue pb-8">
          {/* Header */}
          <div className="flex justify-between items-center p-4 pl-3 text-xl font-bold">
            <div className="ml-3">Embossing Summary Info</div>
            <div className="flex items-center mr-7">
              <div>Outstanding Orders </div>
              <div className="flex justify-center items-center text-center ml-4 w-10 h-10 pt-[2px] border-4 rounded-full border-red-600 animate-pulse duration-300">
                {saleOrdersData?.length}
              </div>
            </div>
          </div>

          {/* Body */}
          <div className="flex-row ml-9 mr-7 cursor-pointer space-y-3 overflow-y-scroll no-scrollbar">
            {saleOrdersData?.map((data) => (
              <div key={data.id}>
                {/* Sales Item */}
                <AdminSalesItem
                  id={data.id}
                  SalesDate={data.SalesDate}
                  SaleType={data.SaleType}
                  SaleVol={data.SaleVol}
                  paymentType={data.paymentType}
                  paid={data.paid}                 
                  Province={data.Province}
                  City={data.City}
                  Suburb={data.Suburb}
                  bussinesName={data.bussinesName}
                  DeliveryName={data.DeliveryName}
                  RecipientCell={data.RecipientCell}
                />
              </div>
            ))}
          </div>
        </div>

        {/* 2nd Half */}
        <div className="flex-row w-100">
          {/* Pretoria Delivery */}
          <div className="flex-row">
            {/* Header */}
            <div className="flex items-start px-4 pt-4 pb-2 pl-3 text-xl font-bold">
              <div className="flex px-2 border-b-2 border-BNPblue border-opacity-30">
                <div>Pretoria Delivery:</div>
                <div className="text-right ml-4 w-8">
                  {PtaDeliveryOrdersData?.length}
                </div>
              </div>
            </div>

            {/* Body */}
            <div className="flex-row space-x-3 overflow-y-scroll no-scrollbar pb-2">
              {PtaDeliveryOrdersData?.map((data) => (
                <div key={data.id}>
                  {/* Delivery info */}
                  <div className="flex">
                    <Delivery
                      id={data.id}
                      City={data.City}
                      Suburb={data.Suburb}
                      bussinesName={data.bussinesName}
                      DeliveryName={data.DeliveryName}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Courier Delivery */}
          <div className="flex-row border-t-2 border-BNPblue ">
            {/* Header */}
            <div className="flex items-start p-4 pl-3 text-xl font-bold">
              <div className="flex px-2 border-b-2 border-BNPblue border-opacity-30">
                <div>Courier Delivery:</div>
                <div className="text-right ml-4 w-8">
                  {deliveryOrdersData?.length}
                </div>
              </div>
            </div>

            {/* Body */}
            <div className="flex-row space-x-3 overflow-y-scroll no-scrollbar pb-4">
              {deliveryOrdersData?.map((data) => (
                <div key={data.id}>
                  <div className="flex">
                    <Delivery
                      id={data.id}
                      City={data.City}
                      Suburb={data.Suburb}
                      bussinesName={data.bussinesName}
                      DeliveryName={data.DeliveryName}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Collection */}
          <div className="flex-row w-auto border-t-2 border-BNPblue pb-8">
            {/* Header */}
            <div className="flex w-auto p-4 pl-3 text-xl font-bold">
              <div className="flex px-2 border-b-2 border-BNPblue border-opacity-30">
                <div>Collection Orders:</div>
                <div className="text-right ml-4 w-8">
                  {CollectOrdersData?.length}
                </div>
              </div>
            </div>

            {/* Body */}
            <div className="flex-row w-auto mx-4 space-y-2 overflow-y-scroll no-scrollbar pb-4">
              {CollectOrdersData?.map((data) => (
                <div key={data.id}>
                  <div className="cursor-pointer">
                    <Collect
                      id={data.id}
                      bussinesName={data.bussinesName}
                      DeliveryName={data.DeliveryName}
                      RecipientCell={data.RecipientCell}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
