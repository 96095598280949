import { useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import axios from "axios";

// Img
import * as riIcons from "react-icons/ri";

// Context
import UrlContext from "../context/URLContext";
import modalContext from "../context/ModalContext";
import AdminContext from "../context/AdminContext";

//////////////////////////////////////

export default function OrderModalOrderInfo(props) {
  // console.log("Render OrderInfo");

  // Context
  const { updatePaidURL } = useContext(UrlContext);
  const { msgClean, setMsgOpen, setMsgTxt, setMsgType } =
    useContext(modalContext);
  const { setAdminPgRefresh, setOrderPaid, setOrderStatus } =
    useContext(AdminContext);

  const handlePaymentReceived = (e) => {
    if (e.target.id !== paid) {
      let i = 0;
      if (e.target.id === "No") {
        setPaid("No");
        setpaymentType("No");
        setOrderStatus(false);
        setPaidCase(
          "text-red-500 font-bold animate-pulse duration-100 border-4 rounded-full p-1 border-red-500 "
        );
      } else {
        setPaid("Yes");
        setpaymentType("Yes");
        setPaidCase("pr-1");
        i = 1;
      }

      // mySQL db update.
      updatePaid(i);
    }

    // Close Drop Down
    setDDstate(false);
  };

  //   State

  const [paid, setPaid] = useState("Yes");
  const [paymentType, setpaymentType] = useState("Yes");
  const [paidCase, setPaidCase] = useState("pr-1");
  const [ddState, setDDstate] = useState(false);

  useEffect(() => {
    if (props.paid === "No") {
      setPaid(props.paid);
      setpaymentType(props.paymentReceived);
      setPaidCase(
        "text-red-500 font-bold animate-pulse duration-100 border-4 rounded-full p-1 border-red-500"
      );
    }
  }, [props.paid, props.paymentReceived]);

  const updatePaid = async (i) => {
    // Update payment received to db.
    let url = updatePaidURL + props.Order_No;
    const data = await axios
      .patch(url, {
        Paid: i,
      })
      .then((res) => res)
      .catch((err) => {
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });
    // console.log(data);

    if (data.status === 200) {
      msgClean();
      setMsgTxt("Payment updated!");
      setMsgType("Success");
      setMsgOpen(true);
      setAdminPgRefresh(1);

      if (i === 1) {
        setOrderPaid("Yes");
      } else {
        setOrderPaid("No");
      }
    }
  };



  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div className="flex justify-between gap-5">
        <div className="flex-row items-between w-[50%] p-3 text-BNPblue bg-white">
          {/* Order Number */}
          <div className="flex justify-between items-center font-semibold text-xl">
            <p className="font-semibold">Order number:</p>
            {props.Order_No}
          </div>
          {/* Delivery Type */}
          <div className="flex justify-between items-center font-semibold">
            <p className="font-semibold text-lg">Delivery Type:</p>
            {props.DeliveryType}
          </div>
          {/* Sales Date */}
          <div className="flex justify-between items-center">
            <p className="font-semibold text-lg mr-10 ">Sales date:</p>
            <p> {props.SalesDate} </p>
          </div>
          {/* Sales Vol */}
          <div className="flex justify-between items-center font-semibold">
            <p className="font-semibold text-lg mr-10 ">Items:</p>
            <p> {props.SaleVol} </p>
          </div>
        </div>
        <div className="flex-row items-center w-[50%] p-3 text-BNPblue bg-white">
          {/* Payment Method */}
          <div className="flex justify-between items-center font-semibold">
            <p className="font-semibold text-lg mr-10 ">Payment Type:</p>
            <p> {props.paymentType} </p>
          </div>
          {/* Paid */}
          <div className="flex justify-between items-center font-semibold h-11">
            <p className="font-semibold text-lg mr-10 ">Paid:</p>
            <p className={paidCase}>{paid}</p>
          </div>
          {/* Payment Received */}
          <div className="relative flex justify-between items-center font-semibold">
            <p className="font-semibold text-lg mr-10 ">Payment Received:</p>

            {/* DropDown */}
            <div className="absolute flex-row right-0 top-0 -mr-1">
              <div
                onClick={() => setDDstate(!ddState)}
                className="flex items-center justify-between w-[80px] text-right border-2 border-BNPblue cursor-pointer"
              >
                <riIcons.RiArrowDropDownLine
                  onClick={() => setDDstate(!ddState)}
                  className={
                    ddState
                      ? "text-3xl cursor-pointer border-BNPblue transition-all duration-500 rotate-180 hover:text-BNPgoldDark"
                      : "text-3xl cursor-pointer border-BNPblue transition-all duration-500 hover:text-BNPgoldDark"
                  }
                />
                <p className="pr-2 ">{paymentType}</p>
              </div>
              <Transition
                show={ddState}
                enter="transition ease-in-out duration-700 transform"
                enterFrom="-translate-y-[50%] opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition ease-in-out duration-700 transform"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="-translate-y-[50%] opacity-0"
                className="bg-white text-right border-r-2 border-l-2 border-b-2 border-BNPblue"
              >
                <div>
                  <p
                    id="Yes"
                    onClick={handlePaymentReceived}
                    className="hover:bg-BNPblue cursor-pointer hover:text-BNPgoldLightLight px-1 focus-"
                  >
                    Yes
                  </p>
                  <p
                    id="No"
                    onClick={handlePaymentReceived}
                    className="hover:bg-BNPblue cursor-pointer hover:text-BNPgoldLightLight px-1"
                  >
                    No
                  </p>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
