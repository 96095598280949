import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";

export default function CarouselImg(props) {

  const [triggerClean, setTriggerClean] = useState(false);

  const [dirEnterFrom, setDirEnterFrom] = useState("n");
  const [dirEnterTo, setDirEnterTo] = useState("n");

  const [dirLeaveFrom, setDirLeaveFrom] = useState("n");
  const [dirLeaveTo, setDirLeaveTo] = useState("n");



  useEffect(() => {
    if (props.Ctrigger !== "true") {
      setTriggerClean(props.Ctrigger);
    }
    else {
      setTriggerClean(true);
    };

    if (props.Cdirection === "top") {
      setDirEnterFrom("-translate-y-full opacity-0");
      setDirEnterTo("translate-y-0 opacity-100");
      setDirLeaveFrom("translate-y-0 opacity-100");
      setDirLeaveTo("translate-y-full opacity-0");
    } else if (props.Cdirection === "left") {
      setDirEnterFrom("translate-x-full opacity-0");
      setDirEnterTo("translate-x-0 opacity-100");
      setDirLeaveFrom("translate-x-0 opacity-100");
      setDirLeaveTo("-translate-x-full opacity-0");
    };

  }, [props.Ctrigger, props.Cdirection])

  // console.log(props.Cdirection)
  // console.log(dirEnterFrom)
  // console.log(dirEnterTo)
  // console.log(dirLeaveFrom)
  // console.log(dirLeaveTo)

  return (
    <>
      <Transition
        show={triggerClean}
        enter="transition ease-in-out duration-700 transform"
        enterFrom={dirEnterFrom}
        enterTo={dirEnterTo}
        leave="transition ease-in-out duration-700 transform"
        leaveFrom={dirLeaveFrom}
        leaveTo={dirLeaveTo}
      >
        <img src={props.Cimgs} alt="" className={props.CimgClass} />
      </Transition>

    </>
  );
};