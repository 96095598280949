import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CreateAcctPage from "./CreateAcctPage";
import UserInfoPage from "./UserInfoPage";
import AddressPage from "./RegisterAddressPagel";

// Context
import UserContext from "../context/UserContext";

// Data
import newUserTemplate from "../data/Lookups/newUserTemplate.json"

export default function RegisterPage() {
  // console.log("Render RegisterPage");

  // Navigate
  const nav = useNavigate();

  // Context
  const { userLogin, newUser, setNewUser } = useContext(UserContext);

  useEffect(() => {
    if (userLogin === true) {
      nav("/PageNotFound");
    }

    if (newUser.length === 0)
    { setNewUser(newUserTemplate);}
 
  }, [nav, userLogin, newUser, setNewUser]);

  return (
    <>
      <div className="relative flex">
        <section className="font-Montserrat">
          <div className="flex flex-col flex-wrap gap-2 xl:flex-row w-full ">
            {/* Acct info */}
            <div className="">
              <CreateAcctPage />
            </div>

            {/* Client details */}

            {/* User Info */}
            <div className="">
              <div>
                <UserInfoPage />
              </div>
            </div>
            {/* Address info */}
            <div className="">
              <AddressPage />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
