import React, { useRef, useState, useContext, useEffect } from "react";
import { Transition } from "@headlessui/react";

// Data
// Formatting
import formatProperCase from "../utilities/formatProperCase";
import IdTypeData from "../data/Lookups/IDType.json";

// Context
import UserContext from "../context/UserContext";
import ModalContext from "../context/ModalContext";

// Img
import * as riIcons from "react-icons/ri";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function IdPassportLookup() {
  // console.log("Render IdPassportLookup");

  // Context
  const { IdType, setIdType } = useContext(UserContext);
  const { IdTypeDdOpen, setIdTypeDdOpen, IDTypeInValid, setIDTypeInValid } =
    useContext(ModalContext);

  const IdTypeRef = useRef();
  const [IdTypeMove, setIdTypeMove] = useState(false);

  const ddRef = useRef();

  // build IdType Array
  const [clearText, setClearText] = useState(false);

  // build ID/Passport Array
  // Filter by input
  const filterData2 = IdTypeData.filter((data) => {
    return data.IDType.toLowerCase().startsWith(IdType.toLowerCase());
  });

  useEffect(() => {
    if (IdType.length !== 0 || IdTypeDdOpen === true) {
      setIdTypeMove(true);
      setIDTypeInValid(false);
    } else {
      setIdTypeMove(false);
    }

    if (IdType.length === 0) {
      setClearText(false);
    } else {
      setClearText(true);

      if (
        filterData2.find((data) => {
          return data.IDType.toLowerCase() === IdType.toLowerCase();
        })
      ) {
        // console.log("match full ID or ID Null");
        setIDTypeInValid(false);
      } else if (
        // check if dd closed to early
        IdTypeDdOpen === false
      ) {
        setIDTypeInValid(true);
      } else if (
        filterData2.find((data) => {
          return data.IDType.toLowerCase().startsWith(IdType.toLowerCase());
        })
      ) {
        // console.log("start match ID");
        setIDTypeInValid(false);
      }
    }
  }, [IdType, filterData2, setIDTypeInValid, IdTypeDdOpen]);

  const closeOutSide = (e) => {
    setIdTypeDdOpen(!IdTypeDdOpen);				
};

  // IdType
  const IdTypeFocusOn = () => {
    setIdTypeMove(true);
    IdTypeRef.current.focus();
  };

  const IdTypeFocusOff = () => {
    if (IdType.length !== 0 || IdTypeDdOpen === true) {
      setIdTypeMove(true);
    } else {
      setIdTypeMove(false);
    }
  };

  // Reset Input Field handler
  const clearIdType = () => {
    setIdType("");
    setClearText(false);
    setIDTypeInValid(false);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div onClick={closeOutSide} className="p-1 text-md flex justify-end">
        <div
          onClick={() => IdTypeFocusOn()}
          className="group w-[190px] h-[40px] BNP-LableDIV cursor-pointer"
        >
          <div className="flex">
            <input
              id="IDType_lookup_Close"													
              value={IdType}
              onChange={(e) => {
                setIdType(formatProperCase(e.target.value));
                setIdTypeDdOpen(true);
              }}
              onBlur={() => IdTypeFocusOff()}
              type="text"
              ref={IdTypeRef}
              autoComplete="off"
              className={
                IDTypeInValid
                  ? "w-[190px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-pointer border-4 focus:ring-4 focus:ring-red-500 focus:border-red-600 border-red-600 hover:border-[5px] hover:border-red-600" // Error in Text typing
                  : "w-[190px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-pointer"
              }
            />
            <div className="relative flex-row -ml-[36px] top-0">
              <riIcons.RiArrowDropDownLine
                id="IDType_lookup_Close_Arrow"									  
                className={
                  IdTypeDdOpen
                    ? "absolute z-30 w-10 h-10 text-3xl cursor-pointer transition-all duration-500 rotate-180 text-BNPblue"
                    : "absolute z-30 w-10 h-10 text-3xl cursor-pointer transition-all duration-500 text-BNPblue"
                }
              />
            </div>
            <div className="absolute z-20 w-[190px] mt-[37px]">
              <Transition
                show={IdTypeDdOpen}
                enter="transition ease-in-out duration-700 transform"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in-out duration-700 transform"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="bg-white text-BNPblue border-2 border-BNPblue rounded-b-md shadow-md focus:ring focus:border-BNPblue hover:border-BNPblue BNP-Trans200"
              >
                <div className="flex-row items-center bg-white max-h-48 overflow-y-auto">
                  {filterData2.map((items) => (
                    <div
                      id={items.IDType}
                      key={items.IDType}
                      ref={ddRef}
                      onClick={(e) => {
                        setIdType(e.target.id);
                        setIdTypeDdOpen(false);
                        console.log(items.id);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setIdType(e.target.id);
                          setIdTypeDdOpen(false);
                        }
                      }}
                      tabIndex={0}
                      className="pl-4 py-1 text-lg hover:bg-BNPblue hover:text-BNPgoldLight"
                    >
                      {items.IDType}
                    </div>
                  ))}
                </div>
              </Transition>
            </div>
          </div>
          <h4
            className={
              IdTypeMove
                ? "w-[55px] BNP-LableMove -translate-y-[50px]"
                : "group w-[100px] BNP-LableStart h-[31px] flex items-center"
            }
          >
            <div id="IdType_lookup_Close2" className="flex">
              ID Type
              <div
                id="IdType_lookup_Close3"
                className={
                  IdTypeMove ? "hidden" : "font-semibold pl-1 text-red-700"
                }
              >
                *
              </div>
            </div>
          </h4>
        </div>
      </div>
      <div className="flex h-5 justify-end">
        <Transition
          show={clearText}
          enter="transition ease-in-out duration-500 transform"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in-out duration-500 transform"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <p
            onClick={clearIdType}
            className="w-[55px] BNP-Links -mt-3 mb-0 pb-0"
          >
            clear
          </p>
        </Transition>
      </div>
    </>
  );
}
