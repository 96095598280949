import React, { useContext, useEffect, useState } from "react";

// Context
import SalesCartContext from "../context/SalesCartContext";

// React Icons
import * as fa6Icons from "react-icons/fa6";

// formating
import formatCurrency from "../utilities/formatCurrency";
import ViewRegImg from "../utilities/regPlateimgView";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default function SalesCartItems() {
  // Context
  const {
    cartItems,
    setCartItems,
    deleteByID,
    CartVolDel,
    CartVoladd,
    setCartClick,
    saveLocalSales,
  } = useContext(SalesCartContext);

  // Screen Size effect
  useEffect(() => {
    if (cartItems.length > 0) saveLocalSales();

    // Close cart if nothing is left in it.
    if (cartItems.length === 0) {
      setCartClick(false);
    }
  }, [cartItems, setCartClick, saveLocalSales]);

  const [zoomSizeP, setZoomSizeP] = useState("S1");
  const [zoomSizeM, setZoomSizeM] = useState("S1");

  const modalCaseSizes = {
    P1: "scale-100",
    P2: "scale-125",
    M1: "scale-100",
    M2: "scale-125",
  };

  // Add
  const mouseSaleZoomInP = () => {
    setZoomSizeP("P2");
  };
  const mouseSaleZoomOutP = () => {
    setZoomSizeP("P1");
  };
  //  Remove
  const mouseSaleZoomInM = () => {
    setZoomSizeM("M2");
  };
  const mouseSaleZoomOutM = () => {
    setZoomSizeM("M1");
  };

  const addExtraToCart = (UnqID, prodTypes) => {
    // console.log(pSize + "^BackPlate");

    let PlateQuant = 0;
    setCartItems((cartItems) => {
      if (cartItems.find((item) => item.id === UnqID)) {
        return cartItems.map((item) => {
          if (item.id === UnqID) {
            if (item.prodType === "plate" && item.quant !== 0) {
              PlateQuant = item.quant;
            }
            return { ...item, quant: item.quant + 1 };
          } else {
            return item;
          }
        });
      }
    });

    // console.log("start+");
    // Check if there are any Rivets in the Cart already, else do nothing.
    if (PlateQuant !== 0 && prodTypes === "plate") {
      const newCart = cartItems.filter((data) => data.prodType === "rivet");

      if (newCart.length > 0) {
        // Auto rivet add if a Plate is added
        setCartItems((cartItems) => {
          if (cartItems.find((item2) => item2.id === "Rivet")) {
            return cartItems.map((item2) => {
              if (item2.id === "Rivet" && item2.quant >= PlateQuant) {
                CartVoladd("rivet");
                // console.log("add Rivet");
                return { ...item2, quant: item2.quant + 1 };
              } else {
                return item2;
              }
            });
          }
        });
      }
    }

    CartVoladd(prodTypes);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {cartItems.map((cartItem) => (
        <div key={cartItem.id}>
          {/* {cartItem.reg} */}
          <div className="flex-row justify-center items-center m-1 w-auto border-2 border-BNPblue bg-BNPgoldLight bg-opacity-70">
            <div className="flex-row lg:flex lg:justify-between transition-all duration-500">
              <div className="flex justify-end lg:justify-start p-3 ">
                {/* Item Image */}

                <ViewRegImg // Has to start with Uppercase letter
                  Cformat={"0"}
                  CType={cartItem.prodType}
                  Cimgs={cartItem.plate}
                  CpID={cartItem.id}
                  CregNo={cartItem.reg}
                  Ccase={cartItem.pCase}
                />

                {/* <img src={cartItem.plate} alt="" className={cartItem.pCase} /> */}
              </div>
              <div className="flex justify-end lg:justify-start space-x-3 pl-3 lg:pl-0">
                <div className="flex-row w-[auto] mt-1 mb-2">
                  <p className="font-semibold text-BNPblue">
                    Item Description:
                  </p>
                  <p className="text-BNPblue">{cartItem.province} </p>
                  <p className="text-BNPblue">{cartItem.size}</p>
                </div>
                <div className="flex ">
                  <div className="flex-row h-[85px] w-28 mt-1 mr-1 pr-2">
                    <p className="text-right pr-1 text-BNPblue">
                      R {formatCurrency(cartItem.price)}
                    </p>
                    <div className="flex justify-between items-start pb-1 border-b-2 border-BNPblue border-opacity-50">
                      <p className="text-BNPblue pl-2">Qty:</p>
                      <p className="pr-1 text-BNPblue">{cartItem.quant}</p>
                    </div>
                    <p className="text-BNPblue text-right pt-1 font-semibold">
                      R {formatCurrency(cartItem.price * cartItem.quant)}
                    </p>
                  </div>
                  <div className="flex-row pr-1 py-3 text-BNPblue">
                    <div
                      onMouseEnter={mouseSaleZoomInP}
                      onMouseLeave={mouseSaleZoomOutP}
                      onClick={() =>
                        addExtraToCart(cartItem.id, cartItem.prodType)
                      }
                      className="h-1/2 p-2 mb-[2px] flex justify-center items-center border-2 rounded-md border-BNPblue border-opacity-40 hover:shadow-sm hover:shadow-BNPblue hover:font-semibold hover:cursor-pointer"
                    >
                      <fa6Icons.FaPlus
                        className={`${modalCaseSizes[zoomSizeP]} ...`}
                      />
                    </div>
                    <div
                      onMouseEnter={mouseSaleZoomInM}
                      onMouseLeave={mouseSaleZoomOutM}
                      onClick={() => {
                        deleteByID(cartItem.id);
                        CartVolDel(cartItem.prodType);
                      }}
                      className="h-1/2 p-2 mt-[2px] flex justify-center items-center border-2 rounded-md border-BNPblue border-opacity-40 hover:shadow-sm hover:shadow-BNPblue hover:font-semibold hover:cursor-pointer"
                    >
                      <fa6Icons.FaMinus
                        className={`${modalCaseSizes[zoomSizeM]} ...`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
