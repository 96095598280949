import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// React Icons
import * as aiIcons from "react-icons/ai";

// Context
import modalContext from "../context/ModalContext";
import SalesCartContext from "../context/SalesCartContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function SalePayFastPage() {
  // console.log("Render SalePayFastPage")

  // Navigate
  const nav = useNavigate();

  // Context
  const { setSalePayShapOpen, openPaymentTypes, setOpenPaymentTypes } = useContext(modalContext);

  // State
  const { invNo } = useContext(SalesCartContext);

  // Close
  const closeClick = () => {
    setSalePayShapOpen(false);
    if (openPaymentTypes === true) {
      setOpenPaymentTypes(false);
      nav("/Tracking");
    } else {
      nav("/PaySuccess");
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="flex justify-center max-h-full font-Montserrat">
        <div className="flex-row pt-[5%] pb-100 px-100">
          {/* Header */}
          <div className="relative flex justify-center items-center w-[500px] h-[70px] text-blue-950 bg-orange-600 shadow-md shadow-BNPblue">
            <div className="text-3xl font-semibold">
              <h1>Order Confirmation.</h1>
            </div>
            <div className="absolute top-4 left-4">
              <div className="pt-1 pl-1">
                <aiIcons.AiOutlineClose
                  className="rounded-full focus:outline-none transition-all duration-500 scale-[2.2] hover:font-semibold hover:scale-[2.9] hover:rotate-90
                  hover:border-2 hover:rounded-full hover:border-BNPblue cursor-pointer hover:transition-all hover:duration-700"
                  onClick={closeClick}
                />
              </div>
            </div>
          </div>

          {/* Body */}

          <div className="flex-row justify-between px-2 py-4 bg-white rounded-b-md text-BNPblue shadow-md shadow-BNPblue">
            <div className="flex-row items-center justify-between  mx-14">
              <h1 className=" font-bold text-center text-xl pb-4">
                You have selected to make use of PayShap to make payment.
              </h1>
              <div className="flex justify-center pb-3">
                <p className="text-center text-lg"> PayShapID: </p>
                <p className=" font-semibold text-center text-lg ml-3">
                  0615252099@FNB
                </p>
              </div>

              <div className="flex justify-center items-center text-lg">
                <p>Use payment reference: </p>
                <p className=" font-semibold text-center text-lg ml-3">
                  {invNo}
                </p>
              </div>

              <div className="flex justify-center text-center text-lg mt-3 pt-3 border-t-2 border-BNPblue border-opacity-40 text-red-700">
                <p>
                  Note that the order will only be Finalized and items picked
                  once the payment reflects in our bank account!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
