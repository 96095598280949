import React, { useContext, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Transition } from "@headlessui/react";
import Cookies from "js-cookie";

// Components
import SideBar from "../components/SideBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTop from "../utilities/ScrollToTop";

// Pages
import Home from "./HomePage";
import Sales from "./SalesPage";
import Leg from "./Legislation";
import CurLeg from "./LegislationCurrent";
import FutureLeg from "./LegislationFuture";
import About from "./AboutPage";
import Contacts from "./ContactsPage";
import FAQ from "./FAQPage";
import Tracking from "./TrackingPage";
import UserRegister from "./RegisterPage";
import TsAndCs from "./TsAndCs";
import PrivacyPolicy from "./PrivacyPolicyPage";
import PasswordRecover from "./PasswordRecover";
import SalesPayment from "./SalesPayment";
import SalesAdmin from "./AdminPage";
import OrdersPage from "./OrdersModal";
import TrackingOrdersModalPrevImage from "./TrackingOrdersModalPrevImage";
import AddressPage from "./AddressPage";
import AccountInfoPage from "./AccountInfoPage";
import PasswordChangePage from "./PasswordChangePage";
import InvoicesPage from "./InvoicesPage";
import SalesPaymentComplete from "./SalesPaymentPaidComplete";
import CallBacksPage from "./CallBacksPage";

import LoggedOutPage from "./LoggedOutPage";
import LogOutSesionExpPage from "./LogOutSesionExpPage";
import Page404 from "./Page404";

// Context
import UrlContext from "../context/URLContext";
import ModalContext from "../context/ModalContext";
import SalesCartContext from "../context/SalesCartContext";
import UserContext from "../context/UserContext";

// Utilities
import ErrorMsg from "../utilities/ErrorMessages";

// Modal
import CookieModal from "./cookiePage";
import LoginRegisterModal from "./LoginRegisterModal";
import SalesCartModal from "./SalesCartModal";
import PrevImagesModal from "./SalesPageModalPrevImage";
import DelMethodMaodal from "./DeliveryMethod";
import AddressModal from "./DeliveryAddressModal";
import OrdersModal from "./OrdersModal";
import TrackingModal from "./TrackingItemsModal";
import InvoiceModal from "./SalesInvoicePrintModal";
import PaymentPayFastModal from "./PaymentsPayFastModal";
import PaymentPayShapModal from "./PaymentsPayShapModal";
import PaymentsYocoCashModal from "./PaymentsYocoCashModal";
import SalesPaymentCheckLoginModal from "./SalesPaymentCheckLoginModal";

// export const salesCartContext = React.createContext(false);

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
function App() {
  const { BNPloadURLs, URLsLoaded } = useContext(UrlContext);
  const { setCookie, readLocalID } = useContext(UserContext); // setUserLogin, setUserID, setUserAdmin, setPrefName

  const {
    pwResteLock,
    cookieModal,
    setCookieModal,
    loginModal,
    salesModalPrevOpen,
    prevType,
    prevDoc,
    deliveryMethodOpen,
    deliveryAddressOpen,
    ordersModalOpen,
    setProvDdOpen,
    provDdOpen,
    trackingModalOpen,
    invoiceModalOpen,
    trackingModalImgPreview,
    logoutDropDown,
    setLogoutDropDown,
    msgOpen,
    msgTxt,
    salePayFastOpen,
    salePayShapOpen,
    saleYocoCashOpen,
    salePaymentCheckLoginOpen,
    IdTypeDdOpen,
    setIdTypeDdOpen,
  } = useContext(ModalContext);
  const { cartClick, readLocalSales } = useContext(SalesCartContext);

  // State
  const effectRanOnce = useRef(false);
  const effectRunOnce = useRef(false);
  const [pwCaseLocked, setPwCaseLocked] = useState("auto");
  const [pwCaseOpacity, setPwCaseOpacity] = useState("1");

  // Close the any DropDown on click outside
  const closeOutSide = (e) => {
    if (provDdOpen === true) {
      setProvDdOpen(!provDdOpen);
    }
    if (IdTypeDdOpen === true) {
      setIdTypeDdOpen(!IdTypeDdOpen);
    }

    if (logoutDropDown === true) {
      setLogoutDropDown(false);
      // console.log(e.target);
    }
  };

  useEffect(() => {
    // load all URLs
    if (effectRanOnce.current === false) {
      BNPloadURLs();
      if (URLsLoaded === "done") {
        effectRanOnce.current = true;
      }
    }

    // Check cookies exists and set cookies
    let localCookies = Cookies.get("BNPCookies");
       // console.log(localCookies);

    if (localCookies?.length > 0) {
      setCookieModal(false);
      if (localCookies === "true") {
        setCookie(true);

        // Load local storage on startup
        if (effectRunOnce.current === false) {
          // console.log("Load Local data");
          readLocalID();
          readLocalSales();
          effectRunOnce.current = true;
        }
      } else {
        setCookie(false);
      }
    } else {
      setCookieModal(true);
    }

    // Check if Password Reset enabled
    if (pwResteLock === true) {
      setPwCaseLocked("none");
      setPwCaseOpacity("0.3");
    } else {
      setPwCaseLocked("auto");
      setPwCaseOpacity("1");
    }
  }, [
    pwResteLock,
    setCookie,
    setCookieModal,
    readLocalID,
    readLocalSales,
    BNPloadURLs,
    URLsLoaded,
  ]);

  // console.log(cartVolume);

  // // Testing admin login
  // useEffect(() => {
  //   setUserLogin(true);
  //   setUserID("1");
  //   setUserAdmin(true);
  //   setPrefName("Brent");
  // }, [setUserLogin, setUserID, setUserAdmin, setPrefName]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <dir onClick={closeOutSide} className="flex min-h-svh">
        <Router>
          <ScrollToTop />

          {/* Body */}
          <div className="flex w-100 min-w-[550px] BNP-Trans500">
            <nav
              style={{ pointerEvents: pwCaseLocked, opacity: pwCaseOpacity }}
              className="z-10 "
            >
              <SideBar />
            </nav>

            <main className="flex-col min-h-svh w-100 bg-BNPgoldLightLight BNP-Trans500">
              <header className="flex w-100">
                <Header />
              </header>

              <div className="px-3 bg-BNPgoldLightLight min-w-[550px] BNP-Trans500">
                <Routes>
                  <Route path="/" Component={Home} />
                  <Route path="/Sales" Component={Sales} />
                  <Route path="/Legislation" Component={Leg} />
                  <Route path="/CurrentLegislation" Component={CurLeg} />
                  <Route path="/FutureLegislation" Component={FutureLeg} />
                  <Route path="/About" Component={About} />
                  <Route path="/Contacts" Component={Contacts} />
                  <Route path="/FAQ" Component={FAQ} />
                  <Route path="/Tracking" Component={Tracking} />
                  <Route path="/Register" Component={UserRegister} />
                  <Route path="/PasswordRecover" Component={PasswordRecover} />
                  <Route path="/Payreview" Component={SalesPayment} />
                  <Route path="/Ts&Cs" Component={TsAndCs} />
                  <Route path="/Privacy-Policy" Component={PrivacyPolicy} />
                  <Route path="/SalesAdmin" Component={SalesAdmin} />
                  <Route path="/Orders" Component={OrdersModal} />
                  <Route path="/DeliveryAddress" Component={AddressPage} />
                  <Route path="/AccountInfo" Component={AccountInfoPage} />
                  <Route path="/CallBacks" Component={CallBacksPage} />

                  <Route
                    path="/PasswordChange"
                    Component={PasswordChangePage}
                  />
                  <Route path="/Invoices" Component={InvoicesPage} />
                  <Route path="/PaySuccess" Component={SalesPaymentComplete} />

                  <Route path="/LoggedOut" Component={LoggedOutPage} />
                  <Route
                    path="/Session-Expied"
                    Component={LogOutSesionExpPage}
                  />
                  <Route path="*" Component={Page404} />
                </Routes>
              </div>

              <footer
                style={{ pointerEvents: pwCaseLocked, opacity: pwCaseOpacity }}
                className="scroll-smooth sticky top-[100svh] mt-auto w-100 h-18 BNP-Trans500"
              >
                <Footer />
              </footer>
            </main>
          </div>

          {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {/* Modals */}

          {/* Error messages */}
          <Transition
            show={msgOpen}
            enter="transition ease-in-out duration-500 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-500 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="backdrop-blur-sm fixed top-0  w-100 h-100 z-40 -ml-4"
          >
            <ErrorMsg msg={msgTxt} />
          </Transition>

          {/* cookie Modal */}
          <Transition
            show={cookieModal}
            enter="transition ease-in-out duration-400 transform"
            enterFrom="-translate-y-full opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition ease-in-out duration-400 transform"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="-translate-y-full opacity-0"
            className="z-20 fixed inset-0"
          >
            <CookieModal />
          </Transition>

          {/* Login Modal */}
          <Transition
            show={loginModal}
            enter="transition ease-in-out duration-700 transform"
            enterFrom="-translate-x-full opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="-translate-x-full opacity-0"
            className="z-20 fixed inset-0 backdrop-blur-sm"
          >
            <LoginRegisterModal />
          </Transition>

          {/* Cart Modal */}
          <Transition
            show={cartClick}
            enter="transition ease-in-out duration-700 transform"
            enterFrom="translate-x-full opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="translate-x-full opacity-0"
            className="z-20 fixed inset-0 backdrop-blur-sm"
          >
            <SalesCartModal />
          </Transition>

          {/* Sales Img Prev Modal */}
          <Transition
            show={salesModalPrevOpen}
            enter="transition ease-in-out duration-500 transform"
            enterFrom="-translate-x-full opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave="transition ease-in-out duration-500 transform"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="translate-x-full opacity-0"
            className="z-20 fixed inset-0 backdrop-blur-sm"
          >
            <PrevImagesModal type={prevType} prev={prevDoc} />
          </Transition>

          {/* Delivery method Modal */}
          <Transition
            show={deliveryMethodOpen}
            enter="transition ease-in-out duration-500 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="z-20 fixed inset-0 backdrop-blur-sm"
          >
            <DelMethodMaodal />
          </Transition>

          {/* Delivery Address Modal */}
          <Transition
            show={deliveryAddressOpen}
            enter="transition ease-in-out duration-500 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="z-20 fixed inset-0 backdrop-blur-sm"
          >
            <AddressModal />
          </Transition>

          {/* Orders Modal */}
          <Transition
            show={ordersModalOpen}
            enter="transition ease-in-out duration-700 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="z-20 fixed inset-0 backdrop-blur-sm"
          >
            <OrdersPage />
          </Transition>

          {/* Tracking Modal Open */}
          <Transition
            show={trackingModalOpen}
            enter="transition ease-in-out duration-700 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="z-20 fixed inset-0 backdrop-blur-sm"
          >
            <TrackingModal />
          </Transition>

          {/* Invoice Modal Open */}
          <Transition
            show={invoiceModalOpen}
            enter="transition ease-in-out duration-700 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="z-30 fixed inset-0 backdrop-blur-sm overflow-auto "
          >
            <InvoiceModal />
          </Transition>

          {/* Tracking IMG Prev Modal Open */}
          <Transition
            show={trackingModalImgPreview}
            enter="transition ease-in-out duration-500 transform"
            enterFrom="-translate-x-full opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave="transition ease-in-out duration-500 transform"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="translate-x-full opacity-0"
            className="z-20 fixed inset-0 backdrop-blur-sm"
          >
            <TrackingOrdersModalPrevImage />
          </Transition>

          {/* Payment PayFast Modal */}
          <Transition
            show={salePayFastOpen}
            enter="transition ease-in-out duration-500 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="z-20 fixed inset-0 backdrop-blur-sm"
          >
            <PaymentPayFastModal />
          </Transition>

          {/* Payment PayShap Modal */}
          <Transition
            show={salePayShapOpen}
            enter="transition ease-in-out duration-500 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="z-20 fixed inset-0 backdrop-blur-sm"
          >
            <PaymentPayShapModal />
          </Transition>

          {/* Payment Yoco or Cash Modal */}
          <Transition
            show={saleYocoCashOpen}
            enter="transition ease-in-out duration-500 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="z-20 fixed inset-0 backdrop-blur-sm"
          >
            <PaymentsYocoCashModal />
          </Transition>

          {/* Sales Payment check login Modal */}
          <Transition
            show={salePaymentCheckLoginOpen}
            enter="transition ease-in-out duration-500 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="z-20 fixed inset-0 backdrop-blur-sm overflow-y-scroll"
          >
            <SalesPaymentCheckLoginModal />
          </Transition>
        </Router>
      </dir>
    </>
  );
}

export default App;
