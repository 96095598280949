import { useContext } from "react";
import { Link } from "react-router-dom";

// Context
import ModalContext from "../context/ModalContext";

export default function Footer() {
  // console.log("Render Footer");

  // Context
  const { setTsAndCsButton } = useContext(ModalContext);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {/* Footer */}
      <footer className=" bg-BNPgoldLightLight">
        <div className="flex justify-center w-100">
          <div className="flex justify-center w-[95%] h-1 border-b-2 border-BNPblue border-opacity-40"></div>
        </div>
        <div className="pt-4 pb-2 text-BNPblue font-semibold font-Montserrat BNP-Trans300">
          <div className="flex justify-between">
            {/* Pages */}
            <div className="flex-row min-w-[85px] md:w-[230px] ml-3 BNP-Trans300">
              {/* T's & C's */}

              <Link to={"/Ts&Cs"}>
                <p
                  onClick={() => setTsAndCsButton(true)}
                  className="hover:text-xl text-lg cursor-pointer BNP-Trans300"
                >
                  T's & C's
                </p>
              </Link>
              <Link to={"/Privacy-Policy"}>
                <p className="hover:text-xl text-lg cursor-pointer BNP-Trans300">
                  Privacy Policy
                </p>
              </Link>
            </div>
            <div className="flex justify-center w-100">
              <div className="flex-row">
                <p className="-mt-3 text-xl text-center mb-2">
                  We are registered with both these regulatory bodies
                </p>
                <div className="flex justify-between items-center -mt-3">
                  {/* SABS */}
                  <div className="flex h-[50px] md:h-[80px] w-[100px] md:w-[150px] ml-2 md:ml-10 BNP-Trans300">
                    <img src="../images/SABS-approved.png" alt="" />
                  </div>
                  {/* Gauteng Trafic Department */}
                  <div className="flex mr-2 md:mr-8 BNP-Trans300">
                    <img src="../images/GP_TD.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center min-w-fit md:w-[230px] BNP-Trans300">
              <img
                className="mb:h-28 md:w-28 h-20 w-20 BNP-Trans300"
                src="../images/BNPf.png"
                alt=""
                id="logo"
              />
            </div>
          </div>

          <div className="flex justify-center text-sm">
            Copyright &copy; {new Date().getFullYear()}
          </div>
        </div>
      </footer>
    </>
  );
}
