import { useContext, useEffect, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { Transition } from "@headlessui/react";

import "./SideBar.css";
import { IconContext } from "react-icons";
import { NavLink } from "react-router-dom";
import { SidebarData } from "./SideBarData";

// Img
import * as bsIcons from "react-icons/bs";
import * as tbIcons from "react-icons/tb";

// Context
import ModalContext from "../context/ModalContext";
import UserContext from "../context/UserContext";

// import Tooltip from 'rc-tooltip';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function SideBar() {
  // console.log("Render Header");

  // Check window size for any changes
  const size = useWindowSize();

  // Context
  const {
    sidebarSize,
    setSidebarSize,
    testing,
    setFAQ3show,
    setFAQbackShow,
    setUserCreateClick,
  } = useContext(ModalContext);

  const { userLogin, tokenTime, userLogOut } = useContext(UserContext);

  // State
  const [subnavOpen, setsubnavOpen] = useState(false);
  const [sidebarClick, setSidebarClick] = useState(false);
  const [sideBarText, setSideBarText] = useState(false);
  const [stickyH, setStickyH] = useState(false);

  const openNav = (menuName) => {
    if (menuName === "Legislation") {
      setsubnavOpen(!subnavOpen);
    }
  };

  const sideBar = () => {
    setSidebarSize(!sidebarSize);
    setSidebarClick(!sidebarClick);
  };

  useEffect(() => {
    setSideBarText(!sidebarSize);

    if (size.width < 1100) {
      if (sidebarClick === false) {
        setSidebarSize(true);
        setSideBarText(false);
      }
    } else {
      if (sidebarClick === false) {
        setSidebarSize(false);
        setSideBarText(true);
      }
    }

    // Remove sticky when height drops below 500
    if (size.height < 500) {
      setStickyH(true);
    } else {
      setStickyH(false);
    }
  }, [sidebarSize, sidebarClick, setSidebarSize, size]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <IconContext.Provider value={{ color: "" }}>
        <div
          className={
            stickyH
              ? "relative h-100 bg-BNPgoldDark opacity-90 top-0 left-0"
              : "min-h-svh h-full bg-BNPgoldDark opacity-90 sticky top-0 left-0"
          }
        >
          <nav
            className={
              sidebarSize
                ? "nav-menu small BNP-Trans300"
                : "nav-menu BNP-Trans300 sticky top-0 left-0"
            }
          >
            <ul className="w-[100%] pt-[140px]">
              {/* Window Sizes */}
              <div className="relative">
                <div
                  className={
                    testing
                      ? "absolute text-BNPblue w-20 -mt-[140px] left-0"
                      : "hidden"
                  }
                >
                  <div>W:{size.width}</div>
                  <div>H:{size.height}</div>
                </div>
                {/* Sidebare size arrow */}
                <div
                  className={
                    sidebarSize
                      ? "arrowShrink left-[39px] BNP-Trans300"
                      : "arrowShrink left-[190px] BNP-Trans300"
                  }
                >
                  <bsIcons.BsArrowRightShort
                    className={
                      sidebarSize
                        ? "rounded-full focus:outline-none BNP-Trans500 hover:font-semibold hover:border-2 hover:rounded-full hover:border-BNPblue hover:BNP-Trans300"
                        : "rounded-full focus:outline-none BNP-Trans500 hover:font-semibold hover:border-2 hover:rounded-full hover:border-BNPblue hover:BNP-Trans300 rotate-180"
                    }
                    onClick={sideBar}
                  />
                </div>
              </div>

              {/* Sidebar list */}
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className="">
                    <NavLink
                      to={item.path}
                      onClick={() => {
                        // Clear user login if not logged in
                        if (
                          userLogin === false &&
                          new Date().getTime() > tokenTime
                        ) {
                          userLogOut();
                        }
                        // User pwRest force.
                        openNav(item.title);
                        // close items in FAQ page
                        if (item.title === "FAQ") {
                          setFAQ3show(false);
                          setFAQbackShow(false);
                        }
                        setUserCreateClick(false);
                      }}
                      className={({ isActive }) => {
                        return (
                          "flex border-0 rounded-l-full ml-2 " +
                          (isActive
                            ? "bg-BNPgoldLightLight  before:absolute before:right-0 before:rounded-br-full before:shadow-BNP-br before:bg-BNPgoldDark before:w-4 before:h-4 before:-mt-4  after:absolute after:right-0 after:rounded-tr-full after:shadow-BNP-tr after:bg-BNPgoldDark after:w-4 after:h-4 after:mt-[48px]"
                            : // after:transition-all after:duration-500 transition-all duration-500 before:transition-all before:duration-500
                              "")
                        );
                      }}
                    >
                      {/* Whole navbar */}
                      <div className="relative flex justify-between items-center">
                        <div className="flex py-1">
                          <div>
                            <div className="flex items-center justify-start nav-text">
                              {/* Icons */}
                              <div className="scale-[1.4] z-10">
                                {item.icon}
                              </div>
                              {/* Navbar Text */}
                              <Transition
                                show={sideBarText}
                                enter="transition ease-in-out duration-500 transform"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition ease-in-out duration-500 transform"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                className="ml-4 "
                              >
                                {item.title}
                              </Transition>
                            </div>
                          </div>
                        </div>

                        {/* Navbar open Close */}
                        <div
                          onClick={() => setsubnavOpen(!subnavOpen)}
                          className={
                            subnavOpen
                              ? "rotate-180 text-xl absolute right-2 text-BNPblue transition-all duration-300"
                              : " text-xl absolute right-2 text-BNPblue transition-all duration-300"
                          }
                        >
                          {item.subnav && !sidebarSize && item.iconClosed}
                        </div>
                      </div>
                    </NavLink>
                    {/* SubMenu items */}
                    <div className="duration-900">
                      {item.subnav && subnavOpen && !sidebarSize && (
                        <ul>
                          {item.subnavItems.map((subnavItems, i) => (
                            <NavLink to={subnavItems.path}>
                              <li
                                className="nav-textSub flex items-center"
                                key={i}
                              >
                                <tbIcons.TbPointFilled className="mr-2" />
                                {subnavItems.title}
                              </li>
                            </NavLink>
                          ))}
                        </ul>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </nav>{" "}
        </div>
      </IconContext.Provider>
    </>
  );
}
