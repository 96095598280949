import React, { useRef, useContext, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import { Transition } from "@headlessui/react";
import md5 from "md5";
import axios from "axios";

// Context
import modalContext from "../context/ModalContext";
import UrlContext from "../context/URLContext";
import userContext from "../context/UserContext";
import AdminContext from "../context/AdminContext";

import * as aiIcons from "react-icons/ai";
import * as biIcons from "react-icons/bi";
import * as ioIcons from "react-icons/io";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function Login() {
  // console.log("Render LoginPage");

  // Check window size for any changes
  const size = useWindowSize();

  // Navigate
  const nav = useNavigate();
  let location = useLocation();

  // Context
  const { userLogin, userAddress, userLoginTracking } = useContext(UrlContext);
  const {
    testing,
    setPwRestLock,
    msgClean,
    setloginModal,
    currentPage,
    setMsgOpen,
    setMsgTxt,
    setMsgType,
  } = useContext(modalContext);
  const { setSalesVol, setCallBackVol } = useContext(AdminContext);

  const {
    cookie,
    userEmail,
    setuserEmail,
    setUserLogin,
    setUserID,
    setIDno,
    setUserAdmin,
    setBusinessUser,
    setPrefName,
    setUserPassword,
    updateAddressContext,
    saveLocalCust,
    setAccessToken,
    setRefreshToken,
    setTokenTime,
  } = useContext(userContext);

  // State
  const [userPsw, setuserPsw] = useState("");
  const [ClearText, setClearText] = useState(false);
  const [modalSize, setModalSize] = useState("");
  const [userError, setUserError] = useState(false);
  const eMailAddress = useRef();
  const pswRef = useRef();
  const [btnWait, setBtnWait] = useState(false);

  const effectRunOnce = useRef(false);

  const [pwType, setPwType] = useState("password");

  // variables
  let loadNewToken = "";

  const modalCaseSizes = {
    S1: "scale-100 BNP-Trans500",
    S2: "scale-75 BNP-Trans500",
  };

  // Screen Size effect
  useEffect(() => {
    if (userEmail.length === 0 && testing === 1) {
      setuserEmail("liam@gmail.com"); // For Testing
      setuserPsw("123");
      setPwType("text");
    }

    if (size.width >= 900) {
      setModalSize("S1");
    } else {
      setModalSize("S2");
    }

    if (userPsw?.length === 0 && userEmail?.length === 0) {
      setClearText(false);
    } else {
      setClearText(true);
      setUserError(false);
    }

    // set focus to email address
    if (effectRunOnce.current === false) {
      eMailAddress.current.focus();
      effectRunOnce.current = true;
    }
  }, [size.width, userPsw, userEmail, setuserEmail, testing]);

  const closeOutSide = (e) => {
    // if (e.target.id === "LoginModal") closeClick();
  };

  const closeClick = () => {
    setloginModal(false);
    clearText();
  };

  // Input Field handler
  const handleUserEmail = (e) => {
    setuserEmail(e.target.value);
  };

  //
  const handleUserPsw = (e) => {
    setuserPsw(e.target.value);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      // console.log('run click');
      loginSubmit();
    }
    if (e.key === "Escape") {
      closeClick();
    }    
  };

  // Reset Input Field handler
  const clearText = () => {
    setuserEmail("");
    setuserPsw("");
    setClearText(false);
    setUserError(false);
    eMailAddress.current.focus();
  };

  // Login and get User Context data
  const loginSubmit = async () => {
    let error = 0;

    setBtnWait(true);
    if (userEmail.length > 1 && userPsw.length > 0) {
      // Get email hash
      let y = md5(userEmail + process.env.REACT_APP_UCNPASSPHRASE);

      // request data from mySQL db
      let url = userLogin + userEmail;
      // console.log(url);
      const data = await axios
        .get(url, { params: { z: userPsw, y: y } })
        .then((res) => res)
        .catch((err) => {
          msgClean();
          setMsgTxt(
            "Network error!  Please check your Internet connection and try again."
          );
          error = 1;
          setMsgType("Error");
          setMsgOpen(true);
        });
      // console.log(data);

      // Validate data returned from Server!
      try {
        let newData = data.data.sqlData[0];
        // console.log(newData);
        // Update auth Tokens
        setAccessToken(data.data.accessToken);
        setRefreshToken(data.data.refreshToken);
        setTokenTime(new Date().getTime() + 300000);
        loadNewToken = data.data.accessToken;
        // console.log(data.data.accessToken);
        // console.log(data.data.refreshToken);
        if (error === 0) {
          if (newData.length === 0) {
            setUserLogin(false);
            setUserError(true);
            eMailAddress.current.focus();
          } else {
            // Check if password match hash

            if (data.data !== "Login error") {
              let admin = newData[0].admin;

              setUserID(newData[0].ucn);
              setIDno(newData[0].IDno);
              setUserLogin(true);
              setUserPassword(newData[0].Password);
              // console.log(hash);
              setPrefName(newData[0].Prefered_Name); // Change name here
              loadAddressinfo(newData[0].ucn);
              setBusinessUser(newData[0].busClient);

              if (admin === 1) {
                setUserAdmin(true);
                setSalesVol(newData[0].adminVol);
                setCallBackVol(newData[0].CallBackVol);
              } else {
                setUserAdmin(false);
              }

              if (location.pathname === "/LoggedOut") {
                nav(currentPage);
              }

              if (location.pathname === "/Register") {
                nav("/");
              }

              // console.log("cookie")
              // console.log(cookie)
              if (cookie) saveLocalCust(true, newData[0].ucn);

              closeClick();
              // User login tracking
              try {
                LoginTracking(newData[0].ucn);
              } catch (error) {}
            } else {
              setUserLogin(false);
              setUserError(true);
              eMailAddress.current.focus();
            }

            if (newData[0].pwReset > 0) {
              setPwRestLock(true);
              nav("/PasswordChange");
              msgClean();
              setMsgTxt("Please change your PASSWORD now!");
              setMsgType("Warning");
              setMsgOpen(true);
            }
          }
        } else {
          setloginModal(false);
        }
      } catch (error) {}
    } else if (userEmail.length === 0) {
      eMailAddress.current.focus();
    } else if (userPsw.length === 0) {
      pswRef.current.focus();
    }
    setBtnWait(false);
  };

  const LoginTracking = async (ucn) => {
    // console.log(loadNewToken);
    // Update payment received to db.
    let url = userLoginTracking;
    // console.log(url);
    const data = await axios
      .post(
        url,
        {
          track_ucn: ucn,
        },
        { headers: { Authorization: loadNewToken } } // JWS Token
      )
      .then((res) => res)
      .catch((err) => {
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });
    // console.log(data);

    try {
      if (data.status === 200) {
        if (data.data.affectedRows > 0) {
          // console.log("User Tracking info updated.");
        }
      }
    } catch (error) {
      // console.log("User Tracking info NOT updated.");
    }
  };

  // Load address info
  const loadAddressinfo = async (i) => {
    if (i !== "") {
      // request data from mySQL db
      let url = userAddress + i;
      // console.log(url)
      const data = await axios
        .get(
          url,
          { headers: { Authorization: loadNewToken } } // JWS Token
        )
        .then((res) => res.data[0])
        .catch((err) => {
          msgClean();
          setMsgTxt(
            "Network error!  Please check your Internet connection and try again."
          );
          setMsgType("Error");
          setMsgOpen(true);
        });
      // console.log(data);
      if (data) updateAddressContext(data);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div
        onClick={closeOutSide}
        className={`${modalCaseSizes[modalSize]} ...`}
      >
        <div
          id="LoginModal"
          className="mr-5 mt-0 flex t-0 h-full justify-end font-Montserrat"
        >
          <div className="w-auto h-[500px] md:-translate-x-24 md:mt-32 mt-16 BNP-Modal-shape BNP-Trans300 bg-BNPblue rounded-md">
            <div className="flex-row h-full items-center justify-center w-[540px]">
              <div className="p-5  text-BNPgoldLight">
                <div className="pt-1 pl-1">
                  <aiIcons.AiOutlineClose
                    className="rounded-full focus:outline-none transition-all duration-500 scale-[2.2] hover:font-semibold hover:scale-[2.9] hover:rotate-90
                    hover:border-2 hover:rounded-full hover:border-BNPgoldLight hover:transition-all hover:duration-700"
                    onClick={closeClick}
                  />
                </div>
                {/* Header */}
                <h1 className="flex justify-center text-6xl font-bold text-BNPgoldLight">
                  Login
                </h1>
              </div>

              {/* Body */}
              <div className="bg-white rounded-b-md">
                <div className=" bg-BNPgoldLightLight p-5 ">
                  <div className="flex-row items-center justify-between pt-2">
                    <p className="flex justify-center text-xl">
                      Please enter your login details:
                    </p>

                    <div className="flex justify-center items-baseline">
                      <p className="p-2 text-xl">Don't have an account yet?</p>
                      <Link
                        to={"/Register"}
                        onClick={() => {
                          closeClick();
                        }}
                      >
                        <div className="text-xl BNP-Links">Register</div>
                      </Link>
                    </div>
                  </div>

                  <form className="relative mt-8 text-xl">
                    {/* User info */}
                    <div className="flex items-center">
                      <biIcons.BiUserCircle className="ml-2 opacity-80" />
                      <div className="pl-2">
                        <input
                          id="loginEmail"
                          type="text"
                          ref={eMailAddress}
                          placeholder="Email address"
                          value={userEmail}
                          onChange={handleUserEmail}
                          onKeyDown={handleEnter}
                          autoComplete="off"
                          className="-mx-9 w-[500px] pl-11 BNP-Input"
                        />
                      </div>
                    </div>

                    {/* Password */}
                    <div className="flex items-center mt-4">
                      <ioIcons.IoIosKey className="ml-2 opacity-80 " />
                      <div className="pl-2">
                        <input
                          id="password"
                          ref={pswRef}
                          type={pwType} // password or text
                          placeholder="Password"
                          value={userPsw}
                          onChange={handleUserPsw}
                          onKeyDown={handleEnter}
                          className="-mx-9 w-[500px] pl-11 BNP-Input"
                        />
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <Link to={"/PasswordRecover"} onClick={closeClick}>
                        <p className="w-[200px] BNP-Links">Forgot Password</p>
                      </Link>

                      <Transition
                        show={ClearText}
                        enter="transition ease-in-out duration-500 transform"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in-out duration-500 transform"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <p onClick={clearText} className="w-[70px] BNP-Links">
                          clear
                        </p>
                      </Transition>
                    </div>
                    <div className="absolute flex justify-center top-0 w-100 -mt-8">
                      <Transition
                        show={userError}
                        enter="transition ease-in-out duration-700 transform"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in-out duration-700 transform"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="font-semibold text-red-600">
                          Invalid Email address or Password entered!
                        </div>
                      </Transition>
                    </div>
                  </form>
                  <div className="flex justify-end">
                    <button
                      onClick={loginSubmit}
                      className={
                        btnWait
                          ? "mt-6 w-[150px] BNP-SubmitButtonsWait"
                          : "mt-6 w-[150px] BNP-SubmitButtons"
                      }
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
