import axios from "axios";

export default async function UserAccessToken(url, t) {
  // console.log(url)
  // console.log(t)
  //   Test access token
  const data = await axios
    .get(url, { headers: { Authorization: t } })
    .then((res) => res.data)
    .catch((err) => {});
  return data;
}
