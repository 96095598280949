import KZNdata from "../data/Lookups/PlateCodesKZN.json";
import WPdata from "../data/Lookups/PlateCodesWP.json";
import plateData from "../data/Lookups/plates.json";

// Functions
import RemoveFunnies from "../utilities/fromatRemoveFunnies";
import isNumeric from "./isNumeric";

export default function ValidateRegistrationNo(str, prov, psize) {
  // Remove junk and funnie characters.
  if (!str) return str;
  const RegNo = RemoveFunnies(str);
  let RegNoFinal = RegNo;

  ///////////////////////////////////////////////////////////////////////////////////
  let provClean = str;
  let matchType = "Fail";
  let provMach = "Fail";
  let provCheck = 0;
  let plateFormat = 0;
  let pLength = 9;

  // Build isnumeric testing for KZN and CA

  let a1 = false;
  let a2 = false;
  let a3 = false;
  let a4 = false;
  let a5 = false;
  let a6 = false;
  let a7 = false;
  let a8 = false;
  let a9 = false;

  if (
    isNumeric(RegNoFinal.substring(0, 1)) === false &&
    RegNoFinal.substring(0, 1).length === 1
  ) {
    a1 = 1;
  } else {
    a1 = 0;
  }
  if (
    isNumeric(RegNoFinal.substring(1, 2)) === false &&
    RegNoFinal.substring(1, 2).length === 1
  ) {
    a2 = 1;
  } else {
    a2 = 0;
  }
  if (
    isNumeric(RegNoFinal.substring(2, 3)) === false &&
    RegNoFinal.substring(2, 3).length === 1
  ) {
    a3 = 1;
  } else {
    a3 = 0;
  }
  if (
    isNumeric(RegNoFinal.substring(3, 4)) === false &&
    RegNoFinal.substring(3, 4).length === 1
  ) {
    a4 = 1;
  } else {
    a4 = 0;
  }
  if (
    isNumeric(RegNoFinal.substring(4, 5)) === false &&
    RegNoFinal.substring(4, 5).length === 1
  ) {
    a5 = 1;
  } else {
    a5 = 0;
  }
  if (
    isNumeric(RegNoFinal.substring(5, 6)) === false &&
    RegNoFinal.substring(5, 6).length === 1
  ) {
    a6 = 1;
  } else {
    a6 = 0;
  }
  if (
    isNumeric(RegNoFinal.substring(6, 7)) === false &&
    RegNoFinal.substring(6, 7).length === 1
  ) {
    a7 = 1;
  } else {
    a7 = 0;
  }
  if (
    isNumeric(RegNoFinal.substring(7, 8)) === false &&
    RegNoFinal.substring(7, 8).length === 1
  ) {
    a8 = 1;
  } else {
    a8 = 0;
  }
  if (
    isNumeric(RegNoFinal.substring(8, 9)) === false &&
    RegNoFinal.substring(8, 9).length === 1
  ) {
    a9 = 1;
  } else {
    a9 = 0;
  }

  // Check Province for the Arrays
  if (
    // 1 - Filter by input KZN 3
    KZNdata.filter((data) => {
      return (
        data.cde.toLowerCase() === RegNoFinal.substring(0, 3).toLowerCase()
      );
    }).length > 0 &&
    prov === "ZN"
  ) {
    // console.log("KZN-3")
    // check if next caracter is a number
    if (a4 + a5 + a6 + a7 + a8 + a9 === 0) {
      provMach = "Matched 3 - KZN";
      matchType = "KZN-3";
      provClean = "ZN";
    }
    ///////////////////
  } else if (
    // 2 - Filter by input WP 3
    WPdata.filter((data) => {
      return (
        data.cde.toLowerCase() === RegNoFinal.substring(0, 3).toLowerCase()
      );
    }).length > 0 &&
    prov === "WP"
  ) {
    // console.log("WP-3")
    // check if next caracter is a number
    if (a4 + a5 + a6 + a7 + a8 + a9 === 0) {
      provMach = "Matched 3 - WP";
      matchType = "WP-3";
      provClean = "WP";
    }
    ///////////////////
  } else if (
    // 3 - Filter by input KZN 2
    KZNdata.filter((data) => {
      return (
        data.cde.toLowerCase() === RegNoFinal.substring(0, 2).toLowerCase()
      );
    }).length > 0 &&
    prov === "ZN"
  ) {
    // console.log("KZN-2")
    // check if next caracter is a number
    if (a3 + a4 + a5 + a6 + a7 + a8 + a9 === 0) {
      provMach = "Matched 2 - KZN";
      matchType = "KZN-2";
      provClean = "ZN";
    }
    ///////////////////
  } else if (
    // 4 - Filter by input WP 2
    WPdata.filter((data) => {
      return (
        data.cde.toLowerCase() === RegNoFinal.substring(0, 2).toLowerCase()
      );
    }).length > 0 &&
    prov === "WP"
  ) {
    // console.log("WP-2");
    // check if next caracter is a number
    if (a3 + a4 + a5 + a6 + a7 + a8 + a9 === 0) {
      provMach = "Matched 2 - WP";
      matchType = "WP-2";
      provClean = "WP";
    }
    ///////////////////
  } else if (
    // 5 - Filter by GP, NC, WP, EC, ZN, MP, FS, NW
    RegNoFinal.substring(RegNoFinal.length - 2) === "GP" ||
    RegNoFinal.substring(RegNoFinal.length - 2) === "NC" ||
    RegNoFinal.substring(RegNoFinal.length - 2) === "WP" ||
    RegNoFinal.substring(RegNoFinal.length - 2) === "EC" ||
    RegNoFinal.substring(RegNoFinal.length - 2) === "ZN" ||
    RegNoFinal.substring(RegNoFinal.length - 2) === "MP" ||
    RegNoFinal.substring(RegNoFinal.length - 2) === "FS" ||
    RegNoFinal.substring(RegNoFinal.length - 2) === "NW"
  ) {
    // console.log("Oth");
    provClean = RegNoFinal.substring(RegNoFinal.length - 2);
  } else if (
    // 6 - Filter by Limpopo
    RegNoFinal.substring(RegNoFinal.length - 1) === "L"
  ) {
    // console.log("l-1");
    provClean = RegNoFinal.substring(RegNoFinal.length - 1);
  }

  // Check if Prov and ProvClean match
  if (prov === "GP" && provClean === "GP") {
    provCheck = 1;
  }
  if (prov === "NC" && provClean === "NC") {
    provCheck = 1;
  }
  if (prov === "WP" && provClean === "WP") {
    provCheck = 1;
  }
  if (prov === "EC" && provClean === "EC") {
    provCheck = 1;
  }
  if (prov === "ZN" && provClean === "ZN") {
    provCheck = 1;
  }
  if (prov === "MP" && provClean === "MP") {
    provCheck = 1;
  }
  if (prov === "FS" && provClean === "FS") {
    provCheck = 1;
  }
  if (prov === "NW" && provClean === "NW") {
    provCheck = 1;
  }
  if (prov === "L" && provClean === "L") {
    provCheck = 1;
  }

  // Build Array
  const NewplateData = plateData.filter((data) => {
    return data.prov.toLowerCase() === provClean.toLowerCase();
  });

  // land Plate formats
  try {
    plateFormat = NewplateData[0].pFormat;
    if (
      matchType !== "KZN-3" &&
      matchType !== "KZN-2" &&
      matchType !== "WP-3" &&
      matchType !== "WP-2"
    ) {
      pLength = NewplateData[0].mxLength;
    } else {
      pLength = 8;
    }
  } catch (error) {}

  // set max Lengths
  RegNoFinal = RegNoFinal.substring(0, pLength);

  // Filter by Limpopo province
  if (provCheck !== 0 && provClean === "L" && matchType === "Fail") {
    // console.log("1");
    if (
      plateFormat === "old" &&
      a1 + a2 + a3 === 3 &&
      isNumeric(RegNoFinal.substring(3, 6)) === true &&
      NewplateData.length > 0
    ) {
      matchType = "L-SO"; // Standard old plate - ABC123L
    } else {
      matchType = "L-P";
    } // Personalized plate
    provMach = "Matched - " + provClean;
  }

  // check if next caracter is a number
  else if (provCheck !== 0 && provClean !== "L" && matchType === "Fail") {
    // console.log("2");
    if (
      NewplateData.length > 0 &&
      plateFormat === "new" &&
      RegNoFinal.length === 8 &&
      a1 + a2 === 2 &&
      a3 + a4 === 0 &&
      a5 + a6 === 2
    ) {
      matchType = "OT-SN"; // Standard new plate - BX30XBGP
      if (provClean === "GP") {
        pLength = 8;
      }
    } else if (// Standard old plate - ABC123NW
      (NewplateData.length > 0 &&
        plateFormat === "old" &&
        a1 + a2 + a3 === 3 &&
        a4 + a5 + a6 === 0) ||
      (NewplateData.length > 0 &&
        plateFormat === "new" &&
        RegNoFinal.length === 8 &&
        provClean === "GP" &&
        a1 + a2 + a3 === 3 &&
        a4 + a5 + a6 === 0)
    ) {
      matchType = "OT-SO"; 
      if (provClean === "GP") {
        pLength = 8;
      }
    } else {// Personalized plate
      matchType = "OT-P";
    } 
    provMach = "Matched - " + provClean;
  }
  /////////////////////////////////////////////////////////

  // rubish comments
  if (prov === "aaaaa") {
    console.log(provCheck);
    console.log(provMach);
    console.log(matchType);
    console.log(plateFormat);
    console.log(RegNoFinal.substring(0, 3));
    console.log(RegNoFinal.substring(3, 6));
    console.log(RegNoFinal.length);
  }

  // console.log(RegNoFinal.substring(0, 2))
  // console.log(RegNoFinal.substring(2,4))
  // console.log(RegNoFinal.substring(4, 6))
  // console.log(isNumeric(RegNoFinal))
  // console.log(matchType);

  if (psize === "250" && RegNoFinal.length > 8) matchType = "Fail";

  if (matchType === "Fail") {
    return matchType;
  } else {
    return matchType + ":" + pLength;
  }
}
// AB CD EF G HI
// 01 23 45 6 78
// 12 34 56 7 89
