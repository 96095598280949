import React, { useRef, useState, useContext, useEffect } from "react";
import { Transition } from "@headlessui/react";
import validSouthAfricanId from "valid-south-african-id";

// formating
import formatPhoneNumber from "../utilities/formatPhoneNumber";
import formatTextNumber from "../utilities/formatTextNumber";
import formatProperCase from "../utilities/formatProperCase";
import formatProperCompany from "../utilities/formatProperCompany";
import fromatRemoveFunnies from "../utilities/fromatRemoveFunnies";

// pages
import DropIDType from "./LookupIDPassport";

// Context
import userContext from "../context/UserContext";
import ModalContext from "../context/ModalContext";

// React Icons
import * as riIcons from "react-icons/ri";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function UserInfoPage() {
  // console.log("Render UserInfoPage");

  // Context
  const {
    newUser,
    setNewUser,

    BusCheck,
    setBusCheck,
    BusName,
    setBusName,
    RecipientName,
    setRecipientName,
    CellNo,
    setCellNo,
    IdType,
  } = useContext(userContext);

  const { userCreateClick, setIDTypeInValid, setUserInfoCompleted } =
    useContext(ModalContext);

  // State
  const IndRadioRef = useRef();
  const BusRadioRef = useRef();
  const [BusinessFlag, setBusinessFlag] = useState("IndRadio");

  const BusRef = useRef();
  const [BusMove, setBusNameMove] = useState(false);
  const [VAT_No, setVAT_No] = useState("");
  const vatRef = useRef();
  const [vatMove, setVATMove] = useState(false);
  const [FistName, setFistName] = useState("");
  const fistNameRef = useRef();
  const [fistNameMove, setFistNameMove] = useState(false);
  const [prefName, setPrefName] = useState("");
  const prefNameRef = useRef();
  const [prefNameMove, setPrefNameMove] = useState(false);
  const [Surname, setSurname] = useState("");
  const SurnameRef = useRef();
  const [SurnameMove, setSurnameMove] = useState(false);
  const [IDNo, setIDNo] = useState("");
  const IDNoRef = useRef();
  const [IDNoMove, setIDNoMove] = useState(false);
  const [idNoValid, setIDnoValid] = useState(false);
  const CellNoRef = useRef();
  const [CellNoMove, setCellNoMove] = useState(false);
  const [Tel, setTel] = useState("");
  const TelRef = useRef();
  const [TelMove, setTelMove] = useState(false);

  const [busNameFlag, setBusNameFlag] = useState(false);
  const [nameFlag, setNameFlag] = useState(false);
  const [surnameFlag, setSurnameFlag] = useState(false);
  const [idPassportFlag, setIdPassportFlag] = useState(false);
  const [cellFlag, setCellFlag] = useState(false);

  const effectRanOnce = useRef(false);

  const [BusFocus, setBusFocus] = useState(false);
  const [busLoop, setBusLoop] = useState(0);

  useEffect(() => {
    let CheckCnt = 0;

    if (BusName.length === 0 && BusCheck === true && userCreateClick === true) {
      CheckCnt = CheckCnt + 1;
      setBusNameFlag(true);
    } else {
      setBusNameFlag(false);
    }

    if (FistName.length === 0 && userCreateClick === true) {
      CheckCnt = CheckCnt + 1;
      setNameFlag(true);
    } else {
      setNameFlag(false);
    }

    if (Surname.length === 0 && userCreateClick === true) {
      CheckCnt = CheckCnt + 1;
      setSurnameFlag(true);
    } else {
      setSurnameFlag(false);
    }

    if (IDNo.length === 0 && userCreateClick === true) {
      CheckCnt = CheckCnt + 1;
      setIdPassportFlag(true);
    } else {
      setIdPassportFlag(false);
    }

    if (CellNo.length === 0 && userCreateClick === true) {
      CheckCnt = CheckCnt + 1;
      setCellFlag(true);
    } else {
      setCellFlag(false);
    }

    if (IdType === "" && userCreateClick === true) {
      CheckCnt = CheckCnt + 1;
      setIDTypeInValid(true);
    } else {
      setIDTypeInValid(false);
    }

    // Flag missing
    if (CheckCnt === 0) {
      setUserInfoCompleted(true);
    } else {
      setUserInfoCompleted(false);
    }
    // console.log(CheckCnt);
  }, [
    BusCheck,
    CellNo.length,
    FistName.length,
    IDNo.length,
    Surname.length,
    BusName.length,
    setUserInfoCompleted,
    userCreateClick,
    IdType,
    setIDTypeInValid,
  ]);

  useEffect(() => {
    if (BusFocus === true) {
      try {
        if (BusCheck === true) {
          setBusinessFlag("BusRadio");
          BusRef.current.tabIndex = 0;
          vatRef.current.tabIndex = 0;
          BusRef.current.focus();
          setBusFocus(false);
          setBusLoop(0);
        } else {
          setBusinessFlag("IndRadio");
          BusRef.current.tabIndex = -1;
          vatRef.current.tabIndex = -1;
          setBusName("");
          setVAT_No("");
        }
      } catch (error) {
        setBusLoop(busLoop + 1);
      }
    }

    if (BusName.length !== 0) {
      setBusNameMove(true);
    }
    if (VAT_No.length !== 0) {
      setVATMove(true);
    }
    if (FistName.length !== 0) {
      setFistNameMove(true);
    }
    if (prefName.length !== 0) {
      setPrefNameMove(true);
    }
    if (Surname.length !== 0) {
      setSurnameMove(true);
    }
    if (IDNo.length !== 0) {
      setIDNoMove(true);
    }
    if (CellNo.length !== 0) {
      setCellNoMove(true);
    }
    if (Tel.length !== 0) {
      setTelMove(true);
    }


    // Check valid ID number
    if (IdType === "SA ID") {
      if (IDNo.length > 0 && validSouthAfricanId(IDNo) === false) {
        setIDnoValid(true);
      } else {
        setIDnoValid(false);
      }
    } else {
      setIDnoValid(false);
    }


    // Refresh already captured info it clicked away
    if (effectRanOnce.current === false) {
      IndRadioRef.current.tabIndex = -1;
      BusRadioRef.current.tabIndex = -1;

      if (newUser.find((data) => data.Business_Name !== "abc")) {
        setBusName(newUser[0].Business_Name);
      }
      if (newUser.find((data) => data.VAT_Number !== "abc")) {
        setVAT_No(newUser[0].VAT_Number);
      }
      if (newUser.find((data) => data.Name !== "abc")) {
        setFistName(newUser[0].Name);
      }
      if (newUser.find((data) => data.Prefered_Name !== "abc")) {
        setPrefName(newUser[0].Prefered_Name);
      }
      if (newUser.find((data) => data.Surname !== "abc")) {
        setSurname(newUser[0].Surname);
      }
      if (newUser.find((data) => data.ID_No !== "abc")) {
        setIDNo(newUser[0].ID_No);
      }
      if (newUser.find((data) => data.Cell_No !== "abc")) {
        setCellNo(newUser[0].Cell_No);
      }
      if (newUser.find((data) => data.Telephone !== "abc")) {
        setTel(newUser[0].Telephone);
      }
      effectRanOnce.current = true;
      // console.log("Ran User data refresh once only");
    }
  }, [
    newUser,
    BusCheck,
    BusName,
    VAT_No,
    FistName,
    prefName,
    Surname,
    IDNo,
    CellNo,
    Tel,
    setBusName,
    setCellNo,
    BusFocus,
    busLoop,
    IdType,
  ]);

  // Radio Button Changes
  const busClick = () => {
    if (BusinessFlag === "IndRadio") {
      setBusCheck(false);
    } else {
      setBusCheck(true);
    }
    if (1 === 2) {
      console.log(RecipientName);
    }
  };

  const UpdateRecipient = () => {
    setRecipientName(prefName + " " + Surname);
  };

  // Bussines Name
  const BusNameFocusOn = () => {
    setBusNameMove(true);
    BusRef.current.focus();
  };

  const BusNameFocusOff = () => {
    if (BusName.length !== 0) {
      setBusNameMove(true);
    } else {
      setBusNameMove(false);
    }
  };

  // Update Array with Business Name
  function ArrayUpdateBN(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Business_Name: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // Business VATNo
  const vatFocusOn = () => {
    setVATMove(true);
    vatRef.current.focus();
  };

  const vatFocusOff = () => {
    if (VAT_No.length !== 0) {
      setVATMove(true);
    } else {
      setVATMove(false);
    }
  };

  // Update Array with VATNumber
  function ArrayUpdateVAT(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            VAT_Number: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // FistNameprefName
  const fistNameFocusOn = () => {
    setFistNameMove(true);
    fistNameRef.current.focus();
  };

  const fistNameFocusOff = () => {
    if (FistName.length !== 0) {
      setFistNameMove(true);
    } else {
      setFistNameMove(false);
    }
  };

  // Update Array with Name
  function ArrayUpdateName(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Name: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // PrefName
  const prefNameFocusOn = () => {
    setPrefNameMove(true);
    prefNameRef.current.focus();
  };

  const prefNameFocusOff = () => {
    if (prefName.length !== 0) {
      setPrefNameMove(true);
    } else {
      setPrefNameMove(false);
    }
  };

  // Update Array with Prefered_Name
  function ArrayUpdatePreferedName(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Prefered_Name: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // Surname
  const SurnameFocusOn = () => {
    setSurnameMove(true);
    SurnameRef.current.focus();
  };

  const SurnameFocusOff = () => {
    if (Surname.length !== 0) {
      setSurnameMove(true);
    } else {
      setSurnameMove(false);
    }
  };

  // Update Array with Surname
  function ArrayUpdateSurname(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Surname: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // IDNo
  const IDNoFocusOn = () => {
    setIDNoMove(true);
    IDNoRef.current.focus();
  };

  const IDNoFocusOff = () => {
    if (IDNo.length !== 0) {
      setIDNoMove(true);
    } else {
      setIDNoMove(false);
    }
  };

  // Update Array with ID_No
  function ArrayUpdateIDNo(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            ID_No: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // CellNo
  const CellNoFocusOn = () => {
    setCellNoMove(true);
    CellNoRef.current.focus();
  };

  const CellNoFocusOff = () => {
    if (CellNo.length !== 0) {
      setCellNoMove(true);
    } else {
      setCellNoMove(false);
    }
  };

  // Update Array with Cell_No
  function ArrayUpdateCell_No(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Cell_No: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  // TelNo
  const TelFocusOn = () => {
    setTelMove(true);
    TelRef.current.focus();
  };

  const TelFocusOff = () => {
    if (Tel.length !== 0) {
      setTelMove(true);
    } else {
      setTelMove(false);
    }
  };

  // Update Array with Telephone
  function ArrayUpdateTelephone(value) {
    setNewUser(
      newUser.map((items) => {
        if (items.ucn === "1") {
          return {
            ...items,
            Telephone: value,
          };
        } else {
          // No change
          return items;
        }
      })
    );
  }

  console.log(validSouthAfricanId(IDNo))

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {/* User Info Section */}
      {/* Container */}
      <div className="p-2 flex-row font-Montserrat md:p-4 text-BNPblue BNP-Trans300">
        <div className="flex w-full items-center">
          {/* Header */}
          <h1 className="pl-4 text-lg font-bold mr-2 md:mr-14 BNP-Trans300">
            Personal Details
          </h1>

          {/* Radio buttons */}
          <div className="flex items-center justify-between w-72 md:w-80 BNP-Trans300">
            {/* IndRadio */}
            <div
              onClick={() => {
                setBusinessFlag("IndRadio");
                setBusCheck(false);
                fistNameRef.current.focus();
              }}
              className="flex justify-center items-center group hover:cursor-pointer hover:font-bold hover:text-blue-800 BNP-Trans300"
            >
              <input
                type="radio"
                name="typebtn"
                ref={IndRadioRef}
                value={BusinessFlag}
                checked={BusinessFlag === "IndRadio"}
                onChange={() => busClick}
                className="scale-150 group-hover:cursor-pointer "
              />
              <h2 className="p-1 text-lg pl-4 group-hover:font-bold group-hover:text-blue-800 BNP-Trans300">
                Indavidual
              </h2>
            </div>

            {/* BusRadio */}
            <div
              onClick={() => {
                setBusinessFlag("BusRadio");
                setBusCheck(true);
                setBusFocus(true);
              }}
              className="flex justify-center items-center group w-[130px] h-[40px] hover:cursor-pointer hover:font-bold hover:text-blue-800 BNP-Trans300"
            >
              <input
                type="radio"
                name="typebtn"
                ref={BusRadioRef}
                value={BusinessFlag}
                checked={BusinessFlag === "BusRadio"}
                onChange={() => busClick}
                className="scale-150 group-hover:cursor-pointer"
              />
              <h2 className="w-[110px] p-1 text-lg pl-4 group-hover:font-bold group-hover:text-blue-800 hover:cursor-pointer BNP-Trans300">
                Business
              </h2>
            </div>
          </div>
        </div>

        {/* Business Container */}
        <div
          className={
            BusCheck
              ? "transition-height duration-500 ease-in-out h-[60px]" // Show
              : "transition-height duration-500 ease-in-out h-0 opacity-0" // Hidden
          }
        >
          {/* Business name and VAT Number */}
          <Transition
            show={BusCheck}
            enter="transition ease-in-out duration-500 transform"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-700 transform"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="flex flex-wrap items-center justify-start p-1">
              {/* Business Name */}
              <div className="flex justify-center">
                <div className="flex-row justify-center mt-[5px] h-[50px] text-md">
                  <div
                    onClick={() => BusNameFocusOn()}
                    className="group w-[440px] h-[40px] mr-[30px] BNP-LableDIV cursor-text"
                  >
                    <input
                      value={BusName}
                      onChange={(e) => {
                        setBusName(formatProperCompany(e.target.value));
                        ArrayUpdateBN(e.target.value);
                      }}
                      onBlur={() => BusNameFocusOff()}
                      type="text"
                      ref={BusRef}
                      autoComplete="off"
                      className={
                        busNameFlag
                          ? "w-[440px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                          : "w-[440px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                      }
                    />
                    <h4
                      className={
                        BusMove
                          ? "w-[104px] BNP-LableMove -translate-y-[50px]"
                          : "w-[150px] BNP-LableStart mt-[3px]"
                      }
                    >
                      <div className="flex">
                        Business Name
                        <div className={BusMove ? "hidden" : "pl-1"}>
                          <div className="flex">
                            <p className="font-semibold text-red-700">*</p>

                            <p className="ml-5 text-BNPblue opacity-50">
                              eg. BespokNP cc
                            </p>
                          </div>
                        </div>
                      </div>
                    </h4>
                  </div>
                </div>
              </div>

              {/* Business VATNo */}
              <div className="flex justify-center">
                <div className="flex-row justify-center mt-[5px] h-[50px] text-md">
                  <div
                    onClick={() => vatFocusOn()}
                    className="group w-[200px] h-[40px] BNP-LableDIV cursor-text"
                  >
                    <input
                      value={VAT_No}
                      onChange={(e) => {
                        setVAT_No(formatTextNumber(e.target.value));
                        ArrayUpdateVAT(e.target.value);
                      }}
                      onBlur={() => vatFocusOff()}
                      type="text"
                      ref={vatRef}
                      autoComplete="off"
                      className="w-[200px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                    />
                    <h4
                      className={
                        vatMove
                          ? "w-[95px] BNP-LableMove -translate-y-[50px]"
                          : "w-[150px] BNP-LableStart mt-[3px]"
                      }
                    >
                      <div className="flex">VAT Number</div>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>

        {/* Client Container 1 */}
        <div className=" p-1  flex flex-wrap items-center justify-self-auto">
          {/* First Name */}
          <div className="flex justify-center z">
            <div className="flex-row justify-center mt-[5px] h-[50px] text-md">
              <div
                onClick={() => fistNameFocusOn()}
                className="group w-[350px] h-[40px] mr-[30px] BNP-LableDIV cursor-text z-40"
              >
                <input
                  value={FistName}
                  onChange={(e) => {
                    setFistName(formatProperCase(e.target.value));
                    ArrayUpdateName(e.target.value);
                  }}
                  onBlur={() => fistNameFocusOff()}
                  type="text"
                  ref={fistNameRef}
                  autoComplete="off"
                  className={
                    nameFlag
                      ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                      : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                  }
                />
                <h4
                  className={
                    fistNameMove
                      ? "w-[77px] BNP-LableMove -translate-y-[50px]"
                      : "w-[335px] BNP-LableStart mt-[3px]"
                  }
                >
                  <div className="flex">
                    First Name
                    <div className={fistNameMove ? "hidden" : "pl-1"}>
                      <p className="font-semibold text-red-700">*</p>
                    </div>
                  </div>
                </h4>
              </div>
            </div>
          </div>

          {/* Prefered Name */}
          <div className="flex justify-center">
            <div className="flex-row justify-center mt-[5px] h-[50px] text-md">
              <div className="flex justify-between ">
                <div className="flex"></div>
                <div className="flex justify-end -mt-6">
                  <p
                    id="prefNameCopy"
                    onClick={() => {
                      let RepName = newUser[0].Name;
                      if (RepName === "abc") {
                        console.log("The Business name is missing");
                      } else {
                        setPrefName(RepName);
                        ArrayUpdatePreferedName(RepName);
                        prefNameRef.current.focus();
                      }
                    }}
                    className="p-0 pr-3 BNP-Links"
                  >
                    copy
                  </p>
                </div>
              </div>
              <div
                onClick={() => prefNameFocusOn()}
                className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
              >
                <input
                  value={prefName}
                  onChange={(e) => {
                    setPrefName(formatProperCase(e.target.value));
                    ArrayUpdatePreferedName(e.target.value);
                  }}
                  onBlur={() => prefNameFocusOff()}
                  type="text"
                  ref={prefNameRef}
                  autoComplete="off"
                  className="w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                />
                <h4
                  className={
                    prefNameMove
                      ? "w-[103px] BNP-LableMove -translate-y-[50px]"
                      : "w-[345px] BNP-LableStart mt-[3px]"
                  }
                >
                  <div className="flex">Prefered Name</div>
                </h4>
              </div>
            </div>
          </div>
        </div>
        {/* Client Container 2 */}
        <div className="p-1 flex flex-wrap items-center">
          {/* Surname */}
          <div className="flex justify-center">
            <div className="flex-row justify-center mt-[5px] h-[50px] text-md">
              <div
                onClick={() => SurnameFocusOn()}
                className="group w-[350px] h-[40px] mr-[30px] BNP-LableDIV cursor-text"
              >
                <input
                  value={Surname}
                  onChange={(e) => {
                    setSurname(formatProperCase(e.target.value));
                    ArrayUpdateSurname(e.target.value);
                  }}
                  onBlur={() => SurnameFocusOff()}
                  type="text"
                  ref={SurnameRef}
                  autoComplete="off"
                  className={
                    surnameFlag
                      ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                      : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                  }
                />
                <h4
                  className={
                    SurnameMove
                      ? "w-[66px] BNP-LableMove -translate-y-[50px]"
                      : "w-[90px] BNP-LableStart mt-[3px]"
                  }
                >
                  <div className="flex">
                    Surname
                    <div className={SurnameMove ? "hidden" : "pl-1"}>
                      <p className="font-semibold text-red-700">*</p>
                    </div>
                  </div>
                </h4>
              </div>
            </div>
          </div>

          {/* Client Container 3 */}
          <div className="  flex-row w-[250px] items-center justify-start">
            {/* ID No */}
            <div className="flex">
              <div className="flex-row justify-center mt-[4px] h-[50px] text-md">
                <div
                  onClick={() => IDNoFocusOn()}
                  className="group w-[250px] h-[40px] BNP-LableDIV cursor-text"
                >
                  <input
                    value={IDNo}
                    onChange={(e) => {
                      setIDNo(e.target.value.toUpperCase());
                      ArrayUpdateIDNo(e.target.value.toUpperCase());
                    }}
                    onBlur={() => IDNoFocusOff()}
                    type="text"
                    ref={IDNoRef}
                    autoComplete="off"
                    className={
                      idPassportFlag
                        ? "w-[250px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                        : "w-[250px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                    }
                  />
                  <h4
                    className={
                      IDNoMove
                        ? "w-[133px] BNP-LableMove -translate-y-[50px]"
                        : "w-[180px] BNP-LableStart mt-[3px]"
                    }
                  >
                    <div className="flex">
                      ID/Passport Number
                      <div className={IDNoMove ? "hidden" : "pl-1"}>
                        <p className="font-semibold text-red-700">*</p>
                      </div>
                    </div>
                  </h4>
                </div>
              </div>
              <div className="flex mt-[10px] h-5 w-8 ml-1">
                <Transition
                  show={idNoValid}
                  enter="transition ease-in-out duration-500 transform"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition ease-in-out duration-500 transform"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <riIcons.RiCloseCircleLine className="text-3xl font-bold text-red-600 animate-pulse duration-300" />
                </Transition>
              </div>
            </div>

            {/* ID type */}
            <div className="group flex-row justify-end w-[250px] mt-1">
              <DropIDType />
            </div>
          </div>
        </div>
        {/* Client Container 4 */}
        <div className=" p-1 flex flex-wrap items-center justify-self-auto border-b-2 border-BNPblue border-opacity-30">
          {/* Cell No */}
          <div className="flex justify-center">
            <div className="flex-row justify-center h-[50px] text-md">
              <div
                onClick={() => CellNoFocusOn()}
                className="group w-[350px] h-[40px] mr-[30px] BNP-LableDIV cursor-text"
              >
                <input
                  value={CellNo}
                  onChange={(e) => {
                    setCellNo(formatPhoneNumber(e.target.value));
                    ArrayUpdateCell_No(fromatRemoveFunnies(e.target.value));
                  }}
                  onBlur={() => CellNoFocusOff()}
                  type="text"
                  ref={CellNoRef}
                  autoComplete="off"
                  onFocus={() => UpdateRecipient()}
                  className={
                    cellFlag
                      ? "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text BNP-ErrorBoarder"
                      : "w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                  }
                />
                <h4
                  className={
                    CellNoMove
                      ? "w-[126px] BNP-LableMove -translate-y-[50px]"
                      : "w-[160px] BNP-LableStart mt-[3px]"
                  }
                >
                  <div className="flex">
                    Cellphone Number
                    <div className={CellNoMove ? "hidden" : "pl-1"}>
                      <p className="font-semibold text-red-700">*</p>
                    </div>
                  </div>
                </h4>
              </div>
            </div>
          </div>

          {/* Phone No */}
          <div className="flex justify-center">
            <div className="flex-row justify-center h-[50px] text-md">
              <div
                onClick={() => TelFocusOn()}
                className="group w-[350px] h-[40px] BNP-LableDIV cursor-text"
              >
                <input
                  value={Tel}
                  onChange={(e) => {
                    setTel(formatPhoneNumber(e.target.value));
                    ArrayUpdateTelephone(fromatRemoveFunnies(e.target.value));
                  }}
                  onBlur={() => TelFocusOff()}
                  type="text"
                  ref={TelRef}
                  autoComplete="off"
                  className="w-[350px] h-[40px] pl-4 BNP-Input-thin text-lg text-BNPblue cursor-text"
                />
                <h4
                  className={
                    TelMove
                      ? "w-[102px] BNP-LableMove -translate-y-[50px]"
                      : "w-[130px] BNP-LableStart mt-[3px]"
                  }
                >
                  <div className="flex">Other Number</div>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
