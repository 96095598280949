import { Link } from "react-router-dom";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function NotFoundPage() {
  // console.log("Render NotFoundPage")


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="flex-row w-100 font-Montserrat text-center text-BNPblue mt-7 space-y-5">
      <div className="flex justify-center items-center font-semibold text-3xl">
        <h1 className="mr-4">Opps! Something went wrong...</h1>
      </div>

      <div className="flex justify-center items-center font-semibold text-2xl">
        <h1 className="mr-4">Error:</h1>
        <h1>404 Page not found!</h1>
      </div>

      <div className="flex justify-center items-center">
        <img src="../images/lego2.png" alt="" className="w-[200px]" />
      </div>
      <div>
        <div className="flex justify-center items-center font-semibold text-2xl">
          <h1 className="mr-4">Check the address bar</h1>
        </div>

        <div className="flex justify-center items-center font-semibold text-2xl">
          <h1>or</h1>
        </div>
        <div className="flex justify-center items-center font-semibold text-xl">
          <h1 >Go to </h1>

          <div className="BNP-Links">
            <Link to="/">home </Link>
          </div>

          <h1 className="mr-3">page</h1>
        </div>
      </div>
    </div>
  );
}
