import { Link } from "react-router-dom";

export default function PrivacyPolicyPage() {
  // console.log("Render test PrivacyPolicyPage");

  return (
    <>
      <div className="flex font-Montserrat justify-center">
        <div className="justify-center items-center w-full px-5 md:w-5/6 transition-all duration-500 mb-12">
          <div className="flex items-center justify-center ">
            <h1 className="mb-3 md:mb-5 md:pt-10 text-5xl font-bold">
              Privacy Policy
            </h1>
          </div>
          <p>
            This Privacy Policy describes how your personal information is
            collected, used, and shared when you visit or make a purchase from
            <strong> Bespoke Number Plates.</strong>
            <p className="BNP-TsCsH">THE PERSONAL INFORMATION WE COLLECT:</p>
            <p>
              When you visit the Site, we automatically collect certain
              information about your device, including information about your
              web browser, IP address, time zone, and some of the cookies that
              are installed on your device. Additionally, as you browse the
              Site, we collect information about the individual web pages or
              products that you view, what websites or search terms referred you
              to the Site, and information about how you interact with the Site.
              We refer to this automatically-collected information as “Device
              Information”.
            </p>
            <p>
              We collect Device Information using the following technologies:
            </p>
            <p>
              - “Cookies” are data files that are placed on your device or
              computer and often include an anonymous unique identifier. For
              more information about cookies, and how to disable cookies, visit
              http://www.allaboutcookies.org.
            </p>
            <p>
              - “Log files” track actions occurring on the Site, and collect
              data including your IP address, browser type, Internet service
              provider, referring/exit pages, and date/time stamps.
            </p>
            <p>
              - “Web beacons”, “tags”, and “pixels” are electronic files used to
              record information about how you browse the Site.
            </p>
            <p>
              Additionally when you make a purchase or attempt to make a
              purchase through the Site, we collect certain information from
              you, including your name, billing address, shipping address,
              payment information (including credit card numbers, email address,
              and phone number). We refer to this information as “Order
              Information”
            </p>
            <p>
              When we talk about “Personal Information” in this Privacy Policy,
              we are talking both about Device Information and Order
              Information.
            </p>
            <p className="BNP-TsCsH">
              HOW DO WE USE YOUR PERSONAL INFORMATION?
            </p>
            <p>
              We use the Order Information that we collect generally to fulfill
              any orders placed through the Site (including processing your
              payment information, arranging for shipping, and providing you
              with invoices and/or order confirmations). Additionally, we use
              this Order Information to:
            </p>
            <p>- Communicate with you;</p>
            <p>- Screen our orders for potential risk or fraud; and</p>
            <p>- When in line with the preferences you have shared with us,</p>
            <p>
              provide you with information or advertising relating to our
              products or services.
            </p>
            <p>
              We use the Device Information that we collect to help us screen
              for potential risk and fraud (in particular, your IP address), and
              more generally to improve and optimize our Site (for example, by
              generating analytics about how our customers browse and interact
              with the Site, and to assess the success of our marketing and
              advertising campaigns).
            </p>
            <p className="BNP-TsCsH">DATA RETENTION:</p>
            <p>
              When you place an order through the Site, we will maintain your
              Order Information for our records unless and until you ask us to
              delete this information.
            </p>
            <p className="BNP-TsCsH">CHANGES:</p>
            <p>
              We may update this privacy policy from time to time in order to
              reflect, for example, changes to our practices or for other
              operational, legal or regulatory reasons.
            </p>
            <p className="BNP-TsCsH">CONTACT US:</p>
            <p>
              For more information about our privacy practices, if you have
              questions, please visit our contacts page to get hold of us.
            </p>
            <Link to={"/contacts"} className="BNP-Links">
              Contacts
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
