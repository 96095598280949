import React, { useState } from "react";

export default function AboutPage() {
  // console.log("Render AboutPage")

  const [singleHover, setSingleHover] = useState(false);
  const [companyHover, setComponyHover] = useState(false);

  return (
    // structure
    <div className="flex-row justify-start md:justify-center items-center font-Montserrat p-3 mb-12">
      {/* Top banner */}
      <div className="relative flex justify-center w-100 h-32 font-semibold text-4xl">
        <div className="flex justify-between w-100 h-32 rounded-l-2xl bg-gradient-to-r from-BNPgoldLightLight to-BNPgoldDark"></div>
        <div className="flex justify-between w-100 h-32 rounded-r-2xl bg-gradient-to-r from-BNPgoldDark to-BNPblue"></div>

        <div className="absolute flex justify-center items-center text-center top-0 left-0 right-0 bottom-0">
          The Story behind BespokeNP!
        </div>
      </div>
      {/* Body */}
      <div className="flex justify-start md:justify-center mb-20 lg:mb-16 transition-all duration-500 ">
        <div className="flex md:w-[80%] BNP-Trans1000">
          <div className="flex-row space-y-16 md:space-y-5 ">
            <div className="flex justify-center font-semibold text-2xl m-4 md:pb-6 scale-110 text-center">
              <p>
                The vision that has become{" "}
                <span className="font-semibold">Bespoke Number Plates</span> all
                started in the early months of 2023.
              </p>
            </div>

            <div 
            className="group flex justify-around items-center">
              <img
                src="./images/tech-buss.jpg"
                alt=""
                className="h-[200px] rounded-3xl group-hover:scale-110 md:group-hover:scale-130 BNP-Trans1000"
              />
              <p className="text-xl w-1/3 text-center group-hover:scale-110 md:group-hover:scale-125 group-hover:font-semibold BNP-Trans1000">
                Our vision of combining both technology and number plate
                embossing and taking it to the next level, we setout to design
                and implement a fully integrated experience.
              </p>
            </div>
            <div className="group flex justify-around items-center">
              <p className="text-xl w-1/3 text-center group-hover:scale-110 md:group-hover:scale-125 BNP-Trans1000">
                Allowing from{" "}
                <span
                  className={
                    singleHover
                      ? "transition-height duration-1000 ease-in-out font-semibold text-2xl"
                      : "transition-height duration-1000 ease-in-out"
                  }
                >
                  single 
                </span>{" "}
                vehicle owners to{" "}
                <span
                  className={
                    companyHover
                      ? "transition-height duration-1000 ease-in-out font-semibold text-2xl"
                      : "transition-height duration-1000 ease-in-out"
                  }
                >
                  companies
                </span>{" "}
                with hundreds of vehicles to make use of our cutting-edge
                process. From ordering and payments to embossing and
                delivery/collections of their items.
              </p>
              <div className="relative flex justify-center items-center w-[350px] h-[350px]">
                <img
                  onMouseEnter={() => setSingleHover(true)}
                  onMouseLeave={() => setSingleHover(false)}
                  src="./images/BNP-SingleCar.jpeg"
                  alt=""
                  className="absolute rounded-3xl w-[170px] top-0 left-0 hover:w-[310px] hover:z-10 BNP-Trans1000"
                />
                <img
                  onMouseEnter={() => setComponyHover(true)}
                  onMouseLeave={() => setComponyHover(false)}
                  src="./images/BNP-MultiCar.jpeg"
                  alt=""
                  className="absolute rounded-3xl w-[170px] top-0 right-0 hover:w-[310px] hover:z-10 ransition-all duration-1000"
                />

                <img
                  onMouseEnter={() => setComponyHover(true)}
                  onMouseLeave={() => setComponyHover(false)}
                  src="./images/BNP-Dealer.jpeg"
                  alt=""
                  className="absolute rounded-3xl w-[170px] bottom-0 left-0 hover:w-[310px] hover:z-10 ransition-all duration-1000"
                />
                <img
                  onMouseEnter={() => setSingleHover(true)}
                  onMouseLeave={() => setSingleHover(false)}
                  src="./images/BNP-Bike.jpeg"
                  alt=""
                  className="absolute rounded-3xl w-[170px] bottom-0 right-0 hover:w-[310px] hover:z-10 ransition-all duration-1000"
                />
              </div>
            </div>

            <div className="group flex justify-around items-center h-[250px]">
              <img
                src="./images/BNP-CheckBal.jpeg"
                alt=""
                className="w-[280px] rounded-3xl group-hover:scale-110 md:group-hover:scale-130 BNP-Trans1000"
              />
              <p className="text-xl w-1/3 text-center group-hover:scale-110 md:group-hover:scale-125 group-hover:font-semibold BNP-Trans1000">
                With this approach, we can proudly say we have implemented
                better controls and checks to prevent illegal number plates from
                entering the South African eco-system.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Bottom banner */}
      <div className="flex justify-center font-semibold text-center text-4xl m-4">
        Making every visit to BNP an experience to remember.
      </div>
      <div className="flex justify-center w-100 h-20">
        <div className="flex justify-between w-100 h-32 rounded-l-2xl bg-gradient-to-r from-BNPgoldLightLight to-BNPgoldDark"></div>
        <div className="flex justify-between w-100 h-32 rounded-r-2xl bg-gradient-to-r from-BNPgoldDark to-BNPblue"></div>
      </div>
    </div>
  );
}

//
