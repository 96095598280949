import * as tbIcons from "react-icons/tb";

export default function LegislationPage() {
  // console.log("Render LegislationPage");

  return (
    <>
      <div className="flex-row font-Montserrat justify-start">
        <div className="flex-row ml-4 md:ml-10">
          <p className="text-3xl font-semibold pt-3 md:pt-8">
            Current Number Plate Legislation for South Africa.
          </p>
          <p className="text-xl pt-3 md:pt-8">
            You are being directed to a 3rd party site that houses all the info
            needed and explains it better than we can!
          </p>

          <a
            href="https://en.wikipedia.org/wiki/Vehicle_registration_plates_of_South_Africa"
            target="_blank"
            rel="noreferrer"
            className="flex items-center justify-center BNP-SubmitButtons h-20 w-60 rounded-full m-5"
          >
            <div className="flex items-center justify-between w-36">
              <tbIcons.TbBrandWikipedia className="text-3xl" /> Wikipedia
            </div>
          </a>
        </div>

        {/* Do's and Don't  */}
        <div className="flex border-t-2 BNP-border w-[95%]"></div>
      </div>
    </>
  );
}
