import React, { useContext } from "react";

// Context
import AdminContext from "../context/AdminContext";
import modalContext from "../context/ModalContext";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function AccountInfoPage() {
  // console.log("Render AccountInfoPage")

  // Context
  const { salesOrderInfo } = useContext(AdminContext);

  const {
    setMsgOpen,
    msgClean,
    setMsgTxt,
    setMsgType,
    setSalePayFastOpen,
    setSalePayShapOpen,
    setSaleYocoCashOpen,
    setSalePaymentType,
    salePaymentType,
    setOpenPaymentTypes,
  } = useContext(modalContext);

  const processPayments = () => {
    msgClean();
    console.log(salePaymentType)
    if (
      salePaymentType === salesOrderInfo[0].paymentType.toLowerCase() &&
      salePaymentType !== "payfast"
    ) {
      setMsgOpen(true);
      setMsgTxt("Payment method did'nt change.");
      setMsgType("Info");
      setOpenPaymentTypes(false);
      return;
    }

    if (salePaymentType === "payfast") {
      setSalePayFastOpen(true);
    } else if (salePaymentType === "payshap") {
      setSalePayShapOpen(true);
    } else if (salePaymentType === "yoco") {
      setSaleYocoCashOpen(true);
    } else if (salePaymentType === "cash") {
      setSaleYocoCashOpen(true);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {/* Payment Method */}
      <div className="flex-row flex-wrap s1020:flex gap-x-4 md:gap-y-4 p-3">
        {/* PayFast */}
        <div
          onClick={() => setSalePaymentType("payfast")}
          className="flex-row justify-center h-[130px] hover:shadow-md p-2 hover:shadow-BNPblue rounded-lg hover:scale-105  hover:cursor-pointer BNP-Trans300"
        >
          <div className="text-nowrap justify-center font-semibold text-center">
            Credit & Debit Card
          </div>
          <div className="flex-row pb-1">
            <div className="flex justify-center items-center h-[65px]">
              <div className="flex h-10 mt-4 my-3 justify-center">
                <img
                  src="../images/visaMasterCard.png"
                  alt="Credit & Debit Card"
                  id="visaMasterCard"
                />
              </div>
            </div>
            <div className="flex justify-center items-center">
              <input
                type="radio"
                name="payTypes"
                checked={salePaymentType === "payfast"}
                onChange={() => setSalePaymentType("payfast")}
                className="text-center hover:cursor-pointer w-6 h-6"
              />
            </div>
          </div>
        </div>

        {/* PayShap */}
        <div
          onClick={() => setSalePaymentType("payshap")}
          className="flex-row justify-center h-[130px] hover:shadow-md p-2 hover:shadow-BNPblue rounded-lg hover:scale-105 hover:cursor-pointer BNP-Trans300"
        >
          <div className="flex justify-center font-semibold">PayShap</div>
          <div className="flex-row pb-1">
            <div className="flex justify-center items-center h-[65px]">
              <div className="flex h-9">
                <img src="../images/payShap.png" alt="Payshap" id="payShap" />
              </div>
            </div>
            <div className="flex justify-center">
              <input
                type="radio"
                name="payTypes"
                checked={salePaymentType === "payshap"}
                onChange={() => setSalePaymentType("payshap")}
                className="text-center hover:cursor-pointer w-6 h-6"
              />
            </div>
          </div>
        </div>

        {/* Yoco Tap */}
        <div
          onClick={() => setSalePaymentType("yoco")}
          className="flex-row justify-center h-[130px] hover:shadow-md p-2 hover:shadow-BNPblue rounded-lg hover:scale-105 hover:cursor-pointer BNP-Trans300"
        >
          <div className="flex justify-center font-semibold">Yoco</div>
          <div className="flex-row pb-1">
            <div className="flex justify-center items-center h-[65px]">
              <div className="flex justify-center mt-2 h-[75px]">
                <img
                  src="../images/YocoTap2.webp"
                  alt="Yoco tap"
                  id="yocoTap"
                />
              </div>
            </div>
            <div className="flex justify-center">
              <input
                type="radio"
                name="payTypes"
                checked={salePaymentType === "yoco"}
                onChange={() => setSalePaymentType("yoco")}
                className="text-center hover:cursor-pointer w-6 h-6"
              />
            </div>
          </div>
        </div>

        {/* Cash */}
        <div
          onClick={() => setSalePaymentType("cash")}
          className="flex-row justify-center h-[130px] hover:shadow-md p-2 hover:shadow-BNPblue rounded-lg hover:scale-105 hover:cursor-pointer BNP-Trans300"
        >
          <div className="flex justify-center font-semibold">Cash</div>
          <div className="flex-row pb-1">
            <div className="flex justify-center items-center h-[65px]">
              <div className="flex justify-center h-[55px] -mt-2">
                <img src="../images/cash.png" alt="Pay cash" id="cashRands" />
              </div>
            </div>
            <div className="flex justify-center">
              <input
                type="radio"
                name="payTypes"
                checked={salePaymentType === "cash"}
                onChange={() => setSalePaymentType("cash")}
                className="text-center hover:cursor-pointer w-6 h-6"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          onClick={() => processPayments()}
          className="w-3/4 BNP-SubmitButtonsBlue"
        >
          Pay Now
        </button>
      </div>
    </>
  );
}
