
export default function DateTime(value) {
  if (!value) return "No Token loaded";
  const formattedDate = new Date(value).toLocaleString("en-SA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return formattedDate;
}