import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

// React Icons
import * as aiIcons from "react-icons/ai";

// Pages
import OrderModalOrderInfo from "./OrderModalOrderInfo";
import OrderModalProductInfo from "./OrderModalProductInfo";

// Context
import modalContext from "../context/ModalContext";
import adminContext from "../context/AdminContext";
import URLContext from "../context/URLContext";
import UserContext from "../context/UserContext";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function OrdersModal() {
  // console.log("Render OrdersModal");

  // Context
  const { updateOrderReady } = useContext(URLContext);
  const { userID } = useContext(UserContext);

  const { setOrdersModalOpen, setMsgOpen, msgClean, setMsgTxt, setMsgType } =
    useContext(modalContext);
  const {
    selectOrderNo,
    salesSummaryData,
    salesProductData,
    orderPaid,
    setOrderPaid,
    orderStatus,
    setOrderStatus,
    adminPgRefresh,
    setAdminPgRefresh,
    ordersModalValidation,
    setOrdersModalValidation,
  } = useContext(adminContext);

  // State
  const [orderInfo, setOrderInfo] = useState([]);
  const [productInfo, setProductInfo] = useState([]);

  const [orderStatusText, setOrderStatusText] = useState("");

  // Close
  const closeOutSide = (e) => {
    // console.log(e.target.id);
    if (e.target.id === "Orders_Close") closeClick();
  };

  const closeClick = () => {
    setOrdersModalOpen(false);
    setOrdersModalValidation(false);
  };

  useEffect(() => {
    // filter order no
    if (orderInfo.length === 0) {
      const oInfo = salesSummaryData?.filter(
        (data) => data.id === selectOrderNo
      );
      setOrderInfo(oInfo);
      setOrderPaid(oInfo[0].paid);
      if (oInfo[0].Verified === 1) {
        setOrdersModalValidation(true);
      }

      // Select same Order No for Product data
      const pInfo = salesProductData.filter(
        (data) => data.Order_No === selectOrderNo
      );
      // console.log(pInfo)
      setProductInfo(pInfo);
    }

    // Set Status
    if (orderStatus === true) {
      setOrderStatusText("Completed");
    } else {
      setOrderStatusText("In Progress");
    }
  }, [
    selectOrderNo,
    orderInfo,
    orderPaid,
    setOrderPaid,
    orderStatus,
    salesSummaryData,
    salesProductData,
    msgClean,
    setMsgOpen,
    adminPgRefresh,
    setOrdersModalValidation,
  ]);

  // console.log(salesSummaryData)
  // console.log(orderInfo)
  // console.log(orderPaid);
  // console.log(ordersModalValidation);

  const handelDone = () => {
    // Check Paid

    // Update status or show message
    let i = 0;
    if (orderPaid === "Yes") {
      // Check Plates Validated
      if (ordersModalValidation === false) {
        setOrderStatus(false);
        msgClean();
        setMsgOpen(true);
        setMsgType("Warning");
        setMsgTxt(
          "Please verify Documents and Plate before marking it as Done!"
        );
      } else {
        setOrderStatus(true);
        i = 1;
      }
    } else {
      // Not Paid
      setOrderStatus(false);
      msgClean();
      setMsgOpen(true);
      setMsgType("Info");
      setMsgTxt("Please CHECK if the client has Paid!");
    }

    // Update Order ready on DB
    updateReadyDone(i);

    // console.log(orderInfo);
  };

  const inProgressUpdate = () => {
    setOrderStatus(false);

    // Check if it was previously verified
    if (orderInfo[0].Verified === 1) {
      setOrdersModalValidation(true);
    }

    // Update Order ready on DB
    updateReadyDone(0);
  };

  const updateReadyDone = async (i) => {
    let url = updateOrderReady + selectOrderNo;
    const data = await axios
      .patch(url, {
        Ready: i,
        ReadyUCN: userID,
      })
      .then((res) => res)
      .catch((err) => {
        msgClean();
        setMsgTxt(
          "Network error!  Please check your Internet connection and try again."
        );
        setMsgType("Error");
        setMsgOpen(true);
      });
    // console.log(data);

    if (data.status === 200) {
      setAdminPgRefresh(1);
    }
  };

  // console.log(productInfo);
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div
        onClick={closeOutSide}
        className="flex justify-center max-h-full font-Montserrat overflow-y-scroll no-scrollbar overflow-x-hidden text-BNPblue "
      >
        <div id="Orders_Close" className="flex-row pt-[2%] pb-100 px-100 ">
          {/* Header */}
          <div className="flex-row  w-auto h-[75px] text-BNPgoldLight bg-BNPblue shadow-md shadow-BNPblue rounded-t-md">
            <div className="flex justify-start items-start pt-5 pl-5">
              <aiIcons.AiOutlineClose
                className="rounded-full focus:outline-none transition-all duration-500 scale-[2.2] hover:font-semibold hover:scale-[2.9] hover:rotate-90
                  hover:border-2 hover:rounded-full hover:border-BNPgoldLight cursor-pointer hover:transition-all hover:duration-700"
                onClick={closeClick}
              />
            </div>

            <div className="flex justify-center items-center -mt-3 mb-5 text-3xl font-semibold">
              <h1>Order info</h1>
            </div>
          </div>

          {/* Body */}
          <div className="relative flex-row p-4 min-w-[650px] bg-BNPgoldLightLight text-BNPblue shadow-md shadow-BNPblue space-y-4  rounded-b-md">
            {/* Order Status */}
            <div className="flex justify-between items-center px-4">
              {/* Status Heading */}
              <div className="flex justify-between items-center w-100 pl-5">
                <p className="font-semibold text-3xl">Order status:</p>
              </div>

              {/* Status selection buttons */}
              <div className="flex-row justify-center w-100">
                <p
                  className={
                    orderStatus
                      ? "font-semibold text-4xl text-center  text-green-600"
                      : "font-semibold text-4xl text-center  text-red-600"
                  }
                >
                  {orderStatusText}
                </p>
                <div className="flex justify-center items-center  mt-2 space-x-3">
                  <button
                    onClick={() => inProgressUpdate()}
                    className={
                      orderStatus
                        ? "p-2 text-center font-bold tracking-widest rounded-md bg-BNPgoldLight text-BNPblue border-2 border-BNPblue cursor-pointer hover:bg-BNPblue hover:text-BNPgoldLight hover:border-BNPgoldLight shadow-lg shadow-BNPblue"
                        : "p-2 text-center font-semibold text-BNPgoldLight bg-BNPblue rounded-md border-2 border-BNPgoldLight cursor-default"
                    }
                  >
                    In progress
                  </button>
                  <button
                    onClick={handelDone}
                    className={
                      orderStatus
                        ? "p-2 text-center font-semibold text-BNPgoldLight bg-BNPblue rounded-md border-2 border-BNPgoldLight cursor-default"
                        : "p-2 text-center font-bold tracking-widest rounded-md bg-BNPgoldLight text-BNPblue border-2 border-BNPblue cursor-pointer hover:bg-BNPblue hover:text-BNPgoldLight hover:border-BNPgoldLight shadow-lg shadow-BNPblue"
                    }
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>

            <div className="flex-row lg:flex justify-center items-center space-y-4 lg:space-y-0 lg:space-x-4 space-x-0">
              {orderInfo.map((oInfo) => (
                <div className="w-100" key={oInfo.id}>
                  {/* Column 1 */}
                  <OrderModalOrderInfo
                    Order_No={oInfo.id}
                    SalesDate={oInfo.SalesDate}
                    SaleVol={oInfo.SaleVol}
                    DeliveryType={oInfo.deliveryType}
                    paymentType={oInfo.paymentType}
                    paid={oInfo.paid}
                    paymentReceived={oInfo.paid}
                  />
                </div>
              ))}
            </div>
            {/* Items Ordered */}
            <div>
              {productInfo.map((pInfo) => (
                <div
                  key={pInfo.id}
                  className="flex-row lg:flex space-y-4 lg:space-y-0 lg:space-x-4 space-x-0"
                >
                  <OrderModalProductInfo
                    Sales_ID={pInfo.id}
                    Order_No={pInfo.Order_No}
                    prodType={pInfo.prodType}
                    quant={pInfo.quant}
                    size={pInfo.size}
                    reg={pInfo.reg}
                    province={pInfo.province}
                    verified={pInfo.verified}
                    CpID={pInfo.UnqID}
                    IDImg={pInfo.IDImg}
                    RegImg={pInfo.RegImg}
                    SelfieImg={pInfo.SelfieImg}
                    verifiedImg={pInfo.verifiedImg}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
