import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Context
import UserContext from "../context/UserContext";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function AccountInfoPage() {
  // console.log("Render AccountInfoPage")

  // Context
  const { userLogin, userAdmin } = useContext(UserContext);

  // Navigate
  const nav = useNavigate();

  useEffect(() => {
    if (userLogin === false) {
      nav("/PageNotFound");
    }
  }, [nav, userLogin, userAdmin]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="flex-row justify-center items-center">
      <h1>Account Info</h1>
    </div>
  );
}
