
export default function FutureLegislation() {
    // console.log("Render FutureLegPage")
    
    return (
      <>
        <div>hi there New rules for you</div>
        
        
      </>
    );
  };